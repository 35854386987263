import React from 'react';
import DescriptionCard from '../DescriptionCard';

const SecretNumberDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <DescriptionCard
      title="Secret Number"
      description={`**How to Play:** Your challenge is to uncover your secret number and the secret numbers of other players. Each player is assigned a number from 1 - 100. This number is consistent for all participants during today's challenge.

Using mathematical operations, create equations between secret numbers to reveal clues and deduce their values. To create an equation, select two profiles and choose an operation to perform: addition, multiplication, or division. 

**Addition** reveals the sum of the two secret numbers. Example: If Profile A's number is 10 and Profile B's number is 15, the result shown is 25.

**Multiplication** reveals the **last digit** of the product of two secret numbers. Example: If Profile C's number is 12 and Profile D's number is 34, the product is 408, the result shown is 8.

**Division** reveals the **quotient** of the larger number divided by the smaller number, excluding the remainder or decimals. Example: If Profile E's number is 40 and Profile F's number is 12, the result is 3.

You have **4 uses** of each action, allowing for a total of **12 equations** in the game.

To submit guesses, select the profiles you have solved for and enter your answer into the box. You do not need to submit a guess for every profile. 

**Scoring**: Correctly guessing your own secret number: **+5 points**. Incorrectly guessing your own secret number: **-5 points**. Correctly guessing another player's number: **+2 points** to your score and **-2 points** from that player's score. Incorrectly guessing another player's number: **-2 points** from your score. 

⚠️  This is a timed challenge. You are limited to 12 equations. Refreshing, leaving and re-entering after it has started may result in progress lost or time reduced. This is not advised. 

**Win/Lose Conditions**: If you score within the top 50% of points, congratulations, immunity is yours! If score in the bottom 50% of scores, we'll be saving a spot on the wheel for you tonight. 

**Estimated time required**: 15 minutes. 

**Fun-rating:** 10/10`}
      onStart={onStart}
    />
  );
};

export default SecretNumberDescription;
