import { config } from './config';

const API_BASE_URL = config.apiBaseUrl;

// Add a helper function to check if user is authenticated
const checkAuth = () => {
  const isVerified = localStorage.getItem('isVerified') === 'true';
  const userId = localStorage.getItem('userId');
  if (!isVerified || !userId) {
    throw new Error('User not authenticated');
  }
  return parseInt(userId);
};

export async function startGame(gameId: number) {
  try {
    const userId = checkAuth();
    const response = await fetch(`${API_BASE_URL}/api/game-completions/${gameId}/start_game/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId }),
    });
    return response.json();
  } catch (error) {
    console.error('Error starting the game:', error);
    throw error;
  }
}

export async function completeGame(gameId: number, result: 'pass' | 'fail') {
  try {
    const userId = checkAuth();
    const response = await fetch(`${API_BASE_URL}/api/game-completions/${gameId}/complete_game/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId, result }),
    });
    return response.json();
  } catch (error) {
    console.error('Error completing the game:', error);
  }
}

export interface GameStatus {
  canPlay: boolean;
  previousResult?: 'pass' | 'fail';
  allowRetries: boolean;
}

export async function getGameStatus(gameId: number): Promise<GameStatus> {
  try {
    const userId = checkAuth();
    const response = await fetch(
      `${API_BASE_URL}/api/game-completions/${gameId}/status/?user_id=${userId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.json();
  } catch (error) {
    console.error('Error getting game status:', error);
    throw error;
  }
}

export interface Game {
  game_id: number;
  game_name: string;
  is_special_power: boolean;
  allow_retries: boolean;
  has_timer: boolean;
  timer_seconds: number;
  available_date: string;
}

export async function getGames(): Promise<Game[]> {
  try {
    const userId = checkAuth();
    const response = await fetch(`${API_BASE_URL}/api/games/?user_id=${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch games');
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching games:', error);
    throw error;
  }
}

export interface VerifyAccessCodeResponse {
  valid: boolean;
  user_id?: number;
  user_name?: string;
  is_staff?: boolean;
  message?: string;
}

export async function verifyAccessCode(accessCode: string): Promise<VerifyAccessCodeResponse> {
  try {
    const response = await fetch(`${API_BASE_URL}/api/users/verify_access_code/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ access_code: accessCode }),
    });

    const data = await response.json();

    if (!response.ok) {
      return {
        valid: false,
        message: data.message || 'An error occurred',
      };
    }

    return data;
  } catch (error) {
    console.error('Error verifying access code:', error);
    return {
      valid: false,
      message: 'Network error occurred',
    };
  }
}

export async function submitPendingGame(gameId: number) {
  try {
    const userId = checkAuth();
    const response = await fetch(`${API_BASE_URL}/api/game-completions/${gameId}/complete_game/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: userId,
        result: 'pending',
      }),
    });
    return response.json();
  } catch (error) {
    console.error('Error submitting game with pending status:', error);
    throw error;
  }
}

interface WildVoteData {
  position: number;
  animal: string;
  value: number;
}

export async function completeWildVotesGame(gameId: number, votes: WildVoteData[]) {
  try {
    const userId = checkAuth();
    const response = await fetch(`${API_BASE_URL}/api/game-completions/${gameId}/complete_game/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: userId,
        result: 'pending',
        votes,
      }),
    });
    return response.json();
  } catch (error) {
    console.error('Error completing wild votes game:', error);
    throw error;
  }
}

export async function completeRedPillGame(
  gameId: number,
  answers: Array<{ round: number; pill: string; answer: string }>,
) {
  try {
    const userId = checkAuth();
    const response = await fetch(`${API_BASE_URL}/api/game-completions/${gameId}/complete_game/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: userId,
        result: 'pending',
        answers,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to complete red pill game');
    }

    return await response.json();
  } catch (error) {
    console.error('Error completing red pill game:', error);
    throw error;
  }
}

export async function completeQuickfireQuiz(gameId: number, score: number) {
  try {
    const userId = checkAuth();
    const response = await fetch(`${API_BASE_URL}/api/game-completions/${gameId}/complete_game/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: userId,
        result: 'pending',
        score: score,
      }),
    });

    if (!response.ok) {
      console.error('Error completing quickfire quiz:', await response.text());
      return null;
    }

    return response.json();
  } catch (error) {
    console.error('Error completing quickfire quiz:', error);
    return null;
  }
}

export async function completeSquaredle(gameId: number, score: number) {
  try {
    const userId = checkAuth();
    const response = await fetch(`${API_BASE_URL}/api/game-completions/${gameId}/complete_game/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: userId,
        result: 'pending',
        score: score,
      }),
    });

    if (!response.ok) {
      console.error('Error completing squaredle:', await response.text());
      return null;
    }

    return response.json();
  } catch (error) {
    console.error('Error completing squaredle:', error);
    return null;
  }
}

export interface SecretNumberSubmission {
  user_id: number;
  result: 'pending';
  score: number;
  correct_guesses: number[];
}

export async function completeSecretNumber(
  gameId: number,
  score: number,
  correctGuesses: number[],
) {
  try {
    const userId = checkAuth();
    const response = await fetch(`${API_BASE_URL}/api/game-completions/${gameId}/complete_game/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: userId,
        result: 'pending',
        score: score,
        correct_guesses: correctGuesses,
      } as SecretNumberSubmission),
    });

    if (!response.ok) {
      console.error('Error completing secret number:', await response.text());
      return null;
    }

    return response.json();
  } catch (error) {
    console.error('Error completing secret number:', error);
    return null;
  }
}
