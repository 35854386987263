import React, { useState, useEffect } from 'react';
import { completeQuickfireQuiz } from '../api';

interface QuizQuestion {
  question: string;
  answer: string | string[];
}

interface QuickfireQuizProps {
  onGameEnd: (success: boolean, isPending?: boolean, bonusTime?: number) => void;
}

// Game Configuration Constants
const GAME_CONFIG = {
  BONUS_TIME_PER_CORRECT: 5,
} as const;

const QUESTIONS: QuizQuestion[] = [
  {
    question: "What is James Bonds' code name?",
    answer: ['007', 'double o seven', 'double-o-seven', 'double o 7'],
  },
  {
    question: 'What tropical fruit would you find on an hawaiian pizza?',
    answer: ['pineapple', 'pineapples'],
  },
  { question: 'Which is the only planet to end in the letter H?', answer: 'earth' },
  {
    question: 'Tony Stark is the alter ego of which super hero?',
    answer: ['iron man', 'ironman', 'the iron man'],
  },
  { question: 'Arachnophobia is the fear of what?', answer: ['spider', 'spiders'] },
  {
    question: 'What type of fish was Nemo in Finding Nemo?',
    answer: ['clown fish', 'clownfish', 'clown'],
  },
  { question: 'The Seine flows through which capital city?', answer: ['paris', 'paris france'] },
  { question: "The song 'Imagine' was by which former Beatle?", answer: ['john lennon', 'lennon'] },
  {
    question: 'In the wizard of Oz, what organ of the body was The Tin Man in search of?',
    answer: ['heart', 'a heart'],
  },
  {
    question: 'In 2023, Keanu Reeves reprised his role as what fictional assassin?',
    answer: ['john wick', 'johnwick'],
  },
  {
    question: 'Considered a delicacy, the century egg originated from which country?',
    answer: 'china',
  },
  {
    question: "In the US, 'Thanksgiving' is celebrated on which day of the week?",
    answer: 'thursday',
  },
  {
    question: 'What colour are the stars on the flag of New Zealand?',
    answer: ['red', 'red stars'],
  },
  {
    question: 'Which of the four elements are associated with the star sign Taurus?',
    answer: 'earth',
  },
  {
    question: "'O, What a Beautiful Morn' is the opening song from which musical?",
    answer: 'oklahoma',
  },
  { question: "What is the name of Saturn's largest moon?", answer: 'titan' },
  {
    question: "Which graffiti artist's painting shredded itself immediately after auction?",
    answer: 'banksy',
  },
  {
    question: "In the Simpsons, what is the name of Mr Burn's assistant?",
    answer: ['smithers', 'waylon smithers'],
  },
  { question: "What fictional metal is Wolverine's claws made of?", answer: 'adamantium' },
  {
    question: 'Which musical comedy is based on the movie Monty Python and the Holy Grail?',
    answer: ['spamalot', 'monty python spamalot'],
  },
  {
    question: 'In Roman numerals, which number is represented by the letter M?',
    answer: ['1000', '1,000'],
  },
  {
    question: 'What farm animal is traditionally used to hunt for truffles?',
    answer: ['pig', 'pigs', 'truffle pig', 'truffle pigs'],
  },
  {
    question: 'JoJo Siwa appeared in two seasons of which reality TV show?',
    answer: ['dance moms', 'dancemoms', 'dance mom', 'dance mums', 'dancemums', 'dance mum'],
  },
  {
    question:
      'What is the largest Mexican state, which also shares its name with the breed of the smallest dog?',
    answer: 'chihuahua',
  },
  {
    question: 'The American state of Washington can be found on which ocean?',
    answer: ['pacific', 'pacific ocean', 'the pacific', 'the pacific ocean'],
  },
  {
    question: 'In Greek mythology, the Twelve Labours are carried out by which hero?',
    answer: ['heracles', 'hercules'],
  },
  {
    question:
      'Actor Steve Carell plays what memorable character in the popular TV series The Office?',
    answer: ['michael scott', 'michael gary scott'],
  },
  {
    question: 'Anna, Elsa Kristoff and Olaf are all characters in what animated movie?',
    answer: 'frozen',
  },
  {
    question: 'What name is singer-actor Stefani Germanotta better known by?',
    answer: ['lady gaga', 'ladygaga'],
  },
  { question: 'The unicorn is the national animal of which country?', answer: 'scotland' },
  {
    question: 'Before embarking on a solo career, Beyoncé was part of what R&B group?',
    answer: ["destiny's child", 'destinys child', 'destinies child'],
  },
  {
    question: "According to Guinness World Records, what's the best-selling book of all time?",
    answer: ['the bible', 'bible'],
  },
  { question: 'What is the only food that can never go bad?', answer: ['honey', 'pure honey'] },
  { question: 'A majority of adults have how many permanent teeth?', answer: ['32', '32 teeth'] },
  { question: 'What do you call a group of flamingos?', answer: ['flamboyance', 'a flamboyance'] },
  {
    question: 'Which freezes faster: hot or cold water?',
    answer: ['hot water', 'hot', 'the hot water'],
  },
  {
    question: 'Jim Henson is the creator of what beloved cast of characters?',
    answer: ['the muppets', 'muppets', 'muppet'],
  },
  {
    question: 'Which fictional detective did Sir Arthur Conan Doyle create?',
    answer: ['sherlock holmes', 'sherlock'],
  },
  {
    question: 'The film "Home Alone 2" was set in which city?',
    answer: ['new york', 'new york city', 'nyc'],
  },
  { question: 'How many even numbers are there between 1 and 101?', answer: '50' },
  {
    question:
      'In the nursery rhyme, "Hey Diddle Diddle," what item of cutlery did the dish run away with?',
    answer: 'spoon',
  },
  { question: 'What type of reptile is the Pit Viper?', answer: 'snake' },
  {
    question: 'In 2023, King Charles III was crowned in which London church?',
    answer: ['westminster abbey', 'westminster'],
  },
  { question: 'How many holes are there in a bagel?', answer: ['1', 'one'] },
  { question: 'Which singer famously changed his name to a symbol in 1993?', answer: 'prince' },
  {
    question: 'Which actor is best known by the nickname "Sly"?',
    answer: ['sylvester stallone', 'stallone'],
  },
  {
    question: 'Which motorcycle manufacturer, founded in 1903, is based in Milwaukee?',
    answer: ['harley-davidson', 'harley davidson', 'harley'],
  },
  { question: 'What is the largest organ in the human body?', answer: 'skin' },
  { question: 'What US state is known for the nickname The Lone Star State?', answer: 'texas' },
  {
    question: 'What is the name of the galaxy next to the Milky Way?',
    answer: ['andromeda', 'andromeda galaxy', 'the andromeda galaxy'],
  },
  {
    question: 'The Gotthard Base Tunnel runs through the Alps in which country?',
    answer: 'switzerland',
  },
  { question: 'What is the closest planet to the sun?', answer: 'mercury' },
  {
    question: 'What film is associated with the famous line, "Nobody puts Baby in a corner."?',
    answer: ['dirty dancing'],
  },
  {
    question: 'Which planet in our solar system shares its name with the Roman god of the sea?',
    answer: 'neptune',
  },
  {
    question: 'What word links a type of champagne glass and a musical instrument?',
    answer: 'flute',
  },
  {
    question: 'What is the name of the Turkish city that straddles both Europe and Asia?',
    answer: 'istanbul',
  },
  { question: 'In computing, how many bits are in a byte?', answer: ['8', 'eight'] },
  {
    question: 'Which Mary Shelley novel was subtitled "The Modern Prometheus"?',
    answer: 'frankenstein',
  },
  {
    question: 'The statue of David was created by which Renaissance artist?',
    answer: ['michelangelo'],
  },
  {
    question: 'Which actress has been nominated for the most Academy Awards?',
    answer: ['meryl streep', 'streep'],
  },
  {
    question: 'Which famous group was once known as The Quarrymen?',
    answer: ['the beatles', 'beatles'],
  },
  {
    question: 'Ship speed is measured in what unit?',
    answer: ['knot', 'knots'],
  },
  {
    question: 'Who plays the title character in the film "First Blood"?',
    answer: ['sylvester stallone', 'stallone'],
  },
  { question: 'Armistice Day is commemorated in which month?', answer: 'november' },
  {
    question: 'In Greek mythology, which fruit is associated with "the most beautiful"?',
    answer: 'apple',
  },
  { question: 'Beyoncé married which rapper in 2008?', answer: ['jay z', 'jayz', 'jay-z'] },
  {
    question: '"We Found Love in a Hopeless Place" is sung by which Barbadian singer?',
    answer: 'rihanna',
  },
  { question: 'What name is given to the back of a ship?', answer: 'stern' },
  {
    question: 'What is the deepest natural trench in the world?',
    answer: ['mariana trench', 'mariana', 'the mariana trench'],
  },
  {
    question: 'The Fonz was a character in which classic American sitcom?',
    answer: ['happy days'],
  },
  {
    question: 'Spider-Man was created by which legendary comic book writer?',
    answer: ['stan lee', 'stanley lieber'],
  },
  {
    question: 'Marilyn Monroe was married to which baseball star?',
    answer: ['joe dimaggio', 'dimaggio'],
  },
  {
    question: 'Freddie Mercury, the lead singer of Queen, was born in which African country?',
    answer: 'tanzania',
  },
  { question: 'In which month is the U.S. presidential inauguration held?', answer: 'january' },
  { question: '"Fantasy" is a perfume by which pop star?', answer: ['britney spears', 'britney'] },
  {
    question: 'In 2017, Jacinda Ardern became the Prime Minister of which island nation?',
    answer: ['new zealand'],
  },
  { question: 'Brooklyn is a borough of which U.S. city?', answer: ['new york', 'new york city'] },
  { question: "Which country's rugby team is known as the Springboks?", answer: ['south africa'] },
  {
    question: 'According to the book title, "Men Are from Mars, Women Are from...?"',
    answer: 'venus',
  },
  { question: 'Ophthalmology is the study of which sensory organ?', answer: ['eye', 'eyes'] },
  {
    question: 'K-pop originates from which Asian country?',
    answer: ['south korea', 'korea', 'republic of korea'],
  },
  { question: 'Salami sausage originated in which country?', answer: 'italy' },
  {
    question: '"Spiceworld" was the second studio album by which \'90s girl group?',
    answer: ['spice girls'],
  },
  { question: 'Dromedary and Bactrian are types of which mammal?', answer: ['camel', 'camels'] },
  { question: 'An oenophile is a connoisseur of which alcoholic drink?', answer: 'wine' },
  {
    question: 'The neoclassical statue "Liberty Enlightening the World" is better known as what?',
    answer: ['statue of liberty'],
  },
  { question: 'What is the zodiac sign of a person born on May 1?', answer: 'taurus' },
  {
    question: 'Queen Elizabeth II is most associated with which breed of dog?',
    answer: ['corgi', 'corgis'],
  },
  { question: 'Ganymede and Callisto are moons of which planet?', answer: 'jupiter' },
  {
    question:
      'According to the film title, Tom Hanks and Meg Ryan were "Sleepless in" which U.S. city?',
    answer: 'seattle',
  },
  {
    question: 'Excluding jokers, how many cards are in a standard deck of cards?',
    answer: ['52', 'fifty two', 'fifty-two'],
  },
  {
    question: 'What links a type of coffee and a 2023 song by Sabrina Carpenter?',
    answer: 'espresso',
  },
  {
    question: 'In Scrabble, how many points are awarded for the word "quiz"?',
    answer: ['22', 'twenty two', 'twenty-two'],
  },
  { question: 'The term "sol" is used to describe a day on which planet?', answer: 'mars' },
  {
    question: 'Which country was formerly known as New Amsterdam?',
    answer: ['united states', 'usa', 'america'],
  },
  { question: 'What is the most abundant element in the universe?', answer: 'hydrogen' },
  { question: 'How many islands make up the state of Hawaii?', answer: ['8', 'eight'] },
  {
    question: 'Which African country recently changed its name to Eswatini?',
    answer: ['swaziland'],
  },
  { question: 'Which Canadian province is exclusively French-speaking?', answer: 'quebec' },
  {
    question: 'Which English soccer team won the treble after beating Inter Milan in 2023?',
    answer: ['manchester city', 'man city'],
  },
  { question: "Which country's flag features a red dragon?", answer: 'wales' },
  {
    question: 'Who directed the film Oppenheimer in 2023?',
    answer: ['christopher nolan', 'nolan'],
  },
  { question: 'What is the tallest building in the world?', answer: ['burj khalifa'] },
  {
    question:
      'Which famous British writer also published works under the pseudonym Mary Westmacott?',
    answer: ['agatha christie', 'christie'],
  },
  {
    question: 'What is the highest mountain range in the world?',
    answer: ['himalayas', 'the himalayas'],
  },
  { question: 'What age followed the Bronze Age?', answer: ['iron age', 'iron'] },
  { question: 'The Atacama Desert can be found in which country?', answer: 'chile' },
  { question: 'The Dance Theatre of Harlem specialises in which form of dance?', answer: 'ballet' },
  {
    question: 'Frida Kahlo was married to which prominent Mexican painter?',
    answer: ['diego rivera', 'rivera'],
  },
  { question: 'What is the only sea without a land border?', answer: ['sargasso sea', 'sargasso'] },
  {
    question: 'Camp Nou is the home stadium of which Spanish football team?',
    answer: ['barcelona', 'fc barcelona'],
  },
  {
    question:
      'In which sport did North and South Korea compete together at the 2018 Winter Olympics?',
    answer: ['ice hockey'],
  },
  {
    question: 'In Greek mythology, which Titan god is credited with creating men from clay?',
    answer: 'prometheus',
  },
  {
    question: 'Which mathematician is considered the "father of computer science"?',
    answer: ['alan turing', 'turing'],
  },
  { question: 'What is the name of the Japanese art of paper folding?', answer: 'origami' },
  {
    question: 'Which European city is nicknamed "The City of a Hundred Spires"?',
    answer: 'prague',
  },
  { question: 'What is the oldest continuously inhabited city in the world?', answer: 'damascus' },
  {
    question: 'What is the term for a word or phrase that reads the same backward as forward?',
    answer: 'palindrome',
  },
  {
    question: 'Which company became the first to achieve a market capitalization of $3 trillion?',
    answer: 'apple',
  },
  {
    question: 'In 2020, which country became the first to make Bitcoin legal tender?',
    answer: ['el salvador'],
  },
  {
    question: 'In 2020, which pop star\'s documentary "Miss Americana" premiered on Netflix?',
    answer: ['taylor swift'],
  },
  { question: "Which series is Netflix's top-watched show ever?", answer: ['squid game'] },
  {
    question: 'Which artist headlined the 2023 Super Bowl Halftime Show?',
    answer: ['rihanna', 'rhianna'],
  },
  {
    question: 'Which Christmas carol was originally titled "One Horse Open Sleigh"?',
    answer: ['jingle bells'],
  },
  { question: 'Vishnu is a god of which religion?', answer: 'hinduism' },
  { question: 'The Great Sand Sea can be found in which desert?', answer: 'sahara' },
  { question: 'What is the German word for emperor?', answer: 'kaiser' },
  { question: 'Italian Limoncello is made from which fruit?', answer: 'lemon' },
  { question: 'Samba is a dance associated with which South American country?', answer: 'brazil' },
  {
    question:
      'Who starred as Willy Wonka in the 2005 film adaptation of "Charlie and the Chocolate Factory"?',
    answer: ['johnny depp', 'depp'],
  },
  { question: 'Sarah Palin was the governor of which U.S. state?', answer: 'alaska' },
  { question: 'What is the only U.S. state with a one-syllable name?', answer: 'maine' },
  {
    question: 'What is the smallest country in the world by area?',
    answer: ['vatican city', 'vatican'],
  },
  { question: 'Which planet is known as the "Morning Star" or "Evening Star"?', answer: 'venus' },
  { question: 'In which year did the Titanic sink?', answer: '1912' },
  {
    question: 'What is the name of the wizarding bank in Harry Potter?',
    answer: ['gringotts', 'gringotts bank'],
  },
  {
    question: 'Which desert is the largest in the world?',
    answer: ['antarctic desert', 'antarctic', 'the antarctic desert'],
  },
  { question: 'Which country has won the most FIFA World Cups?', answer: 'brazil' },
  {
    question: 'What do you call a shape with five sides?',
    answer: ['pentagon', 'a pentagon'],
  },
  {
    question: "What is the world's smallest ocean?",
    answer: ['arctic ocean', 'the arctic ocean', 'arctic'],
  },
  {
    question: 'What part of the body contains the smallest bones?',
    answer: ['ear', 'the ear', 'ears'],
  },
  {
    question: "What is the name of Thor's hammer in the Marvel universe?",
    answer: ['mjolnir'],
  },
  {
    question: 'What is the center of an atom called?',
    answer: ['nucleus', 'the nucleus'],
  },
  {
    question: "What is the name of Batman's butler?",
    answer: ['alfred', 'alfred pennyworth'],
  },
  {
    question: 'What is the temperature at which Fahrenheit and Celsius are the same?',
    answer: ['-40', 'minus 40', '-40 degrees'],
  },
  {
    question: 'What is the capital city of Switzerland?',
    answer: ['bern'],
  },
  {
    question: "What is the name of the fictional city in 'The Simpsons'?",
    answer: ['springfield'],
  },
  {
    question: 'Which movie features the line, "You can\'t handle the truth!"?',
    answer: ['a few good men'],
  },
  {
    question: 'Which Shakespeare play features the line, "To be or not to be"?',
    answer: ['hamlet'],
  },
  {
    question: 'What is the largest landlocked country in the world by area?',
    answer: ['kazakhstan'],
  },
  {
    question: 'What is the largest island in the Mediterranean Sea?',
    answer: ['sicily'],
  },
  {
    question: 'What is the national sport of Japan?',
    answer: ['sumo wrestling', 'sumo'],
  },
  {
    question: "Who is known as the 'Father of Modern Physics'?",
    answer: ['albert einstein', 'einstein'],
  },
];

const shuffleArray = <T,>(array: T[]): T[] => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const QuickfireQuiz: React.FC<QuickfireQuizProps> = ({ onGameEnd }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [score, setScore] = useState(0);
  const [gameActive, setGameActive] = useState(true);
  const [timeLeft, setTimeLeft] = useState<number>(60);
  const [shuffledQuestions] = useState(() => shuffleArray(QUESTIONS));
  const [flashColor, setFlashColor] = useState<string>('');
  const [lastCorrectAnswer, setLastCorrectAnswer] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Helper function to format answer with proper casing
  const formatAnswer = (answer: string | string[]): string => {
    const firstAnswer = Array.isArray(answer) ? answer[0] : answer;
    return firstAnswer
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  // Add flash animation styles
  const inputStyle = {
    padding: 'clamp(8px, 1.5vw, 12px)',
    fontSize: 'clamp(1rem, 2vw, 1.2rem)',
    marginRight: 'clamp(8px, 2vw, 15px)',
    width: 'min(80%, 300px)',
    borderRadius: '4px',
    border: '1px solid #ccc',
    transition: 'background-color 0.15s ease-in-out',
    backgroundColor: flashColor,
  };

  useEffect(() => {
    const updateTimer = () => {
      const gameStartTime = localStorage.getItem('gameStartTime');
      if (!gameStartTime) return;

      const now = Date.now();
      const startTime = parseInt(gameStartTime, 10);
      const elapsedTime = (now - startTime) / 1000;

      if (elapsedTime >= 60 && !isSubmitting) {
        setTimeLeft(0);
        setIsSubmitting(true);

        const submitScore = async () => {
          try {
            await completeQuickfireQuiz(12, score);
            setGameActive(false);
            onGameEnd(true, true); // isPending = true
          } catch (error) {
            console.error('Failed to submit quiz score:', error);
            setGameActive(false);
            onGameEnd(false);
          } finally {
            setIsSubmitting(false);
          }
        };
        submitScore();
        return;
      }

      const remainingTime = 60 - elapsedTime;
      setTimeLeft(Math.max(0, remainingTime));
    };

    const timer = setInterval(updateTimer, 16);
    return () => clearInterval(timer);
  }, [onGameEnd, score, isSubmitting]);

  useEffect(() => {
    return () => {
      // Cleanup function - submit score if game is still active when unmounting
      if (gameActive && !isSubmitting) {
        completeQuickfireQuiz(12, score).catch((error) => {
          console.error('Failed to submit quiz score on unmount:', error);
        });
      }
    };
  }, [gameActive, score, isSubmitting]);

  const getWallStyle = (side: 'left' | 'right') => {
    const wallStartTime = 30;
    const progress = timeLeft <= wallStartTime ? (wallStartTime - timeLeft) / wallStartTime : 0;

    let width;
    if (timeLeft <= 0) {
      width = '50%';
    } else if (timeLeft > wallStartTime) {
      width = '0%';
    } else {
      width = `${progress * 50}%`;
    }

    return {
      position: 'absolute' as const,
      top: 0,
      [side]: 0,
      width,
      height: 'min(60vh, 800px)',
      minHeight: 'min(500px, 60vh)',
      backgroundColor: '#fd4545',
      opacity: 0.9,
      transition: 'width 0.3s linear',
      zIndex: 1,
    };
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const currentQuestion = shuffledQuestions[currentQuestionIndex];
    const normalizedUserAnswer = userAnswer.toLowerCase().trim();

    const isCorrect = Array.isArray(currentQuestion.answer)
      ? currentQuestion.answer.some((ans) => ans.toLowerCase().trim() === normalizedUserAnswer)
      : normalizedUserAnswer === currentQuestion.answer.toLowerCase().trim();

    // Flash green for correct, red for incorrect
    setFlashColor(isCorrect ? '#66cc00' : '#fd4545');
    setTimeout(() => setFlashColor(''), 150);

    if (isCorrect) {
      setScore(score + 1);
      onGameEnd(false, false, GAME_CONFIG.BONUS_TIME_PER_CORRECT);
      setLastCorrectAnswer(null);
    } else {
      setLastCorrectAnswer(formatAnswer(currentQuestion.answer));
    }

    setUserAnswer('');
    const nextQuestionIndex = currentQuestionIndex + 1;

    if (nextQuestionIndex >= shuffledQuestions.length && !isSubmitting) {
      setIsSubmitting(true);
      try {
        await completeQuickfireQuiz(12, score);
        setGameActive(false);
        onGameEnd(false, true);
      } catch (error) {
        console.error('Failed to submit quiz score:', error);
        setGameActive(false);
        onGameEnd(false);
      } finally {
        setIsSubmitting(false);
      }
      return;
    }

    setCurrentQuestionIndex(nextQuestionIndex);
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px', position: 'relative' }}>
      <div style={getWallStyle('left')} />
      <div style={getWallStyle('right')} />

      <div style={{ position: 'relative', zIndex: 2 }}>
        <div
          style={{
            fontSize: 'clamp(1.5rem, 4vw, 2.5rem)',
            marginBottom: 'clamp(15px, 3vh, 30px)',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div>Score: {score}</div>
        </div>

        {gameActive ? (
          <div>
            <h2
              style={{
                fontSize: 'clamp(1.2rem, 3vw, 1.8rem)',
                marginBottom: 'clamp(15px, 3vh, 25px)',
                padding: '0 clamp(10px, 2vw, 20px)',
              }}
            >
              {shuffledQuestions[currentQuestionIndex].question}
            </h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
                placeholder="Type your answer..."
                style={inputStyle}
                autoFocus
                enterKeyHint="send"
              />
              <button
                type="submit"
                style={{
                  padding: 'clamp(8px, 1.5vw, 12px) clamp(15px, 3vw, 25px)',
                  fontSize: 'clamp(1rem, 2vw, 1.2rem)',
                  backgroundColor: '#66cc00',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginTop: 'clamp(10px, 2vh, 20px)',
                }}
                className="d-none d-sm-inline-block"
              >
                Submit
              </button>
              {lastCorrectAnswer && (
                <div
                  style={{
                    marginTop: 'clamp(40px, 8vh, 60px)',
                    color: '#282c34',
                    fontSize: 'clamp(1.2rem, 2.4vw, 1.4rem)',
                  }}
                >
                  Correct answer: {lastCorrectAnswer}
                </div>
              )}
            </form>
          </div>
        ) : (
          <div
            style={{
              fontSize: 'clamp(1.3rem, 3.5vw, 2rem)',
              marginTop: 'clamp(20px, 4vh, 40px)',
            }}
          >
            Game Over! Final Score: {score}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickfireQuiz;
