import React from 'react';
import DescriptionCard from '../DescriptionCard';

const WordleDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <DescriptionCard
      title="Wordle"
      description={`**How to Play:** Your challenge is to guess the mystery 5-letter word. Enter any valid 5-letter word to reveal hints along the way. A green square means the letter is in the correct spot. A yellow square means the letter is in the word but in the wrong spot. You have 6 attempts to uncover the correct word.

⚠️ This is a timed challenge. Once you hit 'start', the clock is ticking. You cannot pause and there are no retries. If you leave the page before completing, you will fail.

**Win/Lose Conditions**: Submit the correct word within 6 attempts to secure immunity for the day. Fail to crack the word in 6 guesses, and you'll be facing elimination on Day 1. Yikes 😱.

**Estimated time required**: 2 minutes

**Fun-rating:** 10/10`}
      onStart={onStart}
    />
  );
};

export default WordleDescription;
