import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

interface DescriptionCardProps {
  title?: string;
  description: string;
  warning?: string;
  onStart: () => void;
}

const DescriptionCard: React.FC<DescriptionCardProps> = ({
  title,
  description,
  warning,
  onStart,
}) => {
  return (
    <div className="Description-card">
      <div className="scrollable-description">
        {title && <h2>{title}</h2>}
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{description}</ReactMarkdown>
        {warning && <p className="warning">{warning}</p>}
      </div>
      <button className="Start-button" onClick={onStart}>
        Start
      </button>
    </div>
  );
};

export default DescriptionCard;
