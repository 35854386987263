import React, { useState } from 'react';
import styled from 'styled-components';
import { completeRedPillGame } from '../api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: clamp(10px, 2vw, 20px);
  box-sizing: border-box;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  aspect-ratio: 16/9;
`;

const PillImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const PillClickArea = styled.button<{ side: 'left' | 'right' }>`
  position: absolute;
  top: 0;
  ${(props) => props.side}: 0;
  width: 50%;
  height: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) =>
      props.side === 'left' ? 'rgba(255, 0, 0, 0.1)' : 'rgba(0, 0, 255, 0.1)'};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
  }
`;

const Round = styled.h2`
  text-align: center;
  margin-bottom: clamp(6px, 1vh, 10px);
  font-size: clamp(1.2rem, 2.5vw, 1.8rem);
`;

const Description = styled.p`
  text-align: center;
  margin-bottom: clamp(12px, 2vh, 20px);
  font-size: clamp(0.9rem, 1.8vw, 1.1rem);
`;

const Question = styled.div<{ pillColor?: 'red' | 'blue' }>`
  margin: clamp(15px, 2vh, 20px) 0;
  text-align: center;
  font-size: clamp(0.9rem, 2vw, 1.1rem);
  padding: 0 clamp(10px, 2vw, 20px);
  color: ${(props) =>
    props.pillColor === 'red' ? '#fd4545' : props.pillColor === 'blue' ? '#0000ff' : 'inherit'};
`;

const Select = styled.select`
  width: 100%;
  max-width: min(400px, 80%);
  padding: clamp(8px, 1.5vh, 10px);
  margin: clamp(15px, 2vh, 20px) auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: clamp(0.9rem, 1.8vw, 1rem);
`;

const SubmitButton = styled.button`
  padding: clamp(8px, 1.5vh, 10px) clamp(15px, 3vw, 20px);
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: clamp(0.9rem, 1.8vw, 1rem);
  margin: 0 auto;
  min-width: clamp(120px, 20vw, 200px);
  max-width: min(80%, 300px);

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #45a049;
  }
`;

interface RedPillProps {
  onGameEnd: (success: boolean, isPending?: boolean) => void;
  gameId: number;
}

const questions = {
  blue: [
    'If you could save one player from elimination tonight, who would you choose?',
    "If your immunity depended on another player's success, who would you trust to tie it to?",
    'If you could gift one person an extra life to return to the game after elimination, who would you give it to?',
  ],
  red: [
    'If you could eliminate one person tonight, who would you choose?',
    "If your immunity depended on another player's success, who would you absolutely NOT want to tie it to?",
    'If you could drag one person down with you when you are eliminated—even if they have immunity—who would it be?',
  ],
};

const PLAYER_NAMES = [
  'AB',
  '#blinktwiceifyouarehereagainstyourwill',
  'A&T',
  'Ash vs. the world',
  'Awesome Adventurists',
  'Barbwin',
  'Blink Twice (Emma)',
  'Bryn',
  'Cec',
  'Chalsted the witch',
  'Charlene',
  'Chr-issy',
  "Coco's",
  'DD',
  "Frida's Friends",
  "Ghintz don't need no hints",
  'Hats',
  'Just There For Fun',
  'La Jefa',
  'L Mac',
  'Limoncello spritzes',
  'MaddieMonkey',
  'MP Crew',
  'Mr Boss Man',
  'nearkat',
  'North Pole creepers',
  'Ol_dog',
  'Or-Villain',
  "Santa's Slaaaaaay",
  'Sarjar',
  'Shane Warne',
  'ShanelN5',
  'Sid',
  'Silly goose 🪿',
  'Tam Inzebar',
  'Team KB',
  'The Grinch',
  'Tjabs',
  'tommyarox',
  'Wicked witch of Clifton Hill',
];

const RedPill: React.FC<RedPillProps> = ({ onGameEnd, gameId }) => {
  const [currentRound, setCurrentRound] = useState(1);
  const [selectedPill, setSelectedPill] = useState<'red' | 'blue' | null>(null);
  const [answer, setAnswer] = useState('');
  const [answers, setAnswers] = useState<
    Array<{ round: number; pill: 'red' | 'blue'; answer: string }>
  >([]);

  const handlePillSelect = (pill: 'red' | 'blue') => {
    setSelectedPill(pill);
  };

  const handleSubmitAnswer = async () => {
    if (!selectedPill || !answer.trim()) return;

    const newAnswer = {
      round: currentRound,
      pill: selectedPill,
      answer: answer.trim(),
    };

    const newAnswers = [...answers, newAnswer];
    setAnswers(newAnswers);

    if (currentRound === 3) {
      try {
        await completeRedPillGame(gameId, newAnswers);
        onGameEnd(true, true); // Indicates success and pending status
      } catch (error) {
        console.error('Error submitting Red Pill Game Completion:', error);
        onGameEnd(false);
      }
    } else {
      setCurrentRound(currentRound + 1);
      setSelectedPill(null);
      setAnswer('');
    }
  };

  const getCurrentQuestion = () => {
    if (!selectedPill) return '';
    return questions[selectedPill][currentRound - 1];
  };

  return (
    <Container>
      <Round>Question {currentRound}/3</Round>
      {!selectedPill && <Description>Red pill or blue pill?</Description>}

      <ImageContainer>
        <PillImage src="/assets/red-pill-full.webp" alt="Choose your pill" />
        <PillClickArea
          side="left"
          onClick={() => handlePillSelect('red')}
          disabled={selectedPill !== null}
          aria-label="Select Red Pill"
        />
        <PillClickArea
          side="right"
          onClick={() => handlePillSelect('blue')}
          disabled={selectedPill !== null}
          aria-label="Select Blue Pill"
        />
      </ImageContainer>

      {selectedPill && (
        <>
          <Question pillColor={selectedPill}>{getCurrentQuestion()}</Question>
          <Select value={answer} onChange={(e) => setAnswer(e.target.value)}>
            <option value="">Select a player...</option>
            {PLAYER_NAMES.map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </Select>
          <SubmitButton onClick={handleSubmitAnswer} disabled={!answer}>
            {currentRound === 3 ? 'Submit Final Answer' : 'Next Question'}
          </SubmitButton>
        </>
      )}
    </Container>
  );
};

const RedPillWrapper: React.FC<{
  onGameEnd: (success: boolean, isPending?: boolean) => void;
}> = ({ onGameEnd }) => {
  const gameId = 11;
  return <RedPill onGameEnd={onGameEnd} gameId={gameId} />;
};

export default RedPillWrapper;
