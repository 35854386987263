import React from 'react';
import DescriptionCard from '../DescriptionCard';

const PokemonDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <DescriptionCard
      title="Pokemon Battle"
      description={`**How to Play:** Step into the world of Pokemon! You'll engage in a classic turn-based battle against a rival trainer. Choose your moves wisely to defeat your opponent's Pokemon. Each Pokemon has unique moves and stats that will affect the battle outcome.

⚠️ This is a timed challenge. Once you hit 'start', you're locked in battle. There are no timeouts or retries. Leaving mid-battle counts as a loss.

**Win/Lose Conditions**: Defeat your opponent's Pokemon to win immunity for the day. If your Pokemon faints, you'll face elimination. Choose wisely!

**Estimated time required**: 5-10 minutes

**Fun-rating:** 10/10`}
      onStart={onStart}
    />
  );
};

export default PokemonDescription;
