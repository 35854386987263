import React from 'react';
import DescriptionCard from '../DescriptionCard';

const DejaWhoDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <DescriptionCard
      title="Déjà Who"
      description={`**How to play:** Your challenge is to correctly identify the duplicated profile picture. A randomised series of profile pictures will be shown, with one appearing twice in the line up. Spot the duplicate and press 'Been There, Seen That!' or hit the space bar while the image is still displayed. 

There are 3 rounds in total. Each round, you must accurately identify the duplicated profile picture. Incorrect guesses or failure to identify the duplicate will reset you to Level 1. For a nice change today, you have unlimited attempts. 

⚠️  This is not a timed challenge. Refreshing, leaving and re-opening the page should be fine and dandy. 

**Win/Lose Conditions**: Successfully identify the duplicate in all three rounds to pass the challenge and earn immunity. Failure to complete the challenge will earn you a spot on the wheel of elimination. 

**Estimated time required**: 5 minutes. 

**Fun-rating:** 10/10`}
      onStart={onStart}
    />
  );
};

export default DejaWhoDescription;
