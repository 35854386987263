import React, { useState } from 'react';
import styled from '@emotion/styled';

interface PyramidWord {
  numbers: number[];
  word: string;
  solved: boolean;
  guessedLetters: { [key: number]: string };
}

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  max-height: inherit;
  overflow: hidden;
`;

const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: clamp(10px, 2vw, 20px);
  gap: clamp(5px, 1vh, 10px);

  /* Hide scrollbar but keep functionality */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Pyramid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: clamp(3px, 0.8vh, 8px);
  width: 100%;
  flex-shrink: 0;
`;

const PyramidLevel = styled.div<{ isActive: boolean }>`
  display: flex;
  gap: clamp(3px, 0.8vw, 8px);
  justify-content: center;
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  transition: opacity 0.3s ease;
  flex-shrink: 0;
`;

const NumberBox = styled.div<{ isSolved: boolean }>`
  width: clamp(30px, 6vw, 45px);
  height: clamp(30px, 6vw, 45px);
  border: min(2px, 0.5vw) solid #333;
  border-radius: min(4px, 1vw);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background-color: ${(props) => (props.isSolved ? '#66CC00' : '#FFFBE6')};
  transition: all 0.2s ease;
  flex-shrink: 0;
`;

const Number = styled.div<{ isSolved: boolean }>`
  font-size: ${(props) =>
    props.isSolved ? 'clamp(0.65rem, 1.3vw, 0.75rem)' : 'clamp(0.9rem, 1.8vw, 1.2rem)'};
  color: ${(props) => (props.isSolved ? '#FFFFFF' : '#999')};
  position: ${(props) => (props.isSolved ? 'absolute' : 'static')};
  top: ${(props) => (props.isSolved ? '2px' : 'auto')};
  left: ${(props) => (props.isSolved ? '2px' : 'auto')};
`;

const Letter = styled.div`
  font-size: clamp(0.9rem, 1.8vw, 1.2rem);
  font-weight: bold;
  color: #ffffff;
`;

const GameOver = styled.div`
  margin-top: min(10px, 2vh);
  font-size: min(1.2rem, 3vw);
  color: #fd4545;
  text-align: center;
  padding: min(8px, 2vw);
  border-radius: 4px;
  background-color: rgba(253, 69, 69, 0.1);
  flex-shrink: 0;
`;

const Keyboard = styled.div`
  display: flex;
  flex-direction: column;
  gap: min(2px, 0.5vh);
  width: 100%;
  max-width: min(600px, 95%);
  padding: min(3px, 0.8vw);
  box-sizing: border-box;
  flex-shrink: 0;

  /* Add scaling based on container height */
  font-size: clamp(14px, 2vh, 18px);

  /* Make keyboard scrollable if needed */
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  /* Hide scrollbar */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  margin-top: clamp(5px, 1vh, 8px);
`;

const KeyboardRow = styled.div`
  display: flex;
  gap: min(2px, 0.5vw);
  justify-content: center;
  flex-shrink: 0;

  /* Ensure minimum spacing between keys */
  min-height: min(30px, 5vh);
`;

const Key = styled.button<{ used: boolean; correct: boolean; incorrect: boolean }>`
  font-size: 0.9em;
  width: clamp(28px, 5vw, 40px);
  height: clamp(28px, 5vw, 40px);
  padding: 0;

  /* Aggressively remove all button states and transitions */
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  outline: none;

  &:active,
  &:focus,
  &:hover {
    outline: none;
    -webkit-tap-highlight-color: transparent;
    background-color: ${(props) =>
      props.correct ? '#66CC00' : props.incorrect ? '#FD4545' : props.used ? '#CCC' : '#F0F0F0'};
  }

  &:active {
    transform: none;
  }

  /* Override any default button styles */
  appearance: none;
  -webkit-appearance: none;

  background-color: ${(props) =>
    props.correct ? '#66CC00' : props.incorrect ? '#FD4545' : props.used ? '#CCC' : '#FFF'};

  color: ${(props) => (props.used ? '#666' : '#333')};
  border: min(2px, 0.3vw) solid ${(props) => (props.used ? '#999' : '#333')};

  cursor: ${(props) => (props.used ? 'not-allowed' : 'pointer')};
  border-radius: min(4px, 0.8vw);

  display: flex;
  align-items: center;
  justify-content: center;
`;

const GuessesRemaining = styled.div`
  font-size: clamp(1rem, 2vw, 1.3rem);
  margin: clamp(8px, 2vh, 15px) 0;
  flex-shrink: 0;
  font-weight: 500;
  color: #333;
`;

const Title = styled.h2`
  text-align: center;
  margin: 0 0 clamp(1em, 2vh, 1.5em) 0;
  font-size: clamp(1.5rem, 3vw, 2rem);
  padding: 0 clamp(15px, 3vw, 30px);
  box-sizing: border-box;
  font-family: 'Chakra Petch', sans-serif;
  color: #282c34;

  @media (max-width: 768px) {
    font-size: clamp(1.3rem, 2.5vw, 1.8rem);
    margin-bottom: clamp(1.1rem, 2vh, 1.5rem);
  }

  @media (max-width: 480px) {
    font-size: clamp(1.2rem, 2.2vw, 1.5rem);
    margin-bottom: clamp(0.9rem, 1.8vh, 1.3rem);
    padding: 0 clamp(10px, 2vw, 20px);
  }
`;

const Description = styled.p`
  text-align: center;
  margin: 0 0 clamp(1.5em, 3vh, 2em) 0;
  font-size: clamp(1.1rem, 2.2vw, 1.4rem);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 clamp(20px, 4vw, 40px);
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: clamp(0.95rem, 2vw, 1.2rem);
    margin-bottom: clamp(1.3rem, 2.5vh, 1.8rem);
    padding: 0 clamp(15px, 3vw, 30px);
  }

  @media (max-width: 480px) {
    font-size: clamp(0.9rem, 1.8vw, 1.1rem);
    margin-bottom: clamp(1.1rem, 2vh, 1.5rem);
    padding: 0 clamp(10px, 2vw, 20px);
  }
`;

const Cyphr: React.FC<{ onGameEnd: (success: boolean) => void }> = ({ onGameEnd }) => {
  const [pyramidWords] = useState<PyramidWord[]>([
    { numbers: [1], word: 'A', solved: false, guessedLetters: {} }, // Level 1: A -> 1
    { numbers: [13, 12], word: 'ON', solved: false, guessedLetters: {} }, // Level 2: O -> 13, N -> 12
    { numbers: [5, 1, 7], word: 'RAY', solved: false, guessedLetters: {} }, // Level 3: R -> 5, A -> 1, Y -> 7
    { numbers: [8, 3, 10, 8], word: 'HIGH', solved: false, guessedLetters: {} }, // Level 4: H -> 8, I -> 3, G -> 10, H -> 8
    { numbers: [1, 11, 11, 3, 6], word: 'AFFIX', solved: false, guessedLetters: {} }, // Level 5: A -> 1, F -> 11, F -> 11, I -> 3, X -> 6
    { numbers: [2, 3, 12, 10, 4, 5], word: 'LINGER', solved: false, guessedLetters: {} }, // Level 6: L -> 2, I -> 3, N -> 12, G -> 10, E -> 4, R -> 5
    { numbers: [11, 4, 9, 4, 5, 1, 2], word: 'FEDERAL', solved: false, guessedLetters: {} }, // Level 7: F -> 11, E -> 4, D -> 9, E -> 4, R -> 5, A -> 1, L -> 2
  ]);

  const [currentLevel, setCurrentLevel] = useState(0);
  const [guessesRemaining, setGuessesRemaining] = useState(26);
  const [usedKeys, setUsedKeys] = useState<{ [key: string]: 'correct' | 'incorrect' | 'used' }>({});

  // Split keyboard into more rows for smaller screens
  const qwertyLayout = ['QWERTYUIOP', 'ASDFGHJKL', 'ZXCVBNM'];

  const moveToNextLevel = (nextLevel: number) => {
    setCurrentLevel(nextLevel);
    setUsedKeys({});
  };

  const handleKeyPress = (letter: string) => {
    if (guessesRemaining <= 0) return;
    letter = letter.toUpperCase();

    const currentWord = pyramidWords[currentLevel];
    let letterFound = false;

    // Check if the letter appears in the current word
    for (let i = 0; i < currentWord.word.length; i++) {
      if (currentWord.word[i] === letter) {
        const number = currentWord.numbers[i];
        currentWord.guessedLetters[number] = letter;
        letterFound = true;
      }
    }

    // Update used keys and remaining guesses
    if (letterFound) {
      setUsedKeys((prev) => ({ ...prev, [letter]: 'correct' }));

      // Check if word is complete by comparing the guessed letters with the word
      const guessedWord = currentWord.numbers
        .map((num) => currentWord.guessedLetters[num] || '')
        .join('');

      if (guessedWord === currentWord.word) {
        currentWord.solved = true;
        if (currentLevel < pyramidWords.length - 1) {
          moveToNextLevel(currentLevel + 1);
        } else {
          onGameEnd(true);
        }
      }
    } else {
      setUsedKeys((prev) => ({ ...prev, [letter]: 'incorrect' }));
      setGuessesRemaining((prev) => prev - 1);

      if (guessesRemaining <= 1) {
        onGameEnd(false);
      }
    }
  };

  return (
    <Container>
      <ScrollableContent>
        <Title>Cyphr</Title>
        <Description>
          Each number represents a letter. Use the provided keyboard to enter the letters in each
          row.
        </Description>
        <Pyramid>
          {pyramidWords.map((word, levelIndex) => (
            <PyramidLevel key={levelIndex} isActive={levelIndex === currentLevel}>
              {word.numbers.map((number) => {
                return (
                  <NumberBox key={number} isSolved={!!word.guessedLetters[number]}>
                    <Number isSolved={!!word.guessedLetters[number]}>{number}</Number>
                    {word.guessedLetters[number] && <Letter>{word.guessedLetters[number]}</Letter>}
                  </NumberBox>
                );
              })}
            </PyramidLevel>
          ))}
        </Pyramid>

        <GuessesRemaining>Guesses Remaining: {guessesRemaining}</GuessesRemaining>

        <Keyboard>
          {qwertyLayout.map((row, rowIndex) => (
            <KeyboardRow key={rowIndex}>
              {row.split('').map((key) => (
                <Key
                  key={key}
                  onClick={() => handleKeyPress(key)}
                  used={!!usedKeys[key]}
                  correct={usedKeys[key] === 'correct'}
                  incorrect={usedKeys[key] === 'incorrect'}
                >
                  {key}
                </Key>
              ))}
            </KeyboardRow>
          ))}
        </Keyboard>

        {guessesRemaining <= 0 && <GameOver>Game Over! Out of guesses.</GameOver>}
      </ScrollableContent>
    </Container>
  );
};

export default Cyphr;
