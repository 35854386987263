import React from 'react';
import DescriptionCard from '../DescriptionCard';

const CyphrDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <DescriptionCard
      title="Cyphr"
      description={`**How to play:** Your challenge is to decipher the pyramid. The pyramid consists of 7 rows, each containing a word. The words are selected at random and do not form a sentence. Each box in the pyramid has a number that corresponds to a letter, and the same code applies throughout the pyramid.

Start at the top of the pyramid and guess the word in the top row. Correct guesses will turn the corresponding tile green. Incorrect guesses will turn the letter red on the keyboard. You don't need to guess the letters in the order they appear in the word. Once a row is completed, move to the next row underneath.

You have a maximum of **26 incorrect** guesses to solve the entire pyramid.

⚠️  This is a timed challenge and you are limited to 26 incorrect guesses. Leaving and re-entering or refreshing the page may result in progress lost. 

**Win/Lose Conditions**: Successfully decipher all of the words in the pyramid to earn immunity. If you run out of guesses, your name will be added to the elimination wheel. 

**Estimated time required**: 10 minutes.

**Fun-rating:** 10/10`}
      onStart={onStart}
    />
  );
};

export default CyphrDescription;
