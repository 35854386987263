import React from 'react';
import DescriptionCard from '../DescriptionCard';

const PacmanDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => (
  <DescriptionCard
    title="Game: Pacman"
    description="Navigate through the maze, collecting dots while avoiding ghosts. Use arrow keys or WASD to move. Collect all dots to win!"
    warning="Beware - three ghost encounters and it's game over!"
    onStart={onStart}
  />
);

export default PacmanDescription;
