import React, { useEffect, useState } from 'react';
import { getGames, Game } from '../api';
import { useNavigate } from 'react-router-dom';
import { getGamePath } from '../config/GameMapping';

interface GameSelectorProps {
  onGameSelect: (gameId: number) => void;
}

const GameSelector: React.FC<GameSelectorProps> = ({ onGameSelect }) => {
  const [games, setGames] = useState<Game[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const gamesData = await getGames();
        setGames(gamesData || []);
      } catch (error) {
        console.error('Error fetching games:', error);
        setGames([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGames();
  }, []);

  const handleGameChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const path = event.target.value;
    if (path) {
      const selectedGame = games.find((game) => getGamePath(game.game_name) === path.substring(1));
      if (selectedGame) {
        onGameSelect(selectedGame.game_id);
      }
      navigate(path);
    }
  };

  if (isLoading) {
    return (
      <select
        style={{
          padding: '8px',
          marginLeft: '20px',
          borderRadius: '4px',
          border: '1px solid #ccc',
        }}
        disabled
      >
        <option>Loading games...</option>
      </select>
    );
  }

  return (
    <select
      onChange={handleGameChange}
      style={{
        padding: '8px',
        marginLeft: '20px',
        borderRadius: '4px',
        border: '1px solid #ccc',
      }}
    >
      <option value="">Select a game</option>
      {games &&
        games.length > 0 &&
        games.map((game) => {
          const gamePath = getGamePath(game.game_name);
          return (
            <option key={game.game_id} value={`/${gamePath}`}>
              {game.game_name || 'Unnamed Game'}
            </option>
          );
        })}
    </select>
  );
};

export default GameSelector;
