import React from 'react';
import Wordle from '../games/wordle';
import WordleDescription from '../components/game_descriptions/WordleDescription';
import ChooseABox from '../games/choose_a_box';
import ChooseABoxDescription from '../components/game_descriptions/ChooseABoxDescription';
import LetterPile from '../games/letter_pile';
import LetterPileDescription from '../components/game_descriptions/LetterPileDescription';
import Squaredle from '../games/squaredle';
import SquaredleDescription from '../components/game_descriptions/SquaredleDescription';
import Cyphr from '../games/cyphr';
import CyphrDescription from '../components/game_descriptions/CyphrDescription';
import GandalfTheWhite from '../games/gandalf_the_white';
import GandalfDescription from '../components/game_descriptions/GandalfDescription';
import WildVotes from '../games/wild_votes';
import WildVotesDescription from '../components/game_descriptions/WildVotesDescription';
import WordTies from '../games/word_ties';
import WordTiesDescription from '../components/game_descriptions/WordTiesDescription';
import GuessTheWord from '../games/guess_the_word';
import GuessTheWordDescription from '../components/game_descriptions/GuessTheWordDescription';
import Pacman from '../games/pacman';
import PacmanDescription from '../components/game_descriptions/PacmanDescription';
import RedPill from '../games/red_pill';
import RedPillDescription from '../components/game_descriptions/RedPillDescription';
import QuickfireQuiz from '../games/quickfire_quiz';
import QuickfireQuizDescription from '../components/game_descriptions/QuickfireQuizDescription';
import Pokemon from '../games/pokemon';
import PokemonDescription from '../components/game_descriptions/PokemonDescription';
import SecretNumber from '../games/secret_number';
import SecretNumberDescription from '../components/game_descriptions/SecretNumberDescription';
import DejaWho from '../games/deja_who';
import DejaWhoDescription from '../components/game_descriptions/DejaWhoDescription';
import Enigma from '../games/enigma';
import EnigmaDescription from '../components/game_descriptions/EnigmaDescription';
import Crossword from '../games/crossword';
import CrosswordDescription from '../components/game_descriptions/CrosswordDescription';

export interface GameComponent {
  game: React.FC<{ onGameEnd: (success: boolean) => void }>;
  description: React.FC<{ onStart: () => void }>;
  path: string;
}

export const gameComponents: { [key: string]: GameComponent } = {
  wordle: {
    game: Wordle,
    description: WordleDescription,
    path: 'wordle',
  },
  'choose-a-box': {
    game: ChooseABox,
    description: ChooseABoxDescription,
    path: 'choose-a-box',
  },
  'letter-pile': {
    game: LetterPile,
    description: LetterPileDescription,
    path: 'letter-pile',
  },
  squaredle: {
    game: Squaredle,
    description: SquaredleDescription,
    path: 'squaredle',
  },
  cyphr: {
    game: Cyphr,
    description: CyphrDescription,
    path: 'cyphr',
  },
  'gandalf-the-white': {
    game: GandalfTheWhite,
    description: GandalfDescription,
    path: 'gandalf-the-white',
  },
  'wild-votes': {
    game: WildVotes,
    description: WildVotesDescription,
    path: 'wild-votes',
  },
  'word-ties': {
    game: WordTies,
    description: WordTiesDescription,
    path: 'word-ties',
  },
  'guess-the-word': {
    game: GuessTheWord,
    description: GuessTheWordDescription,
    path: 'guess-the-word',
  },
  pacman: {
    game: Pacman,
    description: PacmanDescription,
    path: 'pacman',
  },
  'red-pill': {
    game: RedPill,
    description: RedPillDescription,
    path: 'red-pill',
  },
  'quickfire-quiz': {
    game: QuickfireQuiz,
    description: QuickfireQuizDescription,
    path: 'quickfire-quiz',
  },
  pokemon: {
    game: Pokemon,
    description: PokemonDescription,
    path: 'pokemon',
  },
  'secret-number': {
    game: SecretNumber,
    description: SecretNumberDescription,
    path: 'secret-number',
  },
  'deja-who': {
    game: DejaWho,
    description: DejaWhoDescription,
    path: 'deja-who',
  },
  enigma: {
    game: Enigma,
    description: EnigmaDescription,
    path: 'enigma',
  },
  crossword: {
    game: Crossword,
    description: CrosswordDescription,
    path: 'crossword',
  },
};

export const getGamePath = (gameName: string): string => {
  const key = gameName.toLowerCase().replace(/\s+/g, '-');
  if (gameComponents[key]) {
    return gameComponents[key].path;
  }
  return gameName.toLowerCase().replace(/\s+/g, '-');
};
