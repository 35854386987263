import React from 'react';
import DescriptionCard from '../DescriptionCard';

const WordTiesDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <DescriptionCard
      title="Word Ties"
      description={`**How to Play:** Your challenge is to organise 16 words into **4 groups of 4** based on a shared connection or common theme. To do this, click on words to swap their positions, arranging related words in the same coloured row. Once words are grouped, hit 'Submit' to finalise your categories.

⚠️  This is not a timed challenge. You will be able to re-enter the game as many times as you wish before hitting submit. If you leave the page and come back in, your progress will not be saved. 

**Win/Lose Conditions**: Submit the correct 4 groups to secure immunity for the day. Failure to submit correct word categories and you'll be up for elimination. 

**Estimated time required**: 5 minutes

**Fun-rating:** 10/10`}
      onStart={onStart}
    />
  );
};

export default WordTiesDescription;
