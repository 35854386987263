import React, { useState } from 'react';
import styled from 'styled-components';

interface PokemonType {
  name:
    | 'Normal'
    | 'Fire'
    | 'Water'
    | 'Electric'
    | 'Grass'
    | 'Ice'
    | 'Fighting'
    | 'Poison'
    | 'Ground'
    | 'Flying'
    | 'Psychic'
    | 'Bug'
    | 'Rock'
    | 'Ghost'
    | 'Dragon'
    | 'Dark'
    | 'Steel'
    | 'Fairy';
}

interface Pokemon {
  name: string;
  maxHp: number;
  currentHp: number;
  moves: Move[];
  level: number;
  type: [PokemonType['name'], PokemonType['name']?];
  attack: number;
  defense: number;
  specialAttack: number;
  specialDefense: number;
  speed: number;
  attackStage: number;
  defenseStage: number;
  speedStage: number;
}

interface Move {
  name: string;
  type: PokemonType['name'];
  category: 'Physical' | 'Special' | 'Status';
  power: number;
  accuracy: number;
  pp: number;
  maxPp: number;
  effect: string;
}

interface PokemonSpriteProps {
  isEnemy?: boolean;
}

interface PokemonStatsProps {
  isEnemy?: boolean;
  pokemon: Pokemon;
}

interface PokemonMenuProps {
  pokemon: Pokemon;
  isSelected?: boolean;
  onClick?: () => void;
  allowFainted?: boolean;
}

const BattleContainer = styled.div`
  width: min(90%, 800px);
  height: 100%;
  margin: 0 auto;
  padding: clamp(10px, 2vw, 20px);
  font-family: 'Press Start 2P', monospace;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const BattleScene = styled.div`
  background-color: #f0f0f0;
  border: 4px solid #333;
  border-radius: 8px;
  padding: clamp(15px, 3vw, 30px);
  margin-bottom: clamp(10px, 2vw, 20px);
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: min(400px, 70vh);
`;

const PokemonSprite = styled.img<PokemonSpriteProps>`
  image-rendering: pixelated;
  width: clamp(64px, 12vw, 96px);
  height: clamp(64px, 12vw, 96px);
  position: absolute;
  ${(props) =>
    props.isEnemy
      ? `
    top: 15%;
    right: 15%;
  `
      : `
    bottom: 25%;
    left: 15%;
  `}
`;

const HPBox = styled.div<{ isEnemy?: boolean }>`
  background-color: white;
  border: 4px double #333;
  border-radius: 0;
  padding: clamp(8px, 1.5vw, 12px);
  width: clamp(180px, 35%, 250px);
  position: absolute;
  ${(props) =>
    props.isEnemy
      ? `
    top: 5%;
    left: 5%;
  `
      : `
    bottom: 35%;
    right: 5%;
  `}
`;

const HPLabel = styled.span`
  background-color: #daa520;
  color: black;
  padding: 2px 4px;
  margin-right: 8px;
  font-size: clamp(0.6rem, 1.2vw, 0.8rem);
  font-weight: bold;
`;

const HPBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: clamp(8px, 1.5vw, 12px);
  background-color: #ddd;
  border: 2px solid #333;
  margin: 4px 0;

  &::after {
    content: '';
    position: absolute;
    right: -8px;
    top: -2px;
    bottom: -2px;
    width: 8px;
    background-color: #333;
    clip-path: polygon(0 0, 100% 50%, 0 100%);
  }
`;

const HealthBar = styled.div<{ percentage: number }>`
  width: ${(props) => props.percentage}%;
  height: 100%;
  background-color: ${(props) => {
    if (props.percentage > 50) return '#4caf50'; // Green
    if (props.percentage > 20) return '#ffd700'; // Yellow
    return '#ff0000'; // Red
  }};
  transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;
`;

const calculateTypeEffectiveness = (
  moveType: PokemonType['name'],
  targetTypes: [PokemonType['name'], PokemonType['name']?],
): number => {
  const typeChart: Record<PokemonType['name'], Record<PokemonType['name'], number>> = {
    Normal: {
      Normal: 1,
      Fire: 1,
      Water: 1,
      Electric: 1,
      Grass: 1,
      Ice: 1,
      Fighting: 1,
      Poison: 1,
      Ground: 1,
      Flying: 1,
      Psychic: 1,
      Bug: 1,
      Rock: 0.5,
      Ghost: 0,
      Dragon: 1,
      Dark: 1,
      Steel: 0.5,
      Fairy: 1,
    },
    Fire: {
      Normal: 1,
      Fire: 0.5,
      Water: 0.5,
      Electric: 1,
      Grass: 2,
      Ice: 2,
      Fighting: 1,
      Poison: 1,
      Ground: 1,
      Flying: 1,
      Psychic: 1,
      Bug: 2,
      Rock: 0.5,
      Ghost: 1,
      Dragon: 0.5,
      Dark: 1,
      Steel: 2,
      Fairy: 1,
    },
    Water: {
      Normal: 1,
      Fire: 2,
      Water: 0.5,
      Electric: 1,
      Grass: 0.5,
      Ice: 1,
      Fighting: 1,
      Poison: 1,
      Ground: 2,
      Flying: 1,
      Psychic: 1,
      Bug: 1,
      Rock: 2,
      Ghost: 1,
      Dragon: 0.5,
      Dark: 1,
      Steel: 1,
      Fairy: 1,
    },
    Electric: {
      Normal: 1,
      Fire: 1,
      Water: 2,
      Electric: 0.5,
      Grass: 0.5,
      Ice: 1,
      Fighting: 1,
      Poison: 1,
      Ground: 0,
      Flying: 2,
      Psychic: 1,
      Bug: 1,
      Rock: 1,
      Ghost: 1,
      Dragon: 0.5,
      Dark: 1,
      Steel: 1,
      Fairy: 1,
    },
    Grass: {
      Normal: 1,
      Fire: 0.5,
      Water: 2,
      Electric: 1,
      Grass: 0.5,
      Ice: 1,
      Fighting: 1,
      Poison: 0.5,
      Ground: 2,
      Flying: 0.5,
      Psychic: 1,
      Bug: 0.5,
      Rock: 2,
      Ghost: 1,
      Dragon: 0.5,
      Dark: 1,
      Steel: 0.5,
      Fairy: 1,
    },
    Ice: {
      Normal: 1,
      Fire: 0.5,
      Water: 0.5,
      Electric: 1,
      Grass: 2,
      Ice: 0.5,
      Fighting: 1,
      Poison: 1,
      Ground: 2,
      Flying: 2,
      Psychic: 1,
      Bug: 1,
      Rock: 1,
      Ghost: 1,
      Dragon: 2,
      Dark: 1,
      Steel: 0.5,
      Fairy: 1,
    },
    Fighting: {
      Normal: 2,
      Fire: 1,
      Water: 1,
      Electric: 1,
      Grass: 1,
      Ice: 2,
      Fighting: 1,
      Poison: 0.5,
      Ground: 1,
      Flying: 0.5,
      Psychic: 0.5,
      Bug: 0.5,
      Rock: 2,
      Ghost: 0,
      Dragon: 1,
      Dark: 2,
      Steel: 2,
      Fairy: 0.5,
    },
    Poison: {
      Normal: 1,
      Fire: 1,
      Water: 1,
      Electric: 1,
      Grass: 2,
      Ice: 1,
      Fighting: 1,
      Poison: 0.5,
      Ground: 0.5,
      Flying: 1,
      Psychic: 1,
      Bug: 1,
      Rock: 0.5,
      Ghost: 0.5,
      Dragon: 1,
      Dark: 1,
      Steel: 0,
      Fairy: 2,
    },
    Ground: {
      Normal: 1,
      Fire: 2,
      Water: 1,
      Electric: 2,
      Grass: 0.5,
      Ice: 1,
      Fighting: 1,
      Poison: 2,
      Ground: 1,
      Flying: 0,
      Psychic: 1,
      Bug: 0.5,
      Rock: 2,
      Ghost: 1,
      Dragon: 1,
      Dark: 1,
      Steel: 2,
      Fairy: 1,
    },
    Flying: {
      Normal: 1,
      Fire: 1,
      Water: 1,
      Electric: 0.5,
      Grass: 2,
      Ice: 1,
      Fighting: 2,
      Poison: 1,
      Ground: 1,
      Flying: 1,
      Psychic: 1,
      Bug: 2,
      Rock: 0.5,
      Ghost: 1,
      Dragon: 1,
      Dark: 1,
      Steel: 0.5,
      Fairy: 1,
    },
    Psychic: {
      Normal: 1,
      Fire: 1,
      Water: 1,
      Electric: 1,
      Grass: 1,
      Ice: 1,
      Fighting: 2,
      Poison: 2,
      Ground: 1,
      Flying: 1,
      Psychic: 0.5,
      Bug: 1,
      Rock: 1,
      Ghost: 1,
      Dragon: 1,
      Dark: 0,
      Steel: 0.5,
      Fairy: 1,
    },
    Bug: {
      Normal: 1,
      Fire: 0.5,
      Water: 1,
      Electric: 1,
      Grass: 2,
      Ice: 1,
      Fighting: 0.5,
      Poison: 0.5,
      Ground: 1,
      Flying: 0.5,
      Psychic: 2,
      Bug: 1,
      Rock: 1,
      Ghost: 0.5,
      Dragon: 1,
      Dark: 2,
      Steel: 0.5,
      Fairy: 0.5,
    },
    Rock: {
      Normal: 1,
      Fire: 2,
      Water: 1,
      Electric: 1,
      Grass: 1,
      Ice: 2,
      Fighting: 0.5,
      Poison: 1,
      Ground: 0.5,
      Flying: 2,
      Psychic: 1,
      Bug: 2,
      Rock: 1,
      Ghost: 1,
      Dragon: 1,
      Dark: 1,
      Steel: 0.5,
      Fairy: 1,
    },
    Ghost: {
      Normal: 0,
      Fire: 1,
      Water: 1,
      Electric: 1,
      Grass: 1,
      Ice: 1,
      Fighting: 1,
      Poison: 1,
      Ground: 1,
      Flying: 1,
      Psychic: 2,
      Bug: 1,
      Rock: 1,
      Ghost: 2,
      Dragon: 1,
      Dark: 0.5,
      Steel: 1,
      Fairy: 1,
    },
    Dragon: {
      Normal: 1,
      Fire: 1,
      Water: 1,
      Electric: 1,
      Grass: 1,
      Ice: 1,
      Fighting: 1,
      Poison: 1,
      Ground: 1,
      Flying: 1,
      Psychic: 1,
      Bug: 1,
      Rock: 1,
      Ghost: 1,
      Dragon: 2,
      Dark: 1,
      Steel: 0.5,
      Fairy: 0,
    },
    Dark: {
      Normal: 1,
      Fire: 1,
      Water: 1,
      Electric: 1,
      Grass: 1,
      Ice: 1,
      Fighting: 0.5,
      Poison: 1,
      Ground: 1,
      Flying: 1,
      Psychic: 2,
      Bug: 1,
      Rock: 1,
      Ghost: 2,
      Dragon: 1,
      Dark: 0.5,
      Steel: 1,
      Fairy: 0.5,
    },
    Steel: {
      Normal: 1,
      Fire: 0.5,
      Water: 0.5,
      Electric: 0.5,
      Grass: 1,
      Ice: 2,
      Fighting: 1,
      Poison: 1,
      Ground: 1,
      Flying: 1,
      Psychic: 1,
      Bug: 1,
      Rock: 2,
      Ghost: 1,
      Dragon: 1,
      Dark: 1,
      Steel: 0.5,
      Fairy: 2,
    },
    Fairy: {
      Normal: 1,
      Fire: 0.5,
      Water: 1,
      Electric: 1,
      Grass: 1,
      Ice: 1,
      Fighting: 2,
      Poison: 0.5,
      Ground: 1,
      Flying: 1,
      Psychic: 1,
      Bug: 1,
      Rock: 1,
      Ghost: 1,
      Dragon: 2,
      Dark: 2,
      Steel: 0.5,
      Fairy: 1,
    },
  };

  const [primaryType, secondaryType] = targetTypes;
  const primaryEffectiveness = typeChart[moveType][primaryType] || 1;
  const secondaryEffectiveness = secondaryType ? typeChart[moveType][secondaryType] || 1 : 1;

  return primaryEffectiveness * secondaryEffectiveness;
};

/**
 * Determines if a Pokemon can provide type advantage against an opponent
 */
const canProvideTypeAdvantage = (candidate: Pokemon, playerPokemon: Pokemon): boolean => {
  return candidate.moves.some((move) => {
    const effectiveness = calculateTypeEffectiveness(move.type, playerPokemon.type);
    return effectiveness > 1;
  });
};

/**
 * Checks if a Pokemon is at a type disadvantage against an opponent
 */
const isAtTypeDisadvantage = (enemyPokemon: Pokemon, playerPokemon: Pokemon): boolean => {
  const playerPrimaryType = playerPokemon.type[0];
  const effectiveness = calculateTypeEffectiveness(playerPrimaryType, enemyPokemon.type);
  return effectiveness > 1;
};

/**
 * Decides the enemy's action based on strategic considerations
 */
const decideEnemyAction = (
  enemyTeam: Pokemon[],
  currentEnemyIndex: number,
  enemyPokemon: Pokemon,
  playerPokemon: Pokemon,
): { action: 'move' | 'switch'; move?: Move; switchIndex?: number } => {
  // Check for type disadvantage and potential switch
  if (isAtTypeDisadvantage(enemyPokemon, playerPokemon)) {
    for (let i = 0; i < enemyTeam.length; i++) {
      if (
        i !== currentEnemyIndex &&
        enemyTeam[i].currentHp > 0 &&
        canProvideTypeAdvantage(enemyTeam[i], playerPokemon)
      ) {
        return { action: 'switch', switchIndex: i };
      }
    }
  }

  // Choose best move if not switching
  let bestMove: Move | null = null;
  let highestDamage = 0;
  let guaranteedKOmove: Move | null = null;

  for (const move of enemyPokemon.moves) {
    if (move.pp <= 0 || move.category === 'Status') continue;

    const damage = calculateDamage(move, enemyPokemon, playerPokemon);

    if (move.accuracy === 100 && damage >= playerPokemon.currentHp) {
      guaranteedKOmove = move;
      break;
    }

    if (damage > highestDamage) {
      highestDamage = damage;
      bestMove = move;
    }
  }

  if (guaranteedKOmove) {
    return { action: 'move', move: guaranteedKOmove };
  }

  if (bestMove) {
    return { action: 'move', move: bestMove };
  }

  const availableMoves = enemyPokemon.moves.filter((m) => m.pp > 0);
  if (availableMoves.length > 0) {
    const randomMove = availableMoves[Math.floor(Math.random() * availableMoves.length)];
    return { action: 'move', move: randomMove };
  }

  return { action: 'move', move: enemyPokemon.moves[0] };
};

const PokemonStats: React.FC<PokemonStatsProps> = ({ isEnemy, pokemon }) => (
  <HPBox isEnemy={isEnemy}>
    <div style={{ marginBottom: '4px' }}>
      {pokemon.name} L{pokemon.level}
    </div>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <HPLabel>HP</HPLabel>
      <HPBarContainer>
        <HealthBar percentage={(pokemon.currentHp / pokemon.maxHp) * 100} />
      </HPBarContainer>
    </div>
    <div style={{ textAlign: 'right', fontSize: 'clamp(0.6rem, 1.2vw, 0.8rem)' }}>
      {pokemon.currentHp}/{pokemon.maxHp}
    </div>
  </HPBox>
);

type MenuState = 'message' | 'action' | 'moves' | 'pokemon' | 'pack' | 'use-item';

const BattleMenu = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 8px;
  padding: 8px;
`;

const MenuBox = styled.div`
  background-color: white;
  border: 4px double #333;
  border-radius: 8px;
  padding: clamp(10px, 2vw, 15px);
  display: flex;
  align-items: center;
`;

const ActionOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;
  width: 100%;
  height: 100%;
`;

const MoveInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 8px;
  font-size: clamp(0.7rem, 1.4vw, 0.9rem);
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  font-family: inherit;
  font-size: clamp(0.8rem, 1.6vw, 1rem);
  text-align: left;
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }

  &::before {
    content: '▶';
    margin-right: 8px;
    visibility: hidden;
  }

  &:hover::before {
    visibility: visible;
  }
`;

const PokemonMenuContainer = styled.div`
  background-color: white;
  border: 4px double #333;
  border-radius: 8px;
  padding: clamp(10px, 2vw, 15px);
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 100%;
  overflow-y: auto;
`;

const PokemonMenuItem = styled.button<{ isSelected?: boolean; isFainted?: boolean }>`
  display: grid;
  grid-template-columns: 40px 1fr auto;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  font-family: inherit;
  font-size: clamp(0.8rem, 1.6vw, 1rem);
  text-align: left;
  padding: 4px 8px;
  width: 100%;

  &:hover {
    background-color: ${(props) => (!props.isFainted || props.disabled ? '#eee' : 'transparent')};
  }

  ${(props) =>
    props.isSelected &&
    `
    background-color: #e0e0e0;
    &::before {
      content: '▶';
      position: absolute;
      left: 4px;
    }
  `}
`;

const PokemonMenuSprite = styled.img`
  width: 32px;
  height: 32px;
  image-rendering: pixelated;
`;

const HPText = styled.span`
  font-size: clamp(0.7rem, 1.4vw, 0.9rem);
`;

const PokemonMenuOption: React.FC<PokemonMenuProps> = ({
  pokemon,
  isSelected,
  onClick,
  allowFainted = false,
}) => {
  const isFainted = pokemon.currentHp <= 0;

  return (
    <PokemonMenuItem
      isSelected={isSelected}
      isFainted={isFainted}
      onClick={onClick}
      disabled={isFainted && !allowFainted}
      style={{
        cursor: isFainted && !allowFainted ? 'not-allowed' : 'pointer',
        opacity: isFainted && !allowFainted ? 0.6 : 1,
      }}
    >
      <PokemonSpriteWrapper>
        <PokemonMenuSprite
          src={`/assets/pokemon/${pokemon.name.toLowerCase()}-front.png`}
          alt={pokemon.name}
        />
        {isFainted && !allowFainted && <FaintedOverlay />}
      </PokemonSpriteWrapper>
      <span>{pokemon.name}</span>
      <HPText>
        {pokemon.currentHp}/{pokemon.maxHp}
      </HPText>
    </PokemonMenuItem>
  );
};

const getEffectivenessText = (effectiveness: number): string => {
  if (effectiveness > 1) {
    return " It's super effective!";
  } else if (effectiveness < 1 && effectiveness > 0) {
    return " It's not very effective...";
  } else if (effectiveness === 0) {
    return ' It had no effect...';
  }
  return '';
};

// Add type safety for stat stages
type StatStage = -6 | -5 | -4 | -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6;

// Utility functions with improved error handling and documentation
/**
 * Calculates the multiplier for a given stat stage.
 * @param stage The current stat stage (-6 to +6)
 * @returns The corresponding multiplier for the stat
 */
const getStatMultiplier = (stage: number): number => {
  // Ensure stage is within valid bounds
  const boundedStage = Math.min(Math.max(stage, -6), 6) as StatStage;

  const multipliers = {
    '-6': 0.25,
    '-5': 0.29,
    '-4': 0.33,
    '-3': 0.4,
    '-2': 0.5,
    '-1': 0.67,
    '0': 1.0,
    '1': 1.5,
    '2': 2.0,
    '3': 2.5,
    '4': 3.0,
    '5': 3.5,
    '6': 4.0,
  };
  return multipliers[boundedStage.toString() as keyof typeof multipliers];
};

/**
 * Calculates the effective speed of a Pokemon including stat stages
 * @param pokemon The Pokemon whose speed to calculate
 * @returns The effective speed (minimum 1)
 */
const getEffectiveSpeed = (pokemon: Pokemon): number => {
  if (!pokemon) {
    console.error('Invalid Pokemon provided to getEffectiveSpeed');
    return 1;
  }
  const speedMultiplier = getStatMultiplier(pokemon.speedStage);
  return Math.max(1, Math.floor(pokemon.speed * speedMultiplier));
};

/**
 * Creates a Pokemon with neutral stat stages
 * @param pokemon The base Pokemon data
 * @returns A Pokemon with initialized stat stages
 */
const initializePokemon = (
  pokemon: Omit<Pokemon, 'attackStage' | 'defenseStage' | 'speedStage'>,
): Pokemon => {
  return {
    ...pokemon,
    attackStage: 0,
    defenseStage: 0,
    speedStage: 0,
  };
};

const calculateDamage = (move: Move, attacker: Pokemon, defender: Pokemon): number => {
  const hasStab = attacker.type.includes(move.type);
  const stabMultiplier = hasStab ? 1.5 : 1;
  const effectiveness = calculateTypeEffectiveness(move.type, defender.type);

  const attackMultiplier = getStatMultiplier(attacker.attackStage);
  const defenseMultiplier = getStatMultiplier(defender.defenseStage);

  let damage: number;
  if (move.category === 'Physical') {
    const modifiedAttack = attacker.attack * attackMultiplier;
    const modifiedDefense = defender.defense * defenseMultiplier;
    damage =
      (((2 * attacker.level) / 5 + 2) * move.power * modifiedAttack) / modifiedDefense / 50 + 2;
  } else if (move.category === 'Special') {
    damage =
      (((2 * attacker.level) / 5 + 2) * move.power * attacker.specialAttack) /
        defender.specialDefense /
        50 +
      2;
  } else {
    return 0;
  }

  damage = Math.floor(damage * stabMultiplier * effectiveness);
  const randomFactor = Math.random() * 0.15 + 0.85;
  damage = Math.floor(damage * randomFactor);

  return Math.max(1, damage);
};

const Pokemon: React.FC<{ onGameEnd: (success: boolean) => void }> = ({
  onGameEnd,
}): JSX.Element => {
  const [playerTeam, setPlayerTeam] = useState<Pokemon[]>([
    {
      name: 'Feraligatr',
      maxHp: 226,
      currentHp: 226,
      level: 80,
      type: ['Water'],
      attack: 253,
      defense: 245,
      specialAttack: 211,
      specialDefense: 218,
      speed: 210,
      attackStage: 0,
      defenseStage: 0,
      speedStage: 0,
      moves: [
        {
          name: 'Waterfall',
          type: 'Water',
          category: 'Physical',
          power: 80,
          accuracy: 100,
          pp: 10,
          maxPp: 10,
          effect: '20% chance to make the target flinch.',
        },
        {
          name: 'Ice Fang',
          type: 'Ice',
          category: 'Physical',
          power: 65,
          accuracy: 95,
          pp: 15,
          maxPp: 15,
          effect: '10% chance to freeze the target; 10% chance to make the target flinch.',
        },
        {
          name: 'Crunch',
          type: 'Dark',
          category: 'Physical',
          power: 80,
          accuracy: 100,
          pp: 15,
          maxPp: 15,
          effect: "20% chance to lower the target's Defense by one stage.",
        },
        {
          name: 'Dragon Dance',
          type: 'Dragon',
          category: 'Status',
          power: 0,
          accuracy: 100,
          pp: 20,
          maxPp: 20,
          effect: "Raises the user's Attack and Speed by one stage each.",
        },
      ],
    },
    {
      name: 'Ho-Oh',
      maxHp: 253,
      currentHp: 253,
      level: 78,
      type: ['Fire', 'Flying'],
      attack: 286,
      defense: 223,
      specialAttack: 255,
      specialDefense: 323,
      speed: 223,
      attackStage: 0,
      defenseStage: 0,
      speedStage: 0,
      moves: [
        {
          name: 'Sacred Fire',
          type: 'Fire',
          category: 'Physical',
          power: 100,
          accuracy: 95,
          pp: 5,
          maxPp: 5,
          effect: '50% chance to burn the target.',
        },
        {
          name: 'Brave Bird',
          type: 'Flying',
          category: 'Physical',
          power: 120,
          accuracy: 100,
          pp: 15,
          maxPp: 15,
          effect: 'User receives recoil damage equal to 1/3 of the damage dealt.',
        },
        {
          name: 'Earthquake',
          type: 'Ground',
          category: 'Physical',
          power: 100,
          accuracy: 100,
          pp: 5,
          maxPp: 5,
          effect: 'No additional effect.',
        },
        {
          name: 'Recover',
          type: 'Normal',
          category: 'Status',
          power: 0,
          accuracy: 100,
          pp: 5,
          maxPp: 5,
          effect: "Restores the user's HP by 50% of its max HP.",
        },
      ],
    },
    {
      name: 'Mamoswine',
      maxHp: 250,
      currentHp: 250,
      level: 75,
      type: ['Ice', 'Ground'],
      attack: 275,
      defense: 200,
      specialAttack: 185,
      specialDefense: 170,
      speed: 200,
      attackStage: 0,
      defenseStage: 0,
      speedStage: 0,
      moves: [
        {
          name: 'Earthquake',
          type: 'Ground',
          category: 'Physical',
          power: 100,
          accuracy: 100,
          pp: 5,
          maxPp: 5,
          effect: 'No additional effect.',
        },
        {
          name: 'Avalanche',
          type: 'Ice',
          category: 'Physical',
          power: 60,
          accuracy: 100,
          pp: 10,
          maxPp: 10,
          effect: 'Power doubles if the user is hit by the target before attacking.',
        },
        {
          name: 'Rock Slide',
          type: 'Rock',
          category: 'Physical',
          power: 75,
          accuracy: 90,
          pp: 10,
          maxPp: 10,
          effect: '30% chance to make the target flinch.',
        },
        {
          name: 'Ice Shard',
          type: 'Ice',
          category: 'Physical',
          power: 40,
          accuracy: 100,
          pp: 30,
          maxPp: 30,
          effect: 'Priority move (+1); attacks first.',
        },
      ],
    },
    {
      name: 'Ampharos',
      maxHp: 220,
      currentHp: 220,
      level: 75,
      type: ['Electric'],
      attack: 192,
      defense: 208,
      specialAttack: 252,
      specialDefense: 215,
      speed: 162,
      attackStage: 0,
      defenseStage: 0,
      speedStage: 0,
      moves: [
        {
          name: 'Thunderbolt',
          type: 'Electric',
          category: 'Special',
          power: 90,
          accuracy: 100,
          pp: 15,
          maxPp: 15,
          effect: '10% chance to paralyze the target.',
        },
        {
          name: 'Signal Beam',
          type: 'Bug',
          category: 'Special',
          power: 75,
          accuracy: 100,
          pp: 15,
          maxPp: 15,
          effect: '10% chance to confuse the target.',
        },
        {
          name: 'Focus Blast',
          type: 'Fighting',
          category: 'Special',
          power: 120,
          accuracy: 70,
          pp: 5,
          maxPp: 5,
          effect: "10% chance to lower the target's Special Defense by one stage.",
        },
        {
          name: 'Discharge',
          type: 'Electric',
          category: 'Special',
          power: 80,
          accuracy: 100,
          pp: 15,
          maxPp: 15,
          effect: '30% chance to paralyze the target.',
        },
      ],
    },
    {
      name: 'Alakazam',
      maxHp: 170,
      currentHp: 170,
      level: 76,
      type: ['Psychic'],
      attack: 157,
      defense: 149,
      specialAttack: 286,
      specialDefense: 225,
      speed: 263,
      attackStage: 0,
      defenseStage: 0,
      speedStage: 0,
      moves: [
        {
          name: 'Psychic',
          type: 'Psychic',
          category: 'Special',
          power: 90,
          accuracy: 100,
          pp: 10,
          maxPp: 10,
          effect: "10% chance to lower the target's Special Defense by one stage.",
        },
        {
          name: 'Shadow Ball',
          type: 'Ghost',
          category: 'Special',
          power: 80,
          accuracy: 100,
          pp: 15,
          maxPp: 15,
          effect: "20% chance to lower the target's Special Defense by one stage.",
        },
        {
          name: 'Focus Blast',
          type: 'Fighting',
          category: 'Special',
          power: 120,
          accuracy: 70,
          pp: 5,
          maxPp: 5,
          effect: "10% chance to lower the target's Special Defense by one stage.",
        },
        {
          name: 'Energy Ball',
          type: 'Grass',
          category: 'Special',
          power: 80,
          accuracy: 100,
          pp: 10,
          maxPp: 10,
          effect: "10% chance to lower the target's Special Defense by one stage.",
        },
      ],
    },
    {
      name: 'Machamp',
      maxHp: 220,
      currentHp: 220,
      level: 74,
      type: ['Fighting'],
      attack: 275,
      defense: 200,
      specialAttack: 180,
      specialDefense: 208,
      speed: 162,
      attackStage: 0,
      defenseStage: 0,
      speedStage: 0,
      moves: [
        {
          name: 'Dynamic Punch',
          type: 'Fighting',
          category: 'Physical',
          power: 100,
          accuracy: 50,
          pp: 5,
          maxPp: 5,
          effect: 'Confuses the target.',
        },
        {
          name: 'Stone Edge',
          type: 'Rock',
          category: 'Physical',
          power: 100,
          accuracy: 80,
          pp: 5,
          maxPp: 5,
          effect: 'High critical-hit ratio.',
        },
        {
          name: 'Payback',
          type: 'Dark',
          category: 'Physical',
          power: 50,
          accuracy: 100,
          pp: 10,
          maxPp: 10,
          effect: 'Power doubles if the user moves after the target.',
        },
        {
          name: 'Bulk Up',
          type: 'Fighting',
          category: 'Status',
          power: 0,
          accuracy: 100,
          pp: 20,
          maxPp: 20,
          effect: "Raises the user's Attack and Defense by one stage each.",
        },
      ],
    },
  ]);

  const [playerPokemon, setPlayerPokemon] = useState<Pokemon>(playerTeam[0]);

  const [enemyTeam, setEnemyTeam] = useState<Pokemon[]>([
    {
      name: 'Pikachu',
      maxHp: 200,
      currentHp: 200,
      level: 88,
      type: ['Electric'],
      attack: 238,
      defense: 204,
      specialAttack: 226,
      specialDefense: 226,
      speed: 314,
      attackStage: 0,
      defenseStage: 0,
      speedStage: 0,
      moves: [
        {
          name: 'Volt Tackle',
          type: 'Electric',
          category: 'Physical',
          power: 120,
          accuracy: 100,
          pp: 15,
          maxPp: 15,
          effect: 'User receives recoil damage equal to 1/3 of the damage dealt.',
        },
        {
          name: 'Iron Tail',
          type: 'Steel',
          category: 'Physical',
          power: 100,
          accuracy: 75,
          pp: 15,
          maxPp: 15,
          effect: "30% chance to lower the target's Defense by one stage.",
        },
        {
          name: 'Quick Attack',
          type: 'Normal',
          category: 'Physical',
          power: 40,
          accuracy: 100,
          pp: 30,
          maxPp: 30,
          effect: 'Priority move; allows the user to attack first.',
        },
        {
          name: 'Thunderbolt',
          type: 'Electric',
          category: 'Special',
          power: 90,
          accuracy: 100,
          pp: 15,
          maxPp: 15,
          effect: '10% chance to paralyze the target.',
        },
      ],
    },
    {
      name: 'Lapras',
      maxHp: 298,
      currentHp: 298,
      level: 80,
      type: ['Water', 'Ice'],
      attack: 221,
      defense: 213,
      specialAttack: 221,
      specialDefense: 237,
      speed: 181,
      attackStage: 0,
      defenseStage: 0,
      speedStage: 0,
      moves: [
        {
          name: 'Blizzard',
          type: 'Ice',
          category: 'Special',
          power: 120,
          accuracy: 70,
          pp: 5,
          maxPp: 5,
          effect: '10% chance to freeze the target.',
        },
        {
          name: 'Brine',
          type: 'Water',
          category: 'Special',
          power: 65,
          accuracy: 100,
          pp: 10,
          maxPp: 10,
          effect: "Power doubles to 130 if the target's HP is less than 50%.",
        },
        {
          name: 'Psychic',
          type: 'Psychic',
          category: 'Special',
          power: 90,
          accuracy: 100,
          pp: 10,
          maxPp: 10,
          effect: "10% chance to lower the target's Special Defense by one stage.",
        },
        {
          name: 'Body Slam',
          type: 'Normal',
          category: 'Physical',
          power: 85,
          accuracy: 100,
          pp: 15,
          maxPp: 15,
          effect: '30% chance to paralyze the target.',
        },
      ],
    },
    {
      name: 'Snorlax',
      maxHp: 354,
      currentHp: 354,
      level: 82,
      type: ['Normal'],
      attack: 267,
      defense: 194,
      specialAttack: 194,
      specialDefense: 267,
      speed: 136,
      attackStage: 0,
      defenseStage: 0,
      speedStage: 0,
      moves: [
        {
          name: 'Blizzard',
          type: 'Ice',
          category: 'Special',
          power: 120,
          accuracy: 70,
          pp: 5,
          maxPp: 5,
          effect: '10% chance to freeze the target.',
        },
        {
          name: 'Shadow Ball',
          type: 'Ghost',
          category: 'Special',
          power: 80,
          accuracy: 100,
          pp: 15,
          maxPp: 15,
          effect: "20% chance to lower the target's Special Defense by one stage.",
        },
        {
          name: 'Crunch',
          type: 'Dark',
          category: 'Physical',
          power: 80,
          accuracy: 100,
          pp: 15,
          maxPp: 15,
          effect: "20% chance to lower the target's Defense by one stage.",
        },
        {
          name: 'Giga Impact',
          type: 'Normal',
          category: 'Physical',
          power: 150,
          accuracy: 90,
          pp: 5,
          maxPp: 5,
          effect: 'User must recharge on the next turn.',
        },
      ],
    },
    {
      name: 'Venusaur',
      maxHp: 228,
      currentHp: 228,
      level: 84,
      type: ['Grass', 'Poison'],
      attack: 227,
      defense: 228,
      specialAttack: 257,
      specialDefense: 257,
      speed: 223,
      attackStage: 0,
      defenseStage: 0,
      speedStage: 0,
      moves: [
        {
          name: 'Energy Ball',
          type: 'Grass',
          category: 'Special',
          power: 80,
          accuracy: 100,
          pp: 10,
          maxPp: 10,
          effect: "10% chance to lower the target's Special Defense.",
        },
        {
          name: 'Giga Drain',
          type: 'Grass',
          category: 'Special',
          power: 60,
          accuracy: 100,
          pp: 10,
          maxPp: 10,
          effect: 'User recovers HP equal to half the damage dealt.',
        },
        {
          name: 'Sludge Bomb',
          type: 'Poison',
          category: 'Special',
          power: 90,
          accuracy: 100,
          pp: 10,
          maxPp: 10,
          effect: '30% chance to poison the target.',
        },
        {
          name: 'Frenzy Plant',
          type: 'Grass',
          category: 'Special',
          power: 150,
          accuracy: 90,
          pp: 5,
          maxPp: 5,
          effect: 'User must recharge on the next turn.',
        },
      ],
    },
    {
      name: 'Charizard',
      maxHp: 225,
      currentHp: 225,
      level: 84,
      type: ['Fire', 'Flying'],
      attack: 230,
      defense: 220,
      specialAttack: 272,
      specialDefense: 232,
      speed: 257,
      attackStage: 0,
      defenseStage: 0,
      speedStage: 0,
      moves: [
        {
          name: 'Flare Blitz',
          type: 'Fire',
          category: 'Physical',
          power: 120,
          accuracy: 100,
          pp: 15,
          maxPp: 15,
          effect:
            'User receives recoil damage equal to 1/3 of the damage dealt; 10% chance to burn the target.',
        },
        {
          name: 'Air Slash',
          type: 'Flying',
          category: 'Special',
          power: 75,
          accuracy: 95,
          pp: 15,
          maxPp: 15,
          effect: '30% chance to make the target flinch.',
        },
        {
          name: 'Dragon Pulse',
          type: 'Dragon',
          category: 'Special',
          power: 90,
          accuracy: 100,
          pp: 10,
          maxPp: 10,
          effect: 'No additional effect.',
        },
        {
          name: 'Blast Burn',
          type: 'Fire',
          category: 'Special',
          power: 150,
          accuracy: 90,
          pp: 5,
          maxPp: 5,
          effect: 'User must recharge on the next turn.',
        },
      ],
    },
    {
      name: 'Blastoise',
      maxHp: 228,
      currentHp: 228,
      level: 84,
      type: ['Water'],
      attack: 225,
      defense: 257,
      specialAttack: 228,
      specialDefense: 267,
      speed: 223,
      attackStage: 0,
      defenseStage: 0,
      speedStage: 0,
      moves: [
        {
          name: 'Focus Blast',
          type: 'Normal',
          category: 'Special',
          power: 120,
          accuracy: 70,
          pp: 5,
          maxPp: 5,
          effect: '10% chance to freeze the target.',
        },
        {
          name: 'Blizzard',
          type: 'Ice',
          category: 'Special',
          power: 120,
          accuracy: 70,
          pp: 5,
          maxPp: 5,
          effect: '10% chance to freeze the target.',
        },
        {
          name: 'Flash Cannon',
          type: 'Steel',
          category: 'Special',
          power: 80,
          accuracy: 100,
          pp: 10,
          maxPp: 10,
          effect: "10% chance to lower the target's Special Defense by one stage.",
        },
        {
          name: 'Hydro Cannon',
          type: 'Water',
          category: 'Special',
          power: 150,
          accuracy: 90,
          pp: 5,
          maxPp: 5,
          effect: 'User must recharge on the next turn.',
        },
      ],
    },
  ]);

  const [currentEnemyIndex, setCurrentEnemyIndex] = useState(0);
  const [enemyPokemon, setEnemyPokemon] = useState(enemyTeam[0]);

  const [message, setMessage] = useState<string>(`What will ${playerTeam[0].name} do?`);
  const [isPlayerTurn, setIsPlayerTurn] = useState(true);
  const [gameOver, setGameOver] = useState(false);
  const [menuState, setMenuState] = useState<MenuState>('action');
  const [hoveredMove, setHoveredMove] = useState<Move | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);

  // New state for selected item
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const [items, setItems] = useState({
    'max-potion': 1,
  });

  const calculateAccuracySuccess = (accuracy: number): boolean => {
    return Math.random() * 100 <= accuracy;
  };

  /**
   * Executes a player's move and handles the aftermath
   */
  const executePlayerMove = (move: Move, callback: () => void) => {
    if (!playerPokemon || !enemyPokemon) {
      console.error('Invalid Pokemon state in executePlayerMove');
      return;
    }

    // Reduce PP by 1
    const updatedMoves = playerPokemon.moves.map((m) =>
      m.name === move.name ? { ...m, pp: m.pp - 1 } : m,
    );
    setPlayerPokemon((prev) => ({ ...prev, moves: updatedMoves }));

    // Update the move in the player's team as well
    setPlayerTeam((prev) =>
      prev.map((p) => (p.name === playerPokemon.name ? { ...p, moves: updatedMoves } : p)),
    );

    // Handle status moves
    if (move.category === 'Status') {
      if (move.name === 'Dragon Dance') {
        setPlayerPokemon((prev) => ({
          ...prev,
          attackStage: Math.min(prev.attackStage + 1, 6),
          speedStage: Math.min(prev.speedStage + 1, 6),
        }));
        setMessage(`${playerPokemon.name}'s Attack and Speed rose!`);
        setTimeout(callback, 1500);
        return;
      }

      if (move.name === 'Bulk Up') {
        setPlayerPokemon((prev) => ({
          ...prev,
          attackStage: Math.min(prev.attackStage + 1, 6),
          defenseStage: Math.min(prev.defenseStage + 1, 6),
        }));
        setMessage(`${playerPokemon.name}'s Attack and Defense rose!`);
        setTimeout(callback, 1500);
        return;
      }
    }

    const hit = calculateAccuracySuccess(move.accuracy);
    if (hit) {
      const damage = calculateDamage(move, playerPokemon, enemyPokemon);

      // Calculate new HP and create updated Pokemon
      const newHp = Math.max(0, enemyPokemon.currentHp - damage);
      const updatedEnemyPokemon = { ...enemyPokemon, currentHp: newHp };

      // Update both enemy Pokemon and enemy team
      setEnemyPokemon(updatedEnemyPokemon);
      const newEnemyTeam = [...enemyTeam];
      newEnemyTeam[currentEnemyIndex] = updatedEnemyPokemon;
      setEnemyTeam(newEnemyTeam);

      const effectiveness = calculateTypeEffectiveness(move.type, enemyPokemon.type);
      const effectivenessText = getEffectivenessText(effectiveness);
      setMessage(`${playerPokemon.name} used ${move.name}!${effectivenessText}`);

      // Handle fainting based on the calculated new HP
      if (newHp <= 0) {
        setTimeout(() => handleEnemyFaint(), 1500);
      } else {
        setTimeout(callback, 1500);
      }
    } else {
      setMessage(`${playerPokemon.name}'s attack missed!`);
      setTimeout(callback, 1500);
    }
  };

  /**
   * Handles the enemy's turn
   */
  const executeEnemyMove = (targetPokemon: Pokemon, move: Move, callback: () => void) => {
    if (!targetPokemon || !enemyPokemon) {
      console.error('Invalid Pokemon state in executeEnemyMove');
      return;
    }

    // Reduce PP by 1
    const updatedMoves = enemyPokemon.moves.map((m) =>
      m.name === move.name ? { ...m, pp: m.pp - 1 } : m,
    );
    setEnemyPokemon((prev) => ({ ...prev, moves: updatedMoves }));

    // Update the move in the enemy's team as well
    setEnemyTeam((prev) =>
      prev.map((p) => (p.name === enemyPokemon.name ? { ...p, moves: updatedMoves } : p)),
    );

    // Handle status moves
    if (move.category === 'Status') {
      if (move.name === 'Dragon Dance') {
        setEnemyPokemon((prev) => ({
          ...prev,
          attackStage: Math.min(prev.attackStage + 1, 6),
          speedStage: Math.min(prev.speedStage + 1, 6),
        }));
        setMessage(`Enemy ${enemyPokemon.name}'s Attack and Speed rose!`);
        setTimeout(callback, 1500);
        return;
      }

      if (move.name === 'Bulk Up') {
        setEnemyPokemon((prev) => ({
          ...prev,
          attackStage: Math.min(prev.attackStage + 1, 6),
          defenseStage: Math.min(prev.defenseStage + 1, 6),
        }));
        setMessage(`Enemy ${enemyPokemon.name}'s Attack and Defense rose!`);
        setTimeout(callback, 1500);
        return;
      }
    }

    const hit = calculateAccuracySuccess(move.accuracy);
    if (hit) {
      const damage = calculateDamage(move, enemyPokemon, targetPokemon);
      const newHp = Math.max(0, targetPokemon.currentHp - damage);
      const updatedPlayerPokemon = { ...targetPokemon, currentHp: newHp };

      // Update both player Pokemon and player team
      setPlayerPokemon(updatedPlayerPokemon);
      const newPlayerTeam = [...playerTeam];
      const currentIndex = newPlayerTeam.findIndex((p) => p.name === updatedPlayerPokemon.name);
      if (currentIndex !== -1) {
        newPlayerTeam[currentIndex] = updatedPlayerPokemon;
        setPlayerTeam(newPlayerTeam);
      }

      const effectiveness = calculateTypeEffectiveness(move.type, targetPokemon.type);
      const effectivenessText = getEffectivenessText(effectiveness);
      setMessage(`${effectivenessText}`);

      // Modified this section to ensure state updates are processed
      setTimeout(() => {
        if (newHp <= 0) {
          handlePlayerFaint();
        } else {
          callback();
        }
      }, 1500);
    } else {
      setMessage(`Enemy ${enemyPokemon.name}'s attack missed!`);
      setTimeout(callback, 1500);
    }
  };

  /**
   * Handles when the enemy Pokemon faints
   */
  const handleEnemyFaint = async () => {
    setIsAnimating(true);
    setMessage(`Enemy ${enemyPokemon.name} fainted!`);

    // Check if this was Red's last Pokemon
    const remainingEnemyPokemon = enemyTeam.filter(
      (p, i) => i > currentEnemyIndex && p.currentHp > 0,
    );

    if (remainingEnemyPokemon.length === 0) {
      // No more enemy Pokémon - Player wins!
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setMessage('Red was defeated!');
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setGameOver(true);
      onGameEnd(true); // Signal victory
      setIsAnimating(false);
      return;
    }

    // Continue with existing logic for next Pokemon
    const nextIndex = currentEnemyIndex + 1;
    await new Promise((resolve) => setTimeout(resolve, 1500));
    setMessage(`Red is about to send out ${enemyTeam[nextIndex].name}!`);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    const nextPokemon = initializePokemon(enemyTeam[nextIndex]);
    setEnemyPokemon(nextPokemon);
    setCurrentEnemyIndex(nextIndex);
    setMessage(`Red sent out ${nextPokemon.name}!`);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    setIsAnimating(false);
    setIsPlayerTurn(true);
    setMenuState('action');
    setMessage(`What will ${playerPokemon.name} do?`);
  };

  /**
   * Handles when the player's Pokemon faints
   */
  const handlePlayerFaint = async () => {
    setIsAnimating(true);
    setMessage(`${playerPokemon.name} fainted!`);

    // Check if this was the player's last Pokemon
    const remainingPlayerPokemon = playerTeam.filter((p) => p.currentHp > 0);

    if (remainingPlayerPokemon.length === 0) {
      // No more usable Pokemon - Player loses
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setMessage('You have no usable Pokémon!');
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setGameOver(true);
      onGameEnd(false); // Signal defeat
      setIsAnimating(false);
      return;
    }

    // Continue with existing logic for Pokemon selection
    await new Promise((resolve) => setTimeout(resolve, 1500));
    setMessage(`Choose your next Pokémon!`);
    setMenuState('pokemon');
    setIsAnimating(false);
  };

  /**
   * Handles switching Pokemon, either voluntarily or due to fainting
   */
  const switchPokemon = (index: number) => {
    if (isAnimating) return;

    if (playerTeam[index].currentHp <= 0) {
      setMessage(`${playerTeam[index].name} has no energy left to battle!`);
      return;
    }

    setIsAnimating(true);
    setMenuState('message');

    // Check if this is a reactive switch (after faint) or proactive switch
    const isReactiveSwitch = playerPokemon.currentHp <= 0;

    if (isReactiveSwitch) {
      setMessage(`Let's go, ${playerTeam[index].name}!`);
    } else {
      setMessage(`Come back, ${playerPokemon.name}! Let's go, ${playerTeam[index].name}!`);
    }

    // Create a new team array and swap the Pokémon
    const newPlayerTeam = [...playerTeam];
    [newPlayerTeam[0], newPlayerTeam[index]] = [newPlayerTeam[index], newPlayerTeam[0]];
    const switchedPokemon = newPlayerTeam[0];

    setTimeout(() => {
      // Update both the team and the active Pokémon
      setPlayerTeam(newPlayerTeam);
      setPlayerPokemon(switchedPokemon);

      if (isReactiveSwitch) {
        setTimeout(() => {
          setIsAnimating(false);
          setIsPlayerTurn(true);
          setMenuState('action');
          setMessage(`What will ${switchedPokemon.name} do?`);
        }, 1500);
      } else {
        // For proactive switching, handle the enemy's turn with proper message sequencing
        setTimeout(() => {
          if (enemyPokemon.currentHp > 0) {
            handleEnemyTurn(switchedPokemon, () => {
              if (switchedPokemon.currentHp > 0) {
                proceedToNextTurn();
              }
            });
          }
        }, 1500);
      }
    }, 1500);
  };

  const proceedToNextTurn = () => {
    setIsPlayerTurn(true);
    setMenuState('action');
    setIsAnimating(false);
  };

  const handleMove = (move: Move) => {
    if (!isPlayerTurn || gameOver || isAnimating) return;

    setIsAnimating(true);
    setMenuState('message');

    const playerSpeed = getEffectiveSpeed(playerPokemon);
    const enemySpeed = getEffectiveSpeed(enemyPokemon);

    if (playerSpeed >= enemySpeed) {
      executePlayerMove(move, () => {
        if (enemyPokemon.currentHp > 0) {
          handleEnemyTurn(playerPokemon, () => {
            if (playerPokemon.currentHp > 0) {
              proceedToNextTurn();
            }
          });
        }
      });
    } else {
      handleEnemyTurn(playerPokemon, () => {
        if (playerPokemon.currentHp > 0) {
          executePlayerMove(move, () => {
            if (enemyPokemon.currentHp > 0) {
              proceedToNextTurn();
            }
          });
        }
      });
    }
  };

  const handleActionSelect = (action: string) => {
    if (action === 'FIGHT') {
      setMenuState('moves');
    } else if (action === 'PKM') {
      setMenuState('pokemon');
    } else if (action === 'PACK') {
      setMenuState('pack');
    } else if (action === 'RUN') {
      setMessage('You are not allowed to run from a trainer battle, coward!');
      setMenuState('message');
      setTimeout(() => setMenuState('action'), 1500);
    }
  };

  const handleMoveSelect = (move: Move) => {
    if (move.pp <= 0) {
      setMessage('No PP left for this move!');
      setMenuState('message');
      setTimeout(() => setMenuState('moves'), 1500);
      return;
    }
    handleMove(move);
    setMenuState('message');
  };

  /**
   * Handles using an item from the pack
   * @param item The item to use ('max-potion')
   */
  const handleUseItem = (item: string) => {
    if (items[item as keyof typeof items] <= 0) {
      setMessage(`No ${item.replace('-', ' ')}s remaining!`);
      setMenuState('message');
      setTimeout(() => setMenuState('pack'), 1500);
      return;
    }

    if (item === 'max-potion') {
      setSelectedItem('max-potion');
      setMenuState('use-item');
    }
  };

  /**
   * Handles selecting a Pokémon to apply the item
   */
  const handleSelectPokemonForItem = (pokemon: Pokemon) => {
    if (!selectedItem) return;

    if (selectedItem === 'max-potion') {
      applyMaxPotion(pokemon);
    }

    setSelectedItem(null);
    setMenuState('message');
  };

  /**
   * Applies Max Potion to the selected Pokémon
   */
  const applyMaxPotion = (pokemon: Pokemon) => {
    // Deduct one Max Potion
    setItems((prev) => ({
      ...prev,
      'max-potion': prev['max-potion'] - 1,
    }));

    const updatedPokemon = { ...pokemon, currentHp: pokemon.maxHp };

    // Update both the team and active Pokemon
    updatePlayerTeamPokemon(updatedPokemon);

    setMessage(`${pokemon.name} was fully restored using Max Potion!`);

    // Wait for state updates to complete before enemy turn
    setTimeout(() => {
      if (enemyPokemon.currentHp > 0) {
        handleEnemyTurn(pokemon, () => {
          if (pokemon.currentHp > 0) {
            proceedToNextTurn();
          }
        });
      }
    }, 1500);
  };

  /**
   * Updates the Pokémon in the player's team
   */
  const updatePlayerTeamPokemon = (updatedPokemon: Pokemon) => {
    const newPlayerTeam = playerTeam.map((p) =>
      p.name === updatedPokemon.name ? updatedPokemon : p,
    );
    setPlayerTeam(newPlayerTeam);

    // If the updated Pokémon is the active one, update playerPokemon
    if (playerPokemon.name === updatedPokemon.name) {
      setPlayerPokemon(updatedPokemon);
    }
  };

  /**
   * Renders Pokémon options for using items
   */
  const renderUseItemContent = () => {
    // All Pokémon can use Max Potion
    const applicablePokemon = playerTeam;

    if (applicablePokemon.length === 0) {
      return (
        <>
          <MenuBox>No applicable Pokémon to use this item on.</MenuBox>
          <ActionButton onClick={() => setMenuState('pack')}>CANCEL</ActionButton>
        </>
      );
    }

    return (
      <>
        <MenuBox>Select a Pokémon to use the item on:</MenuBox>
        <PokemonMenuContainer>
          {applicablePokemon.map((pokemon, index) => (
            <PokemonMenuOption
              key={index}
              pokemon={pokemon}
              isSelected={pokemon.name === playerPokemon.name}
              onClick={() => handleSelectPokemonForItem(pokemon)}
              allowFainted={false}
            />
          ))}
          <ActionButton onClick={() => setMenuState('pack')}>CANCEL</ActionButton>
        </PokemonMenuContainer>
      </>
    );
  };

  /**
   * Renders the menu based on the current state
   */
  const renderMenuContent = () => {
    switch (menuState) {
      case 'action':
        return (
          <>
            <MenuBox>What will {playerPokemon.name} do?</MenuBox>
            <MenuBox>
              <ActionOptions>
                <ActionButton onClick={() => handleActionSelect('FIGHT')}>FIGHT</ActionButton>
                <ActionButton onClick={() => handleActionSelect('PKM')}>PKM</ActionButton>
                <ActionButton onClick={() => handleActionSelect('PACK')}>PACK</ActionButton>
                <ActionButton onClick={() => handleActionSelect('RUN')}>RUN</ActionButton>
              </ActionOptions>
            </MenuBox>
          </>
        );
      case 'moves':
        return (
          <>
            <MenuBox>
              <ActionOptions>
                {playerPokemon.moves.map((move, index) => (
                  <ActionButton
                    key={index}
                    onClick={() => handleMoveSelect(move)}
                    onMouseEnter={() => setHoveredMove(move)}
                    onMouseLeave={() => setHoveredMove(null)}
                  >
                    {move.name}
                  </ActionButton>
                ))}
              </ActionOptions>
            </MenuBox>
            <MenuBox
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
              {hoveredMove && (
                <MoveInfo>
                  <div>
                    PP {hoveredMove.pp}/{hoveredMove.maxPp}
                  </div>
                  <div>TYPE/{hoveredMove.type}</div>
                </MoveInfo>
              )}
              <ActionButton onClick={() => setMenuState('action')} style={{ marginTop: 'auto' }}>
                CANCEL
              </ActionButton>
            </MenuBox>
          </>
        );
      case 'pokemon':
        return (
          <PokemonMenuContainer>
            {playerTeam.map((pokemon, index) => (
              <PokemonMenuOption
                key={index}
                pokemon={pokemon}
                isSelected={pokemon.name === playerPokemon.name}
                onClick={() => {
                  if (isAnimating) return;
                  if (pokemon.currentHp <= 0) {
                    setMessage(`${pokemon.name} has no energy left to battle!`);
                    return;
                  }
                  if (pokemon.name !== playerPokemon.name) {
                    switchPokemon(index);
                  }
                }}
                allowFainted={false} // Do not allow selecting fainted Pokémon for battle
              />
            ))}
            {playerPokemon.currentHp > 0 && (
              <ActionButton onClick={() => setMenuState('action')} style={{ marginTop: '8px' }}>
                CANCEL
              </ActionButton>
            )}
          </PokemonMenuContainer>
        );
      case 'pack':
        return (
          <>
            <PackMenuContainer>
              {items['max-potion'] > 0 && (
                <PackMenuItem onClick={() => handleUseItem('max-potion')}>
                  <ItemInfo>
                    <PackItemSprite src={`/assets/pokemon/max-potion.png`} alt="Max Potion" />
                    <span>Max Potion</span>
                  </ItemInfo>
                  <span>×{items['max-potion']}</span>
                </PackMenuItem>
              )}
              {items['max-potion'] === 0 && (
                <div style={{ textAlign: 'center', padding: '8px' }}>No items remaining</div>
              )}
              <ActionButton onClick={() => setMenuState('action')}>CANCEL</ActionButton>
            </PackMenuContainer>
          </>
        );
      case 'use-item':
        return renderUseItemContent();
      case 'message':
        return (
          <>
            <MenuBox
              style={{
                gridColumn: '1 / -1',
                cursor: isAnimating ? 'not-allowed' : 'pointer',
                pointerEvents: isAnimating ? 'none' : 'auto',
              }}
              onClick={() => !isAnimating && setMenuState('action')}
            >
              {message}
            </MenuBox>
          </>
        );
      default:
        return (
          <>
            <MenuBox style={{ gridColumn: '1 / -1' }}>{message}</MenuBox>
          </>
        );
    }
  };

  const performEnemySwitch = (switchIndex: number, callback: () => void) => {
    setIsAnimating(true);
    const oldPokemon = enemyPokemon;
    const newEnemyTeam = [...enemyTeam];
    const newEnemyPokemon = newEnemyTeam[switchIndex];

    setMessage(`Red withdrew ${oldPokemon.name}!`);
    setTimeout(() => {
      setMessage(`Red sent out ${newEnemyPokemon.name}!`);
      setEnemyPokemon(newEnemyPokemon);
      setCurrentEnemyIndex(switchIndex);
      setTimeout(() => {
        setIsAnimating(false);
        callback();
      }, 1500);
    }, 1500);
  };

  const handleEnemyTurn = (targetPokemon: Pokemon, callback: () => void) => {
    const action = decideEnemyAction(enemyTeam, currentEnemyIndex, enemyPokemon, targetPokemon);

    if (action.action === 'switch' && typeof action.switchIndex === 'number') {
      performEnemySwitch(action.switchIndex, () => {
        proceedToNextTurn();
      });
    } else if (action.action === 'move' && action.move) {
      const enemyMove = action.move;
      setMessage(`Enemy ${enemyPokemon.name} used ${enemyMove.name}!`);
      setTimeout(() => {
        executeEnemyMove(targetPokemon, enemyMove, callback);
      }, 1500);
    }
  };

  return (
    <BattleContainer>
      <BattleScene>
        <PokemonSprite
          src={`/assets/pokemon/${enemyPokemon.name.toLowerCase()}-front.png`}
          alt={`Enemy ${enemyPokemon.name}`}
          isEnemy
        />

        <PokemonSprite
          src={`/assets/pokemon/${playerPokemon.name.toLowerCase()}-back.png`}
          alt={`Your ${playerPokemon.name}`}
        />

        <PokemonStats isEnemy pokemon={enemyPokemon} />
        <PokemonStats pokemon={playerPokemon} />

        <BattleMenu>{renderMenuContent()}</BattleMenu>
      </BattleScene>
    </BattleContainer>
  );
};

export default Pokemon;

const FaintedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: #ff0000;
    width: 100%;
    height: 2px;
    transform-origin: center;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const PokemonSpriteWrapper = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
`;

// New styled components for Pack Menu
const PackMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PackMenuItem = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: 2px solid #333;
  border-radius: 8px;
  padding: 8px;
  font-family: inherit;
  font-size: clamp(0.8rem, 1.6vw, 1rem);
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  width: 100%;
  justify-content: space-between;

  &:hover {
    background-color: ${(props) => (props.disabled ? 'transparent' : '#eee')};
  }
`;

const PackItemSprite = styled.img`
  width: 32px;
  height: 32px;
  image-rendering: pixelated;
`;

const ItemInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
