import React from 'react';
import DescriptionCard from '../DescriptionCard';

const GandalfDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => (
  <DescriptionCard
    title="Gandalf The White"
    description={`**How to play:** Welcome back, old friend. Your challenge is to uncover the secret password that Gandalf protects. However, since your last encounter, Gandalf has faced off against the fearsome Balrog and has emerged as the enigmatic Gandalf the White. His transformation signifies purification and a renewal of purpose. No longer merely a guide, he is now a wizard of unparalleled power against the forces of darkness. He will be much less easily persuaded this time, with defences nearly impenetrable and wisdom that transcends the bounds of this world. #YouShallNotPass

⚠️  This is not a timed challenge. Refreshing, leaving and re-entering the page may result in "progress" lost. 

**Win/Lose Conditions**: Yesterday, you embarked on a challenge of choice and consequence. Today, one of those consequences comes into play. Your ability to earn immunity will not rest solely on you today, but will also be tied to the fate of another player. Both you **and** the player you selected to answer Question 2 of *Red Pill, Blue Pill* need to succeed in this challenge, in order for **you** to achieve immunity. Note: their fate is not necessarily tied to yours. If either of you do not obtain Gandalf's secret password, **you** will face elimination from the Wheel tonight.  

To remind you of yesterday's Question 2: <span style="color: #0000ff">If your immunity depended on another player's success, who would you trust to tie it to?</span> / <span style="color: #fd4545">If your immunity depended on another player's success, who would you absolutely **not** want to tie it to?</span>

**Estimated time required**: >2 minutes. 

**Fun-rating:** 10/10`}
    onStart={onStart}
  />
);

export default GandalfDescription;
