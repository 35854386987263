const getApiBaseUrl = () => {
  // For Docker local development or direct local development
  // Home IP is 192.168.1.7
  // Hotspot IP is 172.20.10.4
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_API_URL === 'http://192.168.1.6:8000'
  ) {
    return 'http://192.168.1.6:8000';
  }

  // For production (using GitHub Actions secrets)
  const apiUrl = process.env.REACT_APP_API_URL;
  if (!apiUrl) {
    throw new Error('REACT_APP_API_URL environment variable is not set in production');
  }
  return apiUrl;
};

export const config = {
  apiBaseUrl: getApiBaseUrl(),
  openAiKey: process.env.REACT_APP_OPENAI_API_KEY,
};
