import React from 'react';
import DescriptionCard from '../DescriptionCard'; // Import from components

const ChooseABoxDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <DescriptionCard
      title="Mystery Box"
      description={`**How to Play:** You will be shown three boxes. Inside one of the boxes is immunity. Inside the other two boxes is elimination. Try your luck and click on a box! 

⚠️  You have unlimited time but you have only one guess.

**Win/Lose Conditions**: Correctly guess the box with immunity to pass this challenge. Failing to select the box with immunity inside will result in you being up for elimination. 

**Estimated time required**: 10 seconds.

**Fun-rating:** 10/10`}
      onStart={onStart}
    />
  );
};

export default ChooseABoxDescription;
