import React from 'react';
import DescriptionCard from '../DescriptionCard';

const QuickfireQuizDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <DescriptionCard
      title="Quickfire Quiz"
      description={`**How to Play:** You start with 60 seconds on the clock. Answer as many questions as you can before your time runs out. For every correct answer, you earn 1 point and gain an extra 5 seconds on the clock. Incorrect answers do not affect your score or time. Rack up as many points as possible before the clock expires. To submit an answer, press the enter key. You can skip a question by pressing enter.

⚠️  This is a timed challenge. Once you hit 'start', the clock will be ticking. You cannot pause the game and there are no retries. If you leave the page, the timer will continue counting down.

**Win/Lose Conditions**: To earn immunity, you need to score ≥ 70% of the highest score recorded. For example, if the highest score recorded is 10 points, and you scored 7+ points, you will earn immunity. You won't know if you've earned immunity until the quiz closes at 9PM (AEDT). That's right, nervous energy all day.

**Estimated time required**: 5 minutes

**Fun-rating:** 10/10`}
      onStart={onStart}
    />
  );
};

export default QuickfireQuizDescription;
