import React from 'react';
import DescriptionCard from '../DescriptionCard';

const LetterPileDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <DescriptionCard
      title="Letter Pile"
      description={`**How to Play:** Your challenge is to find **one unused letter** from a pile of letters. You will be given a random category. Your task is to sort the letters into words that fit the category, using **all but** **one** letter. The leftover letter will be your key to immunity.

⚠️  This is a timed challenge. Once you hit 'start', the clock will be ticking. You cannot pause the game and there are no retries. If you leave the page, the timer keeps goes and your progress will not be saved.

💡 **Pro tip:** Play on a desktop for the best user experience. Type your words into the provided word boxes. If a letter doesn't type, it means that letter isn't available in the pile.

**Win/Lose Conditions**: Submit the correct **final left over letter** to complete the challenge. If you submit the wrong letter, you will be facing for elimination. Dun, dun, dunnnn!

**Estimated time required**: 10 minutes

**Fun-rating:** 10/10`}
      onStart={onStart}
    />
  );
};

export default LetterPileDescription;
