import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { completeSecretNumber } from '../api';

interface Player {
  id: number;
  name: string;
  image: string;
  secretNumber: number;
  guessedNumber?: number;
}

interface Calculation {
  player1: number;
  player2: number;
  operation: 'add' | 'multiply' | 'divide';
  result: number;
}

interface CardFaceProps {
  isSelected?: boolean;
}

// Add a shuffle function (Fisher-Yates algorithm)
function shuffleArray<T>(array: T[]): T[] {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

// Original players array becomes the source data
const originalPlayers: Player[] = [
  { id: 4, name: 'Player 4', image: '/assets/user_images/user-4.png', secretNumber: 73 },
  { id: 5, name: 'Player 5', image: '/assets/user_images/user-5.png', secretNumber: 28 },
  { id: 6, name: 'Player 6', image: '/assets/user_images/user-6.png', secretNumber: 95 },
  { id: 7, name: 'Player 7', image: '/assets/user_images/user-7.png', secretNumber: 41 },
  { id: 8, name: 'Player 8', image: '/assets/user_images/user-8.png', secretNumber: 16 },
  { id: 9, name: 'Player 9', image: '/assets/user_images/user-9.png', secretNumber: 62 },
  { id: 10, name: 'Player 10', image: '/assets/user_images/user-10.png', secretNumber: 84 },
  { id: 11, name: 'Player 11', image: '/assets/user_images/user-11.png', secretNumber: 37 },
  { id: 12, name: 'Player 12', image: '/assets/user_images/user-12.png', secretNumber: 53 },
  { id: 13, name: 'Player 13', image: '/assets/user_images/user-13.png', secretNumber: 19 },
  { id: 14, name: 'Player 14', image: '/assets/user_images/user-14.png', secretNumber: 91 },
  { id: 15, name: 'Player 15', image: '/assets/user_images/user-15.png', secretNumber: 45 },
  { id: 16, name: 'Player 16', image: '/assets/user_images/user-16.png', secretNumber: 67 },
  { id: 17, name: 'Player 17', image: '/assets/user_images/user-17.png', secretNumber: 33 },
  { id: 18, name: 'Player 18', image: '/assets/user_images/user-18.png', secretNumber: 88 },
  { id: 19, name: 'Player 19', image: '/assets/user_images/user-19.png', secretNumber: 12 },
  { id: 20, name: 'Player 20', image: '/assets/user_images/user-20.png', secretNumber: 59 },
  { id: 21, name: 'Player 21', image: '/assets/user_images/user-21.png', secretNumber: 25 },
  { id: 22, name: 'Player 22', image: '/assets/user_images/user-22.png', secretNumber: 76 },
  { id: 23, name: 'Player 23', image: '/assets/user_images/user-23.png', secretNumber: 42 },
  { id: 24, name: 'Player 24', image: '/assets/user_images/user-24.png', secretNumber: 94 },
  { id: 25, name: 'Player 25', image: '/assets/user_images/user-25.png', secretNumber: 31 },
  { id: 26, name: 'Player 26', image: '/assets/user_images/user-26.png', secretNumber: 87 },
  { id: 27, name: 'Player 27', image: '/assets/user_images/user-27.png', secretNumber: 15 },
  { id: 28, name: 'Player 28', image: '/assets/user_images/user-28.png', secretNumber: 63 },
  { id: 29, name: 'Player 29', image: '/assets/user_images/user-29.png', secretNumber: 49 },
  { id: 30, name: 'Player 30', image: '/assets/user_images/user-30.png', secretNumber: 82 },
  { id: 31, name: 'Player 31', image: '/assets/user_images/user-31.png', secretNumber: 23 },
  { id: 32, name: 'Player 32', image: '/assets/user_images/user-32.png', secretNumber: 71 },
  { id: 33, name: 'Player 33', image: '/assets/user_images/user-33.png', secretNumber: 39 },
  { id: 34, name: 'Player 34', image: '/assets/user_images/user-34.png', secretNumber: 96 },
  { id: 35, name: 'Player 35', image: '/assets/user_images/user-35.png', secretNumber: 17 },
  { id: 36, name: 'Player 36', image: '/assets/user_images/user-36.png', secretNumber: 55 },
  { id: 37, name: 'Player 37', image: '/assets/user_images/user-37.png', secretNumber: 83 },
  { id: 38, name: 'Player 38', image: '/assets/user_images/user-38.png', secretNumber: 29 },
  { id: 39, name: 'Player 39', image: '/assets/user_images/user-39.png', secretNumber: 68 },
  { id: 40, name: 'Player 40', image: '/assets/user_images/user-40.png', secretNumber: 44 },
  { id: 41, name: 'Player 41', image: '/assets/user_images/user-41.png', secretNumber: 92 },
  { id: 42, name: 'Player 42', image: '/assets/user_images/user-42.png', secretNumber: 35 },
  { id: 43, name: 'Player 43', image: '/assets/user_images/user-43.png', secretNumber: 57 },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: clamp(10px, 2vw, 20px);
  box-sizing: border-box;
  overflow-x: hidden;
`;

const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  gap: clamp(15px, 3vh, 25px);
  padding-bottom: clamp(20px, 4vh, 40px);

  /* Hide scrollbar but keep functionality */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(clamp(45px, 10vw, 80px), 1fr));
  gap: clamp(4px, 1vw, 8px);
  width: 100%;
  max-width: 800px;
  padding: clamp(10px, 2vw, 20px);
  box-sizing: border-box;
`;

const PlayerCard = styled.div<{ isSelected: boolean; isFlipped: boolean }>`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  perspective: 1000px;
  cursor: pointer;

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
    transform: ${(props) => (props.isFlipped ? 'rotateY(180deg)' : 'rotateY(0)')};
  }
`;

const CardFace = styled.div<CardFaceProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.isSelected ? '#66cc00' : '#ccc')};
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const CardFront = styled(CardFace)``;

const CardBack = styled(CardFace)`
  transform: rotateY(180deg);
`;

const PlayerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
`;

const GuessInput = styled.input`
  width: 60%;
  padding: clamp(4px, 1vw, 8px);
  font-size: clamp(0.7rem, 1.4vw, 0.9rem);
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #66cc00;
  }

  /* Remove up/down arrows */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const ActionPanel = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  padding: clamp(30px, 6vw, 50px);
  z-index: 1000;
  width: min(95vw, 500px);
`;

const OperationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(15px, 3vw, 30px);
  width: 100%;
  margin: 0 auto;
`;

const OperationBox = styled.button<{ color: string; disabled?: boolean }>`
  aspect-ratio: 1;
  border: none;
  border-radius: 12px;
  background-color: ${(props) => (props.disabled ? '#ccc' : props.color)};
  color: white;
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-family: 'Chakra Petch', sans-serif;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  transition: transform 0.2s, opacity 0.2s;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

  &:hover:not(:disabled) {
    transform: scale(1.05);
  }

  &:active:not(:disabled) {
    transform: scale(0.95);
  }
`;

const RemainingCount = styled.span`
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: clamp(1.1rem, 2vw, 1.4rem);
  font-weight: bold;
`;

const ResultsList = styled.div`
  width: 100%;
  max-width: 800px;
  padding: clamp(15px, 3vw, 25px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: clamp(8px, 1.5vh, 12px);
  scroll-margin-top: 20px;
`;

const ResultsTitle = styled.h2`
  font-size: clamp(1.1rem, 2.2vw, 1.3rem);
  margin: 0 0 clamp(10px, 2vh, 15px) 0;
  font-weight: 600;
  text-align: center;
`;

const ResultItem = styled.div`
  padding: clamp(10px, 2vw, 15px);
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: clamp(0.9rem, 1.8vw, 1.1rem);
`;

const SubmitButton = styled.button`
  background-color: #66cc00;
  color: white;
  padding: clamp(10px, 2vh, 15px) clamp(20px, 4vw, 30px);
  border: none;
  border-radius: 4px;
  font-size: clamp(0.9rem, 1.8vw, 1.1rem);
  cursor: pointer;
  margin: clamp(15px, 3vh, 25px) 0;
  font-weight: bold;
  transition: background-color 0.2s;
  font-family: 'Chakra Petch', sans-serif;

  &:hover {
    background-color: #5ab800;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

// Add the Description styled component
const Description = styled.p`
  text-align: center;
  margin: 0 0 clamp(0.8em, 1.5vh, 1em) 0;
  font-size: clamp(1rem, 2vw, 1.2rem);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 clamp(20px, 4vw, 40px);
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: clamp(0.95rem, 1.8vw, 1.1rem);
    margin-bottom: clamp(0.7rem, 1.2vh, 0.9rem);
    padding: 0 clamp(15px, 3vw, 30px);
    max-width: 90%;
  }

  @media (max-width: 480px) {
    font-size: clamp(0.9rem, 1.6vw, 1rem);
    margin-bottom: clamp(0.6rem, 1vh, 0.8rem);
    padding: 0 clamp(10px, 2vw, 20px);
  }
`;

const BlurOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 999;
  cursor: pointer;
`;

// Add new styled components for the selected players display
const SelectedPlayersDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: clamp(30px, 6vw, 60px);
  margin-bottom: clamp(40px, 8vh, 70px);
`;

const SelectedPlayerImage = styled.img`
  width: clamp(150px, 25vw, 250px);
  height: clamp(150px, 25vw, 250px);
  border-radius: 8px;
  border: 2px solid white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

const SecretNumber: React.FC<{ onGameEnd: (success: boolean, isPending?: boolean) => void }> = ({
  onGameEnd,
}) => {
  const [selectedPlayers, setSelectedPlayers] = useState<number[]>([]);
  const [flippedCards, setFlippedCards] = useState<number[]>([]);
  const [guessedNumbers, setGuessedNumbers] = useState<{ [key: number]: number | undefined }>({});
  const [showActionPanel, setShowActionPanel] = useState(false);
  const [calculations, setCalculations] = useState<Calculation[]>([]);
  const [remainingActions, setRemainingActions] = useState({
    add: 4,
    multiply: 4,
    divide: 4,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [gameActive, setGameActive] = useState(true);
  const [gameEnded, setGameEnded] = useState(false);

  // Initialize shuffled players in state
  const [players] = useState(() => shuffleArray(originalPlayers));

  // Add ref for the results list
  const resultsListRef = React.useRef<HTMLDivElement>(null);

  const handleCardClick = (playerId: number) => {
    // If clicking the same card that's already selected, unselect it and flip it back
    if (selectedPlayers.includes(playerId)) {
      setSelectedPlayers((prev) => prev.filter((id) => id !== playerId));
      setFlippedCards((prev) => prev.filter((id) => id !== playerId));
      setShowActionPanel(false);
      return;
    }

    // Check if any operations are still available
    const hasRemainingOperations = Object.values(remainingActions).some((count) => count > 0);

    if (hasRemainingOperations) {
      // Only proceed if we haven't selected 2 cards yet
      if (selectedPlayers.length < 2) {
        setSelectedPlayers((prev) => [...prev, playerId]);
        setFlippedCards((prev) => [...prev, playerId]);

        // Only show action panel when exactly 2 different cards are selected
        if (selectedPlayers.length === 1) {
          setShowActionPanel(true);
        }
      }
    } else {
      // If no operations left, just flip the card for guessing
      if (flippedCards.includes(playerId)) {
        setFlippedCards((prev) => prev.filter((id) => id !== playerId));
      } else {
        setFlippedCards((prev) => [...prev, playerId]);
      }
    }
  };

  const handleGuessChange = (playerId: number, value: string) => {
    // Only allow numbers (no decimals, no negative numbers)
    const numbersOnly = value.replace(/[^0-9]/g, '');

    // Allow empty string or numbers only
    setGuessedNumbers((prev) => ({
      ...prev,
      [playerId]: numbersOnly === '' ? undefined : parseInt(numbersOnly),
    }));
  };

  const performCalculation = (operation: 'add' | 'multiply' | 'divide') => {
    const [player1, player2] = selectedPlayers;
    const num1 = players.find((p) => p.id === player1)?.secretNumber || 0;
    const num2 = players.find((p) => p.id === player2)?.secretNumber || 0;

    let result: number;
    switch (operation) {
      case 'add':
        result = num1 + num2;
        break;
      case 'multiply':
        result = (num1 * num2) % 10;
        break;
      case 'divide': {
        const larger = Math.max(num1, num2);
        const smaller = Math.min(num1, num2);
        result = Math.floor(larger / smaller);
        break;
      }
      default:
        result = 0;
    }

    setCalculations((prev) => [...prev, { player1, player2, operation, result }]);

    setRemainingActions((prev) => ({
      ...prev,
      [operation]: prev[operation as keyof typeof prev] - 1,
    }));

    // Reset selected players and hide action panel
    setSelectedPlayers([]);
    setShowActionPanel(false);

    // Reset flipped cards to show images again
    setFlippedCards((prev) => prev.filter((id) => !selectedPlayers.includes(id)));

    // Scroll to results after a short delay to ensure the new calculation is rendered
    setTimeout(() => {
      resultsListRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }, 100);
  };

  const calculateScore = useCallback(() => {
    let totalScore = 0;
    const correctlyGuessedPlayers: number[] = [];

    // Calculate scores for all guesses
    players.forEach((player) => {
      const guessed = guessedNumbers[player.id];
      if (guessed !== undefined) {
        if (guessed === player.secretNumber) {
          if (player.id === parseInt(localStorage.getItem('userId') || '0')) {
            // Correctly guessing your own number
            totalScore += 5;
          } else {
            // Correctly guessing another player's number
            totalScore += 2;
            correctlyGuessedPlayers.push(player.id);
          }
        } else {
          if (player.id === parseInt(localStorage.getItem('userId') || '0')) {
            // Incorrectly guessing your own number
            totalScore -= 5;
          } else {
            // Incorrectly guessing another player's number
            totalScore -= 2;
          }
        }
      }
    });

    return { score: totalScore, correctGuesses: correctlyGuessedPlayers };
  }, [players, guessedNumbers]);

  const submitScore = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const { score, correctGuesses } = calculateScore();
      const response = await completeSecretNumber(14, score, correctGuesses);
      if (response === null) {
        throw new Error('Failed to submit score');
      }
      setGameActive(false);
      setGameEnded(true);
      onGameEnd(true, true); // Always send as pending
    } catch (error) {
      console.error('Failed to submit secret number score:', error);
      setGameActive(false);
      setGameEnded(true);
      onGameEnd(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Update the useEffect to include necessary dependencies
  useEffect(() => {
    return () => {
      if (gameActive && !isSubmitting && !gameEnded) {
        const { score, correctGuesses } = calculateScore();
        completeSecretNumber(14, score, correctGuesses).catch((error) => {
          console.error('Failed to submit secret number score on unmount:', error);
        });
      }
    };
  }, [gameActive, isSubmitting, gameEnded, calculateScore]);

  const handleSubmit = async () => {
    if (!gameActive || isSubmitting) return;
    await submitScore();
  };

  return (
    <Container>
      <ScrollableContent>
        <Description>
          Select two profiles to perform an equation and select the operation you would like
          perform. To guess a secret number, select one profile and type your guess into the box.
          Guess correctly to earn points. Incorrect guesses will subtract points.
        </Description>
        <Grid>
          {players.map((player) => (
            <PlayerCard
              key={player.id}
              isSelected={selectedPlayers.includes(player.id)}
              isFlipped={flippedCards.includes(player.id)}
              onClick={() => handleCardClick(player.id)}
            >
              <div>
                <CardFront>
                  <PlayerImage src={player.image} alt={player.name} />
                </CardFront>
                <CardBack>
                  <GuessInput
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={guessedNumbers[player.id] || ''}
                    onChange={(e) => handleGuessChange(player.id, e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </CardBack>
              </div>
            </PlayerCard>
          ))}
        </Grid>

        {showActionPanel && (
          <>
            <BlurOverlay
              onClick={() => {
                setSelectedPlayers([]);
                setShowActionPanel(false);
                setFlippedCards((prev) => prev.filter((id) => !selectedPlayers.includes(id)));
              }}
            />
            <ActionPanel>
              <SelectedPlayersDisplay>
                {selectedPlayers.map((playerId) => {
                  const player = players.find((p) => p.id === playerId);
                  return (
                    <SelectedPlayerImage key={playerId} src={player?.image} alt={player?.name} />
                  );
                })}
              </SelectedPlayersDisplay>
              <OperationGrid>
                <OperationBox
                  color="#66cc00"
                  onClick={() => performCalculation('add')}
                  disabled={remainingActions.add === 0}
                >
                  <RemainingCount>{remainingActions.add}</RemainingCount>+
                </OperationBox>
                <OperationBox
                  color="#3366ff"
                  onClick={() => performCalculation('multiply')}
                  disabled={remainingActions.multiply === 0}
                >
                  <RemainingCount>{remainingActions.multiply}</RemainingCount>×
                </OperationBox>
                <OperationBox
                  color="#fd4545"
                  onClick={() => performCalculation('divide')}
                  disabled={remainingActions.divide === 0}
                >
                  <RemainingCount>{remainingActions.divide}</RemainingCount>÷
                </OperationBox>
              </OperationGrid>
            </ActionPanel>
          </>
        )}

        <SubmitButton onClick={handleSubmit}>I&apos;m Done</SubmitButton>

        <ResultsList ref={resultsListRef}>
          <ResultsTitle>Operation History</ResultsTitle>
          {calculations.map((calc, index) => (
            <ResultItem key={index}>
              Operation: {calc.operation.charAt(0).toUpperCase() + calc.operation.slice(1)}, Result:{' '}
              {calc.result}
            </ResultItem>
          ))}
        </ResultsList>
      </ScrollableContent>
    </Container>
  );
};

export default SecretNumber;
