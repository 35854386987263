import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { completeGame } from '../api';

// Types
interface GameState {
  level: number;
  isPlaying: boolean;
  isCountingDown: boolean;
  showCorrect: boolean;
  hasStarted: boolean;
  gameOver: boolean;
}

// Add new interface for timing control
interface TimingControl {
  startTime: number;
  lastUpdateTime: number;
}

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: auto;
  padding: clamp(10px, 2vw, 20px);
  box-sizing: border-box;

  /* Hide scrollbar but keep functionality */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const GameContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: clamp(10px, 2vh, 20px);
  min-height: min-content;
  width: 100%;
  padding-bottom: clamp(10px, 2vh, 20px);
`;

const ImageContainer = styled.div`
  width: clamp(180px, 40vh, 350px);
  height: clamp(180px, 40vh, 350px);
  position: relative;
  margin: clamp(8px, 1.5vh, 15px) 0;
  flex-shrink: 0;
`;

const GameImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const ReadyButton = styled.button`
  padding: clamp(12px, 2vh, 20px) clamp(25px, 4vw, 40px);
  font-size: clamp(1rem, 2vh, 1.6rem);
  background-color: #66cc00;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Chakra Petch', sans-serif;
  font-weight: bold;
  transition: transform 0.2s;
  margin-top: clamp(8px, 1.5vh, 15px);

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const Countdown = styled.div`
  font-size: clamp(4rem, 8vw, 8rem);
  font-weight: bold;
  color: #66cc00;
  animation: ${pulse} 1s infinite;
  margin-top: clamp(100px, 20vh, 200px);
`;

const SeenThatButton = styled.button`
  padding: clamp(12px, 2vh, 20px) clamp(25px, 4vw, 40px);
  background-color: #fd4545;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Chakra Petch', sans-serif;
  font-size: clamp(1rem, 2vh, 1.6rem);
  font-weight: bold;
  transition: transform 0.2s;
  margin-top: clamp(8px, 1.5vh, 15px);

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const LevelImage = styled.img`
  width: clamp(180px, 40vh, 350px);
  height: auto;
  margin: clamp(8px, 1.5vh, 15px) 0;
`;

const DejaWho: React.FC<{ onGameEnd: (success: boolean) => void }> = ({ onGameEnd }) => {
  const [gameState, setGameState] = useState<GameState>({
    level: 1,
    isPlaying: false,
    isCountingDown: false,
    showCorrect: false,
    hasStarted: false,
    gameOver: false,
  });
  const [countdown, setCountdown] = useState(3);
  const [currentImage, setCurrentImage] = useState<string>('');
  const [hasSeenDuplicate, setHasSeenDuplicate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const imageSequenceRef = useRef<string[]>([]);
  const currentIndexRef = useRef(0);
  const timerRef = useRef<NodeJS.Timeout>();
  const duplicateImageRef = useRef<string>('');

  // Add new refs for timing control
  const timingRef = useRef<TimingControl>({ startTime: 0, lastUpdateTime: 0 });
  const animationFrameRef = useRef<number>();

  // Get speed based on level
  const getSpeed = useCallback((level: number) => {
    switch (level) {
      case 1:
        return 1500; // 1.5 seconds
      case 2:
        return 1000; // 1 second
      case 3:
        return 750; // 0.75 seconds
      default:
        return 1500; // Default to level 1 speed
    }
  }, []);

  // Helper function to count occurrences
  const countOccurrencesUpToIndex = (index: number) => {
    return imageSequenceRef.current
      .slice(0, index + 1)
      .filter((img) => img === duplicateImageRef.current).length;
  };

  // Add image preloading function
  const preloadImages = useCallback(() => {
    const images = Array.from({ length: 40 }, (_, i) => `/assets/user_images/user-${i + 4}.png`);

    return Promise.all(
      images.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      }),
    );
  }, []);

  // Replace showNextImage implementation
  const showNextImage = useCallback(() => {
    const updateImage = (timestamp: number) => {
      if (!timingRef.current.startTime) {
        timingRef.current.startTime = timestamp;
        timingRef.current.lastUpdateTime = timestamp;

        const firstImage = imageSequenceRef.current[0];
        setCurrentImage(firstImage);
        currentIndexRef.current = 0;
      }

      const elapsed = timestamp - timingRef.current.lastUpdateTime;
      const speed = getSpeed(gameState.level);

      if (elapsed >= speed) {
        currentIndexRef.current++;

        if (currentIndexRef.current >= imageSequenceRef.current.length) {
          setGameState((prev) => ({ ...prev, gameOver: true }));
          onGameEnd(false);
          return;
        }

        const nextImage = imageSequenceRef.current[currentIndexRef.current];
        setCurrentImage(nextImage);

        if (!hasSeenDuplicate) {
          const occurrenceCount = countOccurrencesUpToIndex(currentIndexRef.current);

          if (occurrenceCount === 2) {
            const currentIsNotDuplicate = nextImage !== duplicateImageRef.current;
            if (currentIsNotDuplicate) {
              clearTimeout(timerRef.current);
              setGameState((prev) => ({ ...prev, gameOver: true }));
              onGameEnd(false);
              return;
            }
          }
        }

        timingRef.current.lastUpdateTime = timestamp;
      }

      animationFrameRef.current = requestAnimationFrame(updateImage);
    };

    animationFrameRef.current = requestAnimationFrame(updateImage);
  }, [gameState.level, hasSeenDuplicate, onGameEnd, getSpeed]);

  // Generate random sequence of images
  const generateSequence = useCallback(() => {
    const baseImages = Array.from(
      { length: 40 },
      (_, i) => `/assets/user_images/user-${i + 4}.png`,
    );

    const imageToRepeat = baseImages[Math.floor(Math.random() * baseImages.length)];
    duplicateImageRef.current = imageToRepeat;

    const finalSequence = [...baseImages, imageToRepeat];
    const shuffledSequence = finalSequence.sort(() => Math.random() - 0.5);

    return shuffledSequence;
  }, []);

  // Update startLevel to include preloading
  const startLevel = useCallback(() => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }

    setGameState((prev) => ({ ...prev, isPlaying: true }));
    showNextImage();
  }, [showNextImage]);

  // Handle countdown
  useEffect(() => {
    if (gameState.isCountingDown && countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }

    if (gameState.isCountingDown && countdown === 0) {
      setGameState((prev) => ({
        ...prev,
        isCountingDown: false,
        hasStarted: true,
      }));
      startLevel();
    }
  }, [countdown, gameState.isCountingDown, startLevel]);

  // Handle "Been there, Seen that!" button click
  const handleSeenThat = async () => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }

    const currentImageInSequence = imageSequenceRef.current[currentIndexRef.current];
    const occurrenceCount = countOccurrencesUpToIndex(currentIndexRef.current);

    if (currentImageInSequence !== duplicateImageRef.current) {
      setGameState((prev) => ({ ...prev, gameOver: true }));
      await completeGame(15, 'fail');
      onGameEnd(false);
      return;
    }

    if (occurrenceCount === 1) {
      setGameState((prev) => ({ ...prev, gameOver: true }));
      await completeGame(15, 'fail');
      onGameEnd(false);
      return;
    }

    if (occurrenceCount === 2 && !hasSeenDuplicate) {
      setHasSeenDuplicate(true);
      setGameState((prev) => ({
        ...prev,
        isPlaying: false,
        showCorrect: true,
        hasStarted: false,
        isCountingDown: false,
      }));

      setTimeout(async () => {
        if (gameState.level === 3) {
          await completeGame(15, 'pass');
          onGameEnd(true);
        } else {
          setGameState((prev) => ({
            ...prev,
            level: prev.level + 1,
            showCorrect: false,
            isCountingDown: false,
            hasStarted: false,
            isPlaying: false,
          }));
          setCountdown(3);
        }
      }, 2000);
    }
  };

  // Cleanup
  useEffect(() => {
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  // Handle space bar press
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === 'Space' && gameState.hasStarted && !gameState.gameOver) {
        event.preventDefault();
        handleSeenThat();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [gameState.hasStarted, gameState.gameOver]);

  const handleReadyUp = async () => {
    setIsLoading(true);
    try {
      // Preload all images first
      await preloadImages();

      // Generate and set up the sequence
      imageSequenceRef.current = generateSequence();
      currentIndexRef.current = 0;

      // Set the first image immediately
      setCurrentImage(imageSequenceRef.current[0]);

      // Reset all game state
      setHasSeenDuplicate(false);
      timingRef.current = { startTime: 0, lastUpdateTime: 0 };

      // Now start the countdown
      setGameState((prev) => ({ ...prev, isCountingDown: true }));
    } catch (error) {
      console.error('Failed to prepare game:', error);
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      {isLoading ? (
        <GameContent>
          <div>Loading...</div>
        </GameContent>
      ) : (
        <>
          {!gameState.hasStarted && !gameState.isCountingDown && !gameState.showCorrect && (
            <>
              <LevelImage
                src={`/assets/deja_who/logo-level-${gameState.level}.png`}
                alt={`Level ${gameState.level}`}
              />
              <ReadyButton onClick={handleReadyUp}>Ready Up!</ReadyButton>
            </>
          )}

          {gameState.isCountingDown && <Countdown>{countdown}</Countdown>}

          {gameState.hasStarted && !gameState.gameOver && !gameState.showCorrect && (
            <GameContent>
              <ImageContainer>
                <GameImage src={currentImage} alt="Player" />
              </ImageContainer>
              <SeenThatButton onClick={handleSeenThat}>
                Been there,
                <br />
                Seen that!
              </SeenThatButton>
            </GameContent>
          )}

          {gameState.showCorrect && (
            <ImageContainer>
              <GameImage src="/assets/deja_who/correct-card.png" alt="Correct!" />
            </ImageContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default DejaWho;
