import React from 'react';
import DescriptionCard from '../DescriptionCard';

const CrosswordDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <DescriptionCard
      title="Crossword"
      description={`**How to play:** Your challenge is to complete the crossword using the clues provided. Each row or column forms a word (or words) that correspond to a specific clue.

If you think your crossword is finished but your screen doesn't display PASS, then your crossword is incorrect. Review your entries and make corrections. You have unlimited revisions and can edit your answers as many times as necessary. The challenge will display PASS when all answers are correct.

⚠️  This is not a timed challenge. Refreshing or leaving and re-entering the page may result in progress lost.  

**Win/Lose Conditions**: Successfully complete the crossword to pass and achieve immunity. Failure to do so, and… well, I think we all know by now what happens then. 

**Estimated time required**: >10 minutes

**Fun-rating:** 10/10`}
      onStart={onStart}
    />
  );
};

export default CrosswordDescription;
