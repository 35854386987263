import React, { useState } from 'react';

interface AccessCodeProps {
  onVerify: (accessCode: string) => Promise<boolean>;
}

const AccessCode: React.FC<AccessCodeProps> = ({ onVerify }) => {
  const [accessCode, setAccessCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const success = await onVerify(accessCode);
      if (!success) {
        setError('Invalid access code. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="access-code-overlay">
      <div className="access-code-modal">
        <h2>Welcome to Advent Challenge</h2>
        <p>Please enter your access code to continue</p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
            placeholder="Enter access code"
            disabled={isLoading}
          />
          {error && <div className="error-message">{error}</div>}
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Verifying...' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AccessCode;
