import React from 'react';
import DescriptionCard from '../DescriptionCard';

const WildVotesDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <DescriptionCard
      title="Wild Votes"
      description={`**How to play:** Your challenge is to guess the correct popularity ranking of 5 animals. 

Each player has **20 tokens** to allocate strategically to influence an animal's popularity. Tokens can be applied positively to increase an animal's popularity, or negatively to decrease an animal's popularity. Each player's token placements will influence the final ranking.

Place the animals in the order you predict they will rank in popularity. Place your pick for **most popular** in first position (far left or top spot) and your choice for **least popular** in fifth position (far right or bottom spot*).  

*This depends on the device you are playing on, mobile or desktop. 

At 9PM (AEDT), tokens from all players will be tallied to determine the final popularity ranking from 1 - 5. 

⚠️  This is not a timed challenge. Leaving, refreshing, or re-entering the page may result in progress lost. 

**Win/Lose Conditions**: To win immunity, you must correctly predict the exact ranking of all 5 animals. You won't know if you have earned immunity until 9PM (AEDT). Failing to correctly predict the position of all 5 animals will see you face elimination tonight. 

**Estimated time required**: Up to you! 

**Fun-rating:** 10/10`}
      onStart={onStart}
    />
  );
};

export default WildVotesDescription;
