import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface WordTiesProps {
  onGameEnd: (success: boolean) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: clamp(10px, 2vw, 20px);
  box-sizing: border-box;
  overflow-y: auto;
`;

const Title = styled.h2`
  text-align: center;
  margin: 0 0 0.5em 0;
  font-size: clamp(1.5rem, 3vw, 2rem);
`;

const Description = styled.p`
  text-align: center;
  margin: 0 0 1em 0;
  font-size: clamp(0.9rem, 1.8vw, 1.1rem);
`;

const GameContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: clamp(20px, 4vh, 40px);
  align-items: center;
  flex: 1;
  min-height: min-content;
`;

const GameGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: clamp(8px, 1.5vw, 15px);
  width: min(100%, 600px);
  margin: 0 auto;
`;

const GridCell = styled.div<{ color: string; isActive?: boolean }>`
  padding: clamp(4px, 0.8vw, 8px);
  border: 2px solid #ccc;
  border-radius: 8px;
  text-align: center;
  cursor: grab;
  background-color: ${(props) => props.color};
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(0.6rem, 1.5vw, 0.9rem);
  word-break: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: clamp(35px, 6vh, 50px);
  touch-action: none;
  opacity: ${(props) => (props.isActive ? 1 : 0.85)};
  transform: ${(props) => (props.isActive ? 'scale(1.1)' : 'none')};
  transition: all 0.2s ease;
  border: ${(props) =>
    props.isActive
      ? '3px solid #1976d2' // Match the submit button color
      : '2px solid #ccc'};
  box-shadow: ${(props) => (props.isActive ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none')};
  z-index: ${(props) => (props.isActive ? '1' : '0')};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;

  &:active {
    cursor: grabbing;
  }
`;

const SubmitButton = styled.button`
  padding: clamp(8px, 1.5vw, 12px) clamp(20px, 3vw, 40px);
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: clamp(0.9rem, 1.8vw, 1.1rem);
  min-width: clamp(100px, 15vw, 160px);
  height: clamp(35px, 6vh, 50px);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #1565c0;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const WordTies: React.FC<WordTiesProps> = ({ onGameEnd }) => {
  const categories = {
    group1: ['TABLET', 'CROWN', 'ROBE', 'TORCH'],
    group2: ['GOLF', 'WHISKY', 'NOVEMBER', 'MIKE'],
    group3: ['AMATEUR', 'GOLDEN', 'PEAK', 'HAPPY'],
    group4: ['PARLIAMENT', 'CARAVAN', 'UNKINDNESS', 'MURDER'],
  };

  const categoryColors = {
    group1: '#65dd63',
    group2: '#e066e1',
    group3: '#e3e36b',
    group4: '#f26378',
  };

  const [gridWords, setGridWords] = useState<string[]>([]);
  const [selectedCell, setSelectedCell] = useState<number | null>(null);

  useEffect(() => {
    // Flatten and shuffle all words
    const allWords = Object.values(categories).flat();
    const shuffled = [...allWords].sort(() => Math.random() - 0.5);
    setGridWords(shuffled);
  }, []);

  const getColorForPosition = (index: number) => {
    if (index < 4) return categoryColors.group1;
    if (index < 8) return categoryColors.group2;
    if (index < 12) return categoryColors.group3;
    return categoryColors.group4;
  };

  const handleSubmit = () => {
    // Get the words in each row
    const rows = [
      gridWords.slice(0, 4),
      gridWords.slice(4, 8),
      gridWords.slice(8, 12),
      gridWords.slice(12, 16),
    ];

    // Check if each row matches any category (in any order)
    const isCorrect = rows.every((rowWords) => {
      return Object.values(categories).some((categoryWords) => {
        // Check if this row contains all words from any category
        return categoryWords.every((word) => rowWords.includes(word));
      });
    });

    onGameEnd(isCorrect);
  };

  const handleCellClick = (index: number) => {
    if (selectedCell === index) {
      // If clicking the same cell, deselect it
      setSelectedCell(null);
    } else if (selectedCell !== null) {
      // If a cell was already selected, perform the swap
      const newWords = [...gridWords];
      [newWords[selectedCell], newWords[index]] = [newWords[index], newWords[selectedCell]];
      setGridWords(newWords);
      setSelectedCell(null);
    } else {
      // Select the cell
      setSelectedCell(index);
    }
  };

  return (
    <Container>
      <Title>Word Ties</Title>
      <Description>
        Tap on a word to select it, then select another word to swap their positions.
      </Description>

      <GameContent>
        <GameGrid>
          {gridWords.map((word, index) => (
            <GridCell
              key={`${word}-${index}`}
              color={getColorForPosition(index)}
              isActive={selectedCell === index}
              onClick={() => handleCellClick(index)}
            >
              {word}
            </GridCell>
          ))}
        </GameGrid>

        <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
      </GameContent>
    </Container>
  );
};

export default WordTies;
