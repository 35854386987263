import React, { useState, useEffect, useCallback } from 'react';
import { completeSquaredle } from '../api';
import styled from '@emotion/styled';

const grid = [
  ['T', 'H', 'A', 'N', 'K'],
  ['Y', 'O', 'U', 'F', 'O'],
  ['R', 'A', 'L', 'L', 'T'],
  ['H', 'E', 'F', 'E', 'E'],
  ['D', 'B', 'A', 'C', 'K'],
];

const mainWords = [
  // 4-letter words
  'afar',
  'ahoy',
  'area',
  'back',
  'bear',
  'beau',
  'bell',
  'cafe',
  'cell',
  'deaf',
  'deal',
  'dear',
  'dell',
  'face',
  'fall',
  'fare',
  'fear',
  'feel',
  'feet',
  'fell',
  'felt',
  'flay',
  'flea',
  'fled',
  'flee',
  'full',
  'funk',
  'half',
  'hall',
  'halo',
  'hare',
  'haul',
  'heal',
  'hear',
  'hell',
  'hero',
  'hole',
  'hula',
  'hull',
  'hunk',
  'keel',
  'keto',
  'knot',
  'leaf',
  'leek',
  'loaf',
  'loan',
  'loft',
  'lore',
  'note',
  'null',
  'oral',
  'real',
  'role',
  'roll',
  'tell',
  'than',
  'thou',
  'tofu',
  'toll',
  'tore',
  'yore',
  // 5-letter words
  'allot',
  'belay',
  'belle',
  'cello',
  'delay',
  'facet',
  'fared',
  'fault',
  'fauna',
  'felon',
  'feral',
  'flare',
  'fleck',
  'fleet',
  'flora',
  'flunk',
  'hello',
  'knoll',
  'oared',
  'relay',
  'royal',
  'thank',
  'thunk',
  // 6-letter words
  'befall',
  'befell',
  'behalf',
  'celery',
  'cellar',
  'deface',
  'delete',
  'felled',
  'flared',
  'fuller',
  'hauled',
  'hauler',
  'hulled',
  'keeled',
  'leafed',
  'loafed',
  'loafer',
  'teller',
  'tolled',
  // 7-letter words
  'default',
  'leaflet',
  // 8-letter words
  'thankful',
];

const totalWords = mainWords.length;

interface SquaredleProps {
  onGameEnd: (success: boolean, isPending?: boolean) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  max-height: inherit;
  overflow: hidden;
  position: relative;
`;

const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: clamp(10px, 2vw, 20px);
  gap: clamp(5px, 1vh, 10px);
  position: relative;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const WordDisplay = styled.div`
  font-size: clamp(1.3rem, 3.5vw, 2rem);
  font-weight: bold;
  margin-bottom: clamp(2px, 0.5vh, 5px);
  min-height: 36px;
  font-family: 'Chakra Petch', sans-serif;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 60px));
  gap: clamp(3px, 1vw, 5px);
  margin: 0;
  position: relative;
  max-width: 75vh;
  max-height: 75vh;
  width: 85%;
  transform-origin: center;

  @media (max-width: 768px) {
    width: 80%;
    max-width: 80vw;
    max-height: 65vh;
  }
`;

const GridButton = styled.button<{ isSelected: boolean }>`
  width: 100%;
  aspect-ratio: 1;
  font-size: clamp(1rem, 3.5vw, 1.5rem);
  font-weight: bold;
  background-color: ${(props) => (props.isSelected ? '#ffc107' : '#f0f0f0')};
  color: #000;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  touch-action: none;
`;

const RotateButton = styled.button`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ccc;
  background: #f0f0f0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  z-index: 1;

  @media (max-width: 480px) {
    width: 25px;
    height: 25px;
    font-size: 16px;
  }
`;

const WordList = styled.div`
  margin-top: clamp(2px, 0.5vh, 5px);
  width: 100%;
  max-width: 400px;
`;

const WordListTitle = styled.h3`
  font-size: clamp(1rem, 2.4vw, 1.4rem);
  margin: clamp(10px, 2vh, 20px) 0;
  font-family: 'Chakra Petch', sans-serif;
  color: #282c34;
`;

const WordListContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 8px;
  margin: 0;
`;

const WordItem = styled.li`
  font-size: clamp(0.9rem, 1.8vw, 1.1rem);
  padding: clamp(4px, 1vh, 8px);
  background-color: #f0f0f0;
  border-radius: 4px;
  text-align: center;
  text-transform: capitalize;
`;

const SubmitButton = styled.button`
  background-color: #66cc00;
  color: white;
  padding: clamp(8px, 2vh, 12px) clamp(16px, 4vw, 24px);
  border: none;
  border-radius: 4px;
  font-size: clamp(0.9rem, 2vw, 1.1rem);
  cursor: pointer;
  margin-bottom: clamp(8px, 1.5vh, 15px);
  font-weight: bold;
  font-family: 'Chakra Petch', sans-serif;
`;

const Message = styled.p<{ type: 'success' | 'duplicate' | 'error' }>`
  font-size: clamp(0.9rem, 1.8vw, 1.1rem);
  color: ${(props) => {
    switch (props.type) {
      case 'success':
        return '#66cc00'; // Green for new valid words
      case 'duplicate':
        return '#ffc107'; // Yellow for already found words
      case 'error':
        return '#fd4545'; // Red for invalid words
    }
  }};
  margin: clamp(8px, 2vh, 15px) 0;
  text-align: center;
  font-weight: bold;
`;

const Squaredle: React.FC<SquaredleProps> = ({ onGameEnd }) => {
  const [selectedLetters, setSelectedLetters] = useState<
    { letter: string; row: number; col: number }[]
  >([]);
  const [foundWords, setFoundWords] = useState<string[]>([]);
  const [message, setMessage] = useState<{
    text: string;
    type: 'success' | 'duplicate' | 'error';
  } | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [typedWord, setTypedWord] = useState('');
  const [rotationDegrees, setRotationDegrees] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [gameActive, setGameActive] = useState(true);

  const getDistance = (x1: number, y1: number, x2: number, y2: number) => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  };

  const handleMouseDown = (letter: string, row: number, col: number) => {
    setIsDragging(true);
    setSelectedLetters([{ letter, row, col }]);
  };

  const handleMouseEnter = (letter: string, row: number, col: number, e: React.MouseEvent) => {
    if (isDragging) {
      const lastLetter = selectedLetters[selectedLetters.length - 1];
      const isAdjacent = Math.abs(row - lastLetter.row) <= 1 && Math.abs(col - lastLetter.col) <= 1;
      const isNotSelected = !selectedLetters.some((sel) => sel.row === row && sel.col === col);

      const element = e.currentTarget as HTMLElement;
      const rect = element.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const mouseDistance = getDistance(e.clientX, e.clientY, centerX, centerY);
      const isCloseEnough = mouseDistance < rect.width * 0.7; // Stricter (was 0.95)

      if (isAdjacent && isNotSelected && isCloseEnough) {
        setSelectedLetters([...selectedLetters, { letter, row, col }]);
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    handleSubmitWord();
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        const word = typedWord.toLowerCase();
        if (mainWords.includes(word)) {
          if (!foundWords.includes(word)) {
            setFoundWords((prev) => [...prev, word]);
            setMessage({ text: `Great! You found "${word}"`, type: 'success' });
          } else {
            setMessage({ text: `You've already found "${word}"`, type: 'duplicate' });
          }
        } else {
          setMessage({ text: `"${word}" is not a valid word.`, type: 'error' });
        }
        setTypedWord('');
      } else if (event.key === 'Backspace') {
        setTypedWord((prev) => prev.slice(0, -1));
      } else if (event.key.length === 1 && /[a-zA-Z]/.test(event.key)) {
        setTypedWord((prev) => prev + event.key.toUpperCase());
      }
    },
    [typedWord, foundWords],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleSubmitWord = () => {
    const word = selectedLetters
      .map((sel) => sel.letter)
      .join('')
      .toLowerCase();

    if (mainWords.includes(word)) {
      if (!foundWords.includes(word)) {
        setFoundWords((prev) => [...prev, word]);
        setMessage({ text: `Great! You found "${word}"`, type: 'success' });
      } else {
        setMessage({ text: `You've already found "${word}"`, type: 'duplicate' });
      }
    } else {
      setMessage({ text: `"${word}" is not a valid word.`, type: 'error' });
    }

    setSelectedLetters([]);
  };

  const submitScore = async () => {
    if (isSubmitting) return; // Prevent multiple submissions

    setIsSubmitting(true);
    try {
      const response = await completeSquaredle(4, foundWords.length);
      if (response === null) {
        throw new Error('Failed to submit score');
      }
      setGameActive(false);
      onGameEnd(true, true);
    } catch (error) {
      console.error('Failed to submit squaredle score:', error);
      setGameActive(false);
      onGameEnd(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (foundWords.length === totalWords && gameActive && !isSubmitting) {
      submitScore();
    }
  }, [foundWords.length, gameActive, isSubmitting]);

  useEffect(() => {
    return () => {
      // Cleanup function - submit score if game is still active when unmounting
      if (gameActive && !isSubmitting) {
        completeSquaredle(4, foundWords.length).catch((error) => {
          console.error('Failed to submit squaredle score on unmount:', error);
        });
      }
    };
  }, [gameActive, foundWords.length, isSubmitting]);

  const handleSubmit = async () => {
    if (!gameActive || isSubmitting) return;
    await submitScore();
  };

  const rotateGrid = () => {
    setRotationDegrees((prev) => prev + 90);
  };

  // Add touch event handlers
  const handleTouchStart = (letter: string, row: number, col: number, e: React.TouchEvent) => {
    e.preventDefault(); // Prevent scrolling while dragging
    setIsDragging(true);
    setSelectedLetters([{ letter, row, col }]);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging) return;

    const touch = e.touches[0];
    const elements = document.elementsFromPoint(touch.clientX, touch.clientY);
    const gridButton = elements.find(
      (el) => el.tagName === 'BUTTON' && el.textContent?.length === 1,
    );

    if (gridButton) {
      const [row, col] =
        (gridButton as HTMLElement).getAttribute('data-position')?.split('-') || [];
      const letter = gridButton.textContent || '';

      if (row && col) {
        const rowNum = parseInt(row);
        const colNum = parseInt(col);
        const lastLetter = selectedLetters[selectedLetters.length - 1];

        // Get the button's position in the viewport
        const buttonRect = (gridButton as HTMLElement).getBoundingClientRect();
        const buttonCenterX = buttonRect.left + buttonRect.width / 2;
        const buttonCenterY = buttonRect.top + buttonRect.height / 2;

        // Calculate distance from touch point to button center
        const touchDistance = getDistance(
          touch.clientX,
          touch.clientY,
          buttonCenterX,
          buttonCenterY,
        );

        // Only consider the button if touch is close enough to its center
        const isCloseEnough = touchDistance < buttonRect.width * 0.4; // Adjust this threshold as needed

        const isAdjacent =
          Math.abs(rowNum - lastLetter.row) <= 1 && Math.abs(colNum - lastLetter.col) <= 1;
        const isNotSelected = !selectedLetters.some(
          (sel) => sel.row === rowNum && sel.col === colNum,
        );

        if (isAdjacent && isNotSelected && isCloseEnough) {
          setSelectedLetters([...selectedLetters, { letter, row: rowNum, col: colNum }]);
        }
      }
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    handleSubmitWord();
  };

  return (
    <Container>
      <ScrollableContent>
        <WordDisplay>
          {isDragging
            ? selectedLetters.map((sel) => sel.letter).join('')
            : typedWord || selectedLetters.map((sel) => sel.letter).join('')}
        </WordDisplay>

        <div
          style={{
            position: 'relative',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 'clamp(20px, 4vh, 40px) 0 clamp(10px, 2vh, 20px)',
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'fit-content',
            }}
          >
            <RotateButton onClick={rotateGrid}>↻</RotateButton>
            <GridContainer style={{ transform: `rotate(${rotationDegrees}deg)` }}>
              {grid.map((row, rowIndex) =>
                row.map((letter, colIndex) => (
                  <GridButton
                    key={`${rowIndex}-${colIndex}`}
                    onMouseDown={() => handleMouseDown(letter, rowIndex, colIndex)}
                    onMouseEnter={(e) => handleMouseEnter(letter, rowIndex, colIndex, e)}
                    onMouseUp={handleMouseUp}
                    onTouchStart={(e) => handleTouchStart(letter, rowIndex, colIndex, e)}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    data-position={`${rowIndex}-${colIndex}`}
                    isSelected={selectedLetters.some(
                      (sel) => sel.row === rowIndex && sel.col === colIndex,
                    )}
                    style={{ transform: `rotate(-${rotationDegrees}deg)` }}
                  >
                    {letter}
                  </GridButton>
                )),
              )}
            </GridContainer>
          </div>
        </div>

        {message && <Message type={message.type}>{message.text}</Message>}

        <WordDisplay>{foundWords.length} / ?</WordDisplay>
        <SubmitButton onClick={handleSubmit}>Abandon All Hope</SubmitButton>

        <WordList>
          <WordListTitle>Found Words</WordListTitle>
          <WordListContainer>
            {foundWords.map((word) => (
              <WordItem key={word}>{word}</WordItem>
            ))}
          </WordListContainer>
        </WordList>
      </ScrollableContent>
    </Container>
  );
};

export default Squaredle;
