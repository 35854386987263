import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

// Types
interface CrosswordCell {
  letter: string;
  number?: number;
  isBlack?: boolean;
  userInput?: string;
  isAcrossStart?: boolean;
  isDownStart?: boolean;
  acrossClueNumber?: number;
  downClueNumber?: number;
}

// Updated styled components for crossword.tsx

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: clamp(10px, 2vw, 20px);
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  outline: none;

  /* Hide scrollbar but keep functionality */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin: 0 0 clamp(1em, 2vh, 1.5em) 0;
  font-size: clamp(1.5rem, 3vw, 2rem);
  font-family: 'Chakra Petch', sans-serif;
  color: #282c34;
`;

const CrosswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: clamp(20px, 4vh, 40px);
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(25, 1fr);
  gap: 1px;
  background-color: transparent;
  padding: 1px;
  width: min(95%, 600px);
  margin: 0 auto;
  box-sizing: border-box;

  &::before {
    content: '';
    width: 0;
    padding-bottom: 72%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  & > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  @media (max-width: 768px) {
    width: min(95%, 450px);
  }

  @media (max-width: 480px) {
    width: min(95%, 350px);
  }
`;

const Cell = styled.div<{
  isBlack?: boolean;
  isHighlighted?: boolean;
  highlightColor?: string;
}>`
  position: relative;
  background-color: ${(props) =>
    props.isBlack ? 'transparent' : props.isHighlighted ? props.highlightColor : '#fff'};
  border: ${(props) => (props.isBlack ? 'none' : '1px solid #000')};
  display: flex;
  flex-direction: column;
  cursor: ${(props) => (props.isBlack ? 'default' : 'pointer')};
  box-sizing: border-box;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const CellInput = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: clamp(0.7rem, 1.4vw, 1.1rem);
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Chakra Petch', sans-serif;
  font-weight: 500;
  line-height: 1;
  transform: translateY(0.5px);

  @media (max-width: 768px) {
    font-size: clamp(0.6rem, 1.2vw, 0.9rem);
    transform: none;
  }

  @media (max-width: 480px) {
    font-size: clamp(0.5rem, 1vw, 0.8rem);
  }
`;

const CluesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 250px), 1fr));
  gap: clamp(20px, 4vw, 40px);
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: clamp(15px, 3vw, 30px);
  }
`;

const ClueSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: clamp(10px, 2vh, 20px);
`;

const ClueTitle = styled.h3`
  margin: 0;
  font-size: clamp(1.1rem, 2.2vw, 1.4rem);
  border-bottom: 2px solid #000;
  padding-bottom: clamp(5px, 1vh, 10px);
  font-family: 'Chakra Petch', sans-serif;
  color: #282c34;

  @media (max-width: 768px) {
    font-size: clamp(1rem, 2vw, 1.2rem);
  }
`;

const ClueList = styled.div`
  display: flex;
  flex-direction: column;
  gap: clamp(8px, 1.5vh, 12px);
  width: 100%;
  box-sizing: border-box;
`;

const BaseClue = styled.div`
  font-size: clamp(0.9rem, 1.8vw, 1.1rem);
  padding: clamp(4px, 0.8vh, 8px);
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s;
  width: 100%;
  box-sizing: border-box;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: clamp(0.85rem, 1.6vw, 1rem);
    padding: clamp(3px, 0.6vh, 6px);
  }

  @media (max-width: 480px) {
    font-size: clamp(0.8rem, 1.4vw, 0.9rem);
    padding: clamp(2px, 0.4vh, 4px);
  }
`;

const AcrossClue = styled(BaseClue)`
  // Remove hover effects - no additional styling needed
`;

const DownClue = styled(BaseClue)`
  // Remove hover effects - no additional styling needed
`;

// Add this new styled component for the hidden input
const HiddenInput = styled.input`
  position: absolute;
  top: -1000px;
  left: -1000px;
  opacity: 0;
  pointer-events: none;
`;

const Description = styled.p`
  text-align: center;
  margin: 0 0 clamp(0.8em, 1.5vh, 1em) 0;
  font-size: clamp(1rem, 2vw, 1.2rem);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 clamp(20px, 4vw, 40px);
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: clamp(0.95rem, 1.8vw, 1.1rem);
    margin-bottom: clamp(0.7rem, 1.2vh, 0.9rem);
    padding: 0 clamp(15px, 3vw, 30px);
    max-width: 90%;
  }

  @media (max-width: 480px) {
    font-size: clamp(0.9rem, 1.6vw, 1rem);
    margin-bottom: clamp(0.6rem, 1vh, 0.8rem);
    padding: 0 clamp(10px, 2vw, 20px);
  }
`;

// Crossword puzzle data
const puzzleData = {
  grid: [
    '..........S..............',
    '.....O....P...........H..',
    '...OBLITERATED.PERFECTION',
    '...R.D....M....R......T..',
    '...V.O.......S.I...WORM..',
    '...I.GRAVY.S.K.COUP...A..',
    '...L.......THUNK......N.N',
    '...L.......R.L...F......O',
    '...ADVENTCHALLENGE......C',
    '...I.......T...O.A...D..L',
    'C..N.......E...N.T...R..U',
    'O....CLOWN.G.SNAFU.DWAYNE',
    'V..W....H..Y.U.P.R.E.M...',
    'I..A....E....C.O.E.M.A...',
    'DEBT..FEEDBACK.L...U.....',
    '...E..A.L....S.O...R.....',
    '...RIZZ........GOOSE.....',
    '......EFFICIENCY.........',
  ],
  acrossClues: [
    {
      number: 4,
      clue: "Freda's Friends ___ QuickFire Quiz (11)",
      answer: 'OBLITERATED',
      startRow: 2,
      startCol: 3,
      length: 11,
    },
    {
      number: 6,
      clue: 'A&T on Day 8 (10)',
      answer: 'PERFECTION',
      startRow: 2,
      startCol: 15,
      length: 10,
    },
    {
      number: 8,
      clue: 'Will you still love me if I was a... (4)',
      answer: 'WORM',
      startRow: 4,
      startCol: 19,
      length: 4,
    },
    {
      number: 9,
      clue: '30 30 30 30 30 30 30 30 (5)',
      answer: 'GRAVY',
      startRow: 5,
      startCol: 5,
      length: 5,
    },
    {
      number: 11,
      clue: 'Sudden overthrow or just a bold move (4)',
      answer: 'COUP',
      startRow: 5,
      startCol: 15,
      length: 4,
    },
    {
      number: 12,
      clue: 'Past tense of think (5)',
      answer: 'THUNK',
      startRow: 6,
      startCol: 11,
      length: 5,
    },
    {
      number: 15,
      clue: 'Suspected pyramid scheme (6,9)',
      answer: 'ADVENTCHALLENGE',
      startRow: 8,
      startCol: 3,
      length: 15,
    },
    {
      number: 18,
      clue: 'US President Elect (5)',
      answer: 'CLOWN',
      startRow: 11,
      startCol: 5,
      length: 5,
    },
    {
      number: 20,
      clue: 'A messy situ (5)',
      answer: 'SNAFU',
      startRow: 11,
      startCol: 13,
      length: 5,
    },
    {
      number: 21,
      clue: '🪨 (6)',
      answer: 'DWAYNE',
      startRow: 11,
      startCol: 19,
      length: 6,
    },
    {
      number: 23,
      clue: 'What you leave uni with (4)',
      answer: 'DEBT',
      startRow: 14,
      startCol: 0,
      length: 4,
    },
    {
      number: 24,
      clue: 'Unwelcome and abundant (8)',
      answer: 'FEEDBACK',
      startRow: 14,
      startCol: 6,
      length: 8,
    },
    {
      number: 25,
      clue: "Young person's charisma (4)",
      answer: 'RIZZ',
      startRow: 16,
      startCol: 3,
      length: 4,
    },
    {
      number: 26,
      clue: 'Wooden spoon (5)',
      answer: 'GOOSE',
      startRow: 16,
      startCol: 15,
      length: 5,
    },
    {
      number: 27,
      clue: 'The department that requires two people to run, according to Trump (10)',
      answer: 'EFFICIENCY',
      startRow: 17,
      startCol: 6,
      length: 10,
    },
  ],
  downClues: [
    {
      number: 1,
      clue: 'Plenty of it on WhatsApp—also meat (4)',
      answer: 'SPAM',
      startRow: 0,
      startCol: 10,
      length: 4,
    },
    {
      number: 2,
      clue: 'Mr Free Pass (5)',
      answer: 'OLDOG',
      startRow: 1,
      startCol: 5,
      length: 5,
    },
    {
      number: 3,
      clue: '___ turned Olympic legend (6)',
      answer: 'HITMAN',
      startRow: 1,
      startCol: 22,
      length: 6,
    },
    {
      number: 5,
      clue: 'An unlikely hero (9)',
      answer: 'ORVILLAIN',
      startRow: 2,
      startCol: 3,
      length: 9,
    },
    {
      number: 6,
      clue: 'A spiny plant or an irritating person (5)',
      answer: 'PRICK',
      startRow: 2,
      startCol: 15,
      length: 5,
    },
    {
      number: 7,
      clue: "Reaction to something so funny, you're digitally 'dead' (5)",
      answer: 'SKULL',
      startRow: 4,
      startCol: 13,
      length: 5,
    },
    {
      number: 10,
      clue: 'How to make ___ (8)',
      answer: 'STRATEGY',
      startRow: 5,
      startCol: 11,
      length: 8,
    },
    {
      number: 13,
      clue: '(2,4)',
      answer: 'NOCLUE',
      startRow: 6,
      startCol: 24,
      length: 6,
    },
    {
      number: 14,
      clue: 'Not a bug (7)',
      answer: 'FEATURE',
      startRow: 7,
      startCol: 17,
      length: 7,
    },
    {
      number: 15,
      clue: "'Sorry if you were offended,' for one (10)",
      answer: 'NONAPOLOGY',
      startRow: 8,
      startCol: 15,
      length: 10,
    },
    {
      number: 16,
      clue: 'When a Queen spills the tea… (5)',
      answer: 'DRAMA',
      startRow: 9,
      startCol: 21,
      length: 5,
    },
    {
      number: 17,
      clue: 'Viral sensation (5)',
      answer: 'COVID',
      startRow: 10,
      startCol: 0,
      length: 5,
    },
    {
      number: 19,
      clue: 'You have already guessed it, well some of you have (5)',
      answer: 'WHEEL',
      startRow: 11,
      startCol: 8,
      length: 5,
    },
    {
      number: 20,
      clue: 'Good or bad vacuum cleaner review (5)',
      answer: 'SUCKS',
      startRow: 11,
      startCol: 13,
      length: 5,
    },
    {
      number: 21,
      clue: 'This game is very ___, very mindful. (6)',
      answer: 'DEMURE',
      startRow: 11,
      startCol: 19,
      length: 6,
    },
    {
      number: 22,
      clue: 'H, I, J, K, L, M, N, O (5)',
      answer: 'WATER',
      startRow: 12,
      startCol: 3,
      length: 5,
    },
    {
      number: 24,
      clue: 'Disturb the cool facade (4)',
      answer: 'FAZE',
      startRow: 14,
      startCol: 6,
      length: 4,
    },
  ],
};

// Helper function to find words for a cell
const findWordForCell = (row: number, col: number) => {
  // Check if cell is part of an across word
  const acrossClue = puzzleData.acrossClues.find((clue) => {
    const inRow = clue.startRow === row;
    const inRange = col >= clue.startCol && col < clue.startCol + clue.length;
    return inRow && inRange;
  });

  // Check if cell is part of a down word
  const downClue = puzzleData.downClues.find((clue) => {
    // Check if we're in the same column
    const inCol = clue.startCol === col;
    // Check if the row is within the word's range
    const inRange = row >= clue.startRow && row < clue.startRow + clue.length;

    console.log(`Checking down clue at (${row}, ${col}):`, {
      clue,
      inCol,
      inRange,
      startRow: clue.startRow,
      endRow: clue.startRow + clue.length,
    });

    return inCol && inRange;
  });

  console.log(`Cell at (${row}, ${col}):`, {
    foundAcross: !!acrossClue,
    foundDown: !!downClue,
    acrossClue,
    downClue,
  });

  return { acrossClue, downClue };
};

// Updated initializeGrid function
const initializeGrid = () => {
  const grid: CrosswordCell[][] = [];
  const rows = puzzleData.grid.length;
  const cols = puzzleData.grid[0].length;

  // Create empty grid
  for (let i = 0; i < rows; i++) {
    grid[i] = [];
    for (let j = 0; j < cols; j++) {
      const char = puzzleData.grid[i][j];
      const isBlackCell = char === '.';

      // Find if this cell is the start of any clues
      const acrossClue = puzzleData.acrossClues.find((c) => c.startRow === i && c.startCol === j);
      const downClue = puzzleData.downClues.find((c) => c.startRow === i && c.startCol === j);

      grid[i][j] = {
        letter: isBlackCell ? '' : char,
        isBlack: isBlackCell,
        userInput: '',
        isAcrossStart: !!acrossClue,
        isDownStart: !!downClue,
        acrossClueNumber: acrossClue?.number,
        downClueNumber: downClue?.number,
      };
    }
  }

  return grid;
};

function getAcrossWordCells(startRow: number, startCol: number) {
  const acrossClue = puzzleData.acrossClues.find(
    (c) => c.startRow === startRow && c.startCol <= startCol && startCol < c.startCol + c.length,
  );
  if (!acrossClue) return [];

  const cells = [];
  for (let c = acrossClue.startCol; c < acrossClue.startCol + acrossClue.length; c++) {
    cells.push({ row: startRow, col: c });
  }
  return cells;
}

function getDownWordCells(startRow: number, startCol: number) {
  const downClue = puzzleData.downClues.find(
    (c) => c.startCol === startCol && c.startRow <= startRow && startRow < c.startRow + c.length,
  );
  if (!downClue) return [];

  const cells = [];
  for (let r = downClue.startRow; r < downClue.startRow + downClue.length; r++) {
    cells.push({ row: r, col: startCol });
  }
  return cells;
}

const Crossword: React.FC<{ onGameEnd: (success: boolean) => void }> = ({ onGameEnd }) => {
  const [grid, setGrid] = useState<CrosswordCell[][]>(initializeGrid());
  const [highlightedWordCells, setHighlightedWordCells] = useState<
    { row: number; col: number; main?: boolean }[]
  >([]);
  const [highlightDirection, setHighlightDirection] = useState<'across' | 'down' | null>(null);
  const [highlightedStartCell, setHighlightedStartCell] = useState<{
    row: number;
    col: number;
  } | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  // Focus the hidden input when a word is selected
  useEffect(() => {
    if (highlightedWordCells.length > 0 && hiddenInputRef.current) {
      hiddenInputRef.current.focus();
    }
  }, [highlightedWordCells]);

  const handleCellClick = (row: number, col: number) => {
    const cell = grid[row][col];
    if (cell.isBlack) return;

    const { acrossClue, downClue } = findWordForCell(row, col);

    // If no words found, clear highlights
    if (!acrossClue && !downClue) {
      setHighlightedWordCells([]);
      setHighlightDirection(null);
      setHighlightedStartCell(null);
      return;
    }

    // If we clicked the same start cell again, toggle direction
    if (
      highlightedStartCell &&
      highlightedStartCell.row === row &&
      highlightedStartCell.col === col
    ) {
      let newDirection: 'across' | 'down' | null = null;
      let cells: { row: number; col: number; main?: boolean }[] = [];

      if (highlightDirection === 'across' && downClue) {
        newDirection = 'down';
        cells = getDownWordCells(downClue.startRow, downClue.startCol).map((cellPos, index) => ({
          ...cellPos,
          main: index === 0,
        }));
      } else if (highlightDirection === 'down' && acrossClue) {
        newDirection = 'across';
        cells = getAcrossWordCells(acrossClue.startRow, acrossClue.startCol).map(
          (cellPos, index) => ({ ...cellPos, main: index === 0 }),
        );
      } else {
        setHighlightedWordCells([]);
        setHighlightDirection(null);
        setHighlightedStartCell(null);
        return;
      }

      setHighlightDirection(newDirection);
      setHighlightedWordCells(cells);
    } else {
      let newDirection: 'across' | 'down' | null = null;
      let cells: { row: number; col: number; main?: boolean }[] = [];

      if (acrossClue) {
        newDirection = 'across';
        cells = getAcrossWordCells(acrossClue.startRow, acrossClue.startCol).map(
          (cellPos, index) => ({ ...cellPos, main: index === 0 }),
        );
      } else if (downClue) {
        newDirection = 'down';
        cells = getDownWordCells(downClue.startRow, downClue.startCol).map((cellPos, index) => ({
          ...cellPos,
          main: index === 0,
        }));
      }

      setHighlightedStartCell({ row, col });
      setHighlightDirection(newDirection);
      setHighlightedWordCells(cells);
    }

    // Focus the hidden input for mobile keyboard
    if (hiddenInputRef.current) {
      hiddenInputRef.current.focus();
    }
  };

  const updateCellInput = (row: number, col: number, value: string) => {
    const newGrid = [...grid];
    newGrid[row][col] = { ...newGrid[row][col], userInput: value.toUpperCase() };
    setGrid(newGrid);

    // Find next empty cell in the sequence
    if (value && highlightedWordCells.length > 0) {
      const currentIndex = highlightedWordCells.findIndex(
        (cell) => cell.row === row && cell.col === col,
      );
      const nextEmptyIndex = highlightedWordCells.findIndex(
        (cell, index) => index > currentIndex && !grid[cell.row][cell.col].userInput,
      );

      // If there's a next empty cell, focus the hidden input
      if (nextEmptyIndex !== -1) {
        if (hiddenInputRef.current) {
          hiddenInputRef.current.focus();
        }
      }
    }

    // Check if puzzle is complete - compare each cell's userInput with its correct letter
    const isComplete = newGrid.every((row) =>
      row.every((cell) => {
        // Skip black cells
        if (cell.isBlack) return true;
        // Check if the cell has user input and it matches the correct letter
        return cell.userInput && cell.userInput === cell.letter;
      }),
    );

    // If all cells are correct, trigger game end
    if (isComplete) {
      console.log('Crossword completed successfully!');
      onGameEnd(true);
    }
  };

  const handleHiddenInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase();
    if (value && highlightedWordCells.length > 0) {
      const key = value.slice(-1); // Take the last character entered
      const emptyCell = highlightedWordCells.find((cell) => !grid[cell.row][cell.col].userInput);

      if (emptyCell) {
        updateCellInput(emptyCell.row, emptyCell.col, key);
      }

      // Clear the input after handling
      if (hiddenInputRef.current) {
        hiddenInputRef.current.value = '';
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!highlightedWordCells.length || !highlightDirection) return;

    const key = e.key;

    if (/^[a-zA-Z]$/.test(key)) {
      e.preventDefault();
      const emptyCell = highlightedWordCells.find((cell) => !grid[cell.row][cell.col].userInput);
      if (emptyCell) {
        updateCellInput(emptyCell.row, emptyCell.col, key);
      }
    } else if (key === 'Backspace') {
      e.preventDefault();
      const lastFilledIndex = [...highlightedWordCells]
        .reverse()
        .findIndex(({ row, col }) => grid[row][col].userInput !== '');

      if (lastFilledIndex !== -1) {
        const targetIndex = highlightedWordCells.length - 1 - lastFilledIndex;
        const { row, col } = highlightedWordCells[targetIndex];
        updateCellInput(row, col, '');
      }
    }
  };

  const getHighlightColor = (row: number, col: number): string | undefined => {
    const found = highlightedWordCells.find((c) => c.row === row && c.col === col);
    if (!found) return undefined;

    if (highlightDirection === 'across') {
      // Green theme
      return found.main ? '#66cc00' : '#d9f2d9';
    } else if (highlightDirection === 'down') {
      // Red theme
      return found.main ? '#fd4545' : '#ffd9d9';
    }
    return undefined;
  };

  return (
    <Container ref={containerRef} tabIndex={0} onKeyDown={handleKeyDown}>
      <Title>Crossword</Title>
      <Description>
        Click a square to select a word. Click again to switch between horizontal and vertical
        words. Type your answer to fill the highlighted word.
      </Description>
      <CrosswordContainer>
        <Grid>
          {grid.map((row, rowIndex) =>
            row.map((cell, colIndex) => (
              <Cell
                key={`${rowIndex}-${colIndex}`}
                isBlack={cell.isBlack}
                isHighlighted={
                  !!highlightedWordCells.find((c) => c.row === rowIndex && c.col === colIndex)
                }
                highlightColor={getHighlightColor(rowIndex, colIndex)}
                onClick={() => handleCellClick(rowIndex, colIndex)}
              >
                {!cell.isBlack && <CellInput>{cell.userInput || ''}</CellInput>}
              </Cell>
            )),
          )}
        </Grid>

        {/* Hidden Input for Mobile */}
        <HiddenInput
          ref={hiddenInputRef}
          type="text"
          inputMode="text"
          autoCapitalize="characters"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          onChange={handleHiddenInputChange}
        />

        <CluesContainer>
          <ClueSection>
            <ClueTitle>Across</ClueTitle>
            <ClueList>
              {puzzleData.acrossClues.map((clue) => (
                <AcrossClue key={clue.number}>{clue.clue}</AcrossClue>
              ))}
            </ClueList>
          </ClueSection>

          <ClueSection>
            <ClueTitle>Down</ClueTitle>
            <ClueList>
              {puzzleData.downClues.map((clue) => (
                <DownClue key={clue.number}>{clue.clue}</DownClue>
              ))}
            </ClueList>
          </ClueSection>
        </CluesContainer>
      </CrosswordContainer>
    </Container>
  );
};

export default Crossword;
