import React from 'react';
import DescriptionCard from '../DescriptionCard';

const GuessTheWordDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => (
  <DescriptionCard
    title="Guess the Word"
    description={`**How to Play:** Your challenge is to guess the secret word. After each guess, you will find out how semantically similar your guess is to the secret word, based on **related meaning**. Similarity is indicated on a scale from 1 - 100 with the secret word being number 1. The closer your guess is to the number 1, the greater similarity it has to the secret word.

⚠️  You have unlimited time and unlimited guesses.

**Win/Lose Conditions**: Guess the mystery word to earn immunity. Failure to guess the word will put you up for elimination.

**Estimated time required**: We would rather not say.

**Fun-rating:** 10/10`}
    onStart={onStart}
  />
);

export default GuessTheWordDescription;
