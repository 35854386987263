import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: clamp(10px, 2vw, 20px);
  box-sizing: border-box;
`;

const Title = styled.h2`
  text-align: center;
  margin: 0 0 clamp(1em, 2vh, 1.5em) 0;
  font-size: clamp(1.5rem, 3vw, 2rem);
`;

const Description = styled.p`
  text-align: center;
  margin: 0 0 clamp(1.5em, 3vh, 2em) 0;
  font-size: clamp(1.1rem, 2.2vw, 1.4rem);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 clamp(10px, 2vw, 20px);
`;

const BoxesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: clamp(15px, 3vw, 30px);
  margin-top: clamp(20px, 4vh, 40px);
  position: relative;
  width: min(800px, 80vw);
  height: min(800px, 80vw);
  margin: clamp(20px, 4vh, 40px) auto 0;

  @media (max-width: 480px) {
    width: min(400px, 90vw);
    height: min(400px, 90vw);
    gap: clamp(10px, 2vw, 20px);
    margin-top: -40px;
  }
`;

const orbit = (index: number) => `
  @keyframes orbit-${index} {
    from {
      transform: rotate(${index * 120}deg) translateX(min(160px, 16vw)) rotate(-${index * 120}deg);
    }
    to {
      transform: rotate(${index * 120 + 360}deg) translateX(min(160px, 16vw)) rotate(-${
  index * 120 + 360
}deg);
    }
  }
`;

const wobble = `
  @keyframes wobble {
    0% { 
      rotate: 0deg;
      scale: 1;
    }
    25% { 
      rotate: -5deg;
      scale: 1.05;
    }
    75% { 
      rotate: 5deg;
      scale: 1.05;
    }
    100% { 
      rotate: 0deg;
      scale: 1;
    }
  }
`;

const Box = styled.div<{ isSelected: boolean; index: number; isWobbling: boolean }>`
  width: clamp(140px, 25vw, 250px);
  height: clamp(140px, 25vw, 250px);
  cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(70px, 12vw, 150px);
  transition: all 0.3s ease;
  position: absolute;
  transform-origin: center center;
  animation: ${({ index }) => `orbit-${index} 20s linear infinite`}
    ${({ isWobbling }) => (isWobbling ? `, wobble 0.5s ease-in-out` : '')};
  ${({ index }) => orbit(index)}
  ${wobble}

  transform: ${({ isSelected }) => (isSelected ? 'scale(1.1)' : 'scale(1)')};
  filter: brightness(1);

  &:hover {
    transform: ${({ isSelected }) => (isSelected ? 'scale(1.1)' : 'scale(1.15)')};
    filter: brightness(1.1);
    z-index: 1;
  }

  @media (max-width: 480px) {
    width: clamp(120px, 35vw, 160px);
    height: clamp(120px, 35vw, 160px);
    font-size: clamp(60px, 18vw, 90px);
  }
`;

interface ChooseBoxProps {
  onGameEnd: (success: boolean) => void;
}

const ChooseBox: React.FC<ChooseBoxProps> = ({ onGameEnd }) => {
  const [selectedBox, setSelectedBox] = useState<number | null>(null);
  const [winningBox] = useState(() => Math.floor(Math.random() * 3));
  const [wobblingBox, setWobblingBox] = useState<number | null>(null);

  React.useEffect(() => {
    if (selectedBox !== null) return;

    const wobbleInterval = setInterval(() => {
      const randomBox = Math.floor(Math.random() * 3);
      setWobblingBox(randomBox);
      setTimeout(() => setWobblingBox(null), 500);
    }, 5000);

    return () => clearInterval(wobbleInterval);
  }, [selectedBox]);

  const handleBoxClick = (boxIndex: number) => {
    if (selectedBox !== null) return;
    setSelectedBox(boxIndex);
    onGameEnd(boxIndex === winningBox);
  };

  return (
    <Container>
      <Title>Mystery Box</Title>
      <Description>Feeling lucky? Pick a box, but choose wisely!</Description>
      <BoxesContainer>
        {[0, 1, 2].map((boxIndex) => (
          <Box
            key={boxIndex}
            onClick={() => handleBoxClick(boxIndex)}
            isSelected={selectedBox === boxIndex}
            index={boxIndex}
            isWobbling={wobblingBox === boxIndex}
          >
            {selectedBox === boxIndex ? (
              <span>{boxIndex === winningBox ? '✅' : '❌'}</span>
            ) : (
              <span>🎁</span>
            )}
          </Box>
        ))}
      </BoxesContainer>
    </Container>
  );
};

export default ChooseBox;
