import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid, Paper } from '@mui/material';

interface LetterPileProps {
  onGameEnd: (success: boolean) => void;
}

// Define the category type for future expansion
type Category = {
  name: string;
  letters: string;
  correctLetter: string;
};

// For now we only have fruits, but we can add more categories later
const CATEGORIES: Category[] = [
  {
    name: 'Fruits',
    letters: 'coconutkiwipeargrapedurianavocadol',
    correctLetter: 'l',
  },
  {
    name: 'Countries',
    letters: 'qatarjapanindiabrazilcanadafinlandr',
    correctLetter: 'r',
  },
  {
    name: 'Animals',
    letters: 'butterflyzebrafoxpenguinwolfgiraffeb',
    correctLetter: 'b',
  },
];

// Randomly select a category (will be more useful when we have multiple)
const SELECTED_CATEGORY = CATEGORIES[Math.floor(Math.random() * CATEGORIES.length)];
const LETTER_SET = SELECTED_CATEGORY.letters.split('').sort(() => Math.random() - 0.5);
const CORRECT_LETTER = SELECTED_CATEGORY.correctLetter;

const LetterPile: React.FC<LetterPileProps> = ({ onGameEnd }) => {
  const [availableLetters, setAvailableLetters] = useState<string[]>(LETTER_SET);
  const [words, setWords] = useState<string[]>(Array(6).fill(''));
  const [errorMessages, setErrorMessages] = useState<string[]>(Array(6).fill(''));
  const [finalLetter, setFinalLetter] = useState('');

  // Add useEffect to manage meta viewport tag
  React.useEffect(() => {
    // Store the original viewport meta tag content
    const originalViewport = document
      .querySelector('meta[name="viewport"]')
      ?.getAttribute('content');

    // Create or update viewport meta tag to prevent zooming AND scrolling adjustments
    const viewportMeta =
      document.querySelector('meta[name="viewport"]') || document.createElement('meta');
    viewportMeta.setAttribute('name', 'viewport');
    viewportMeta.setAttribute(
      'content',
      'width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover',
    );

    if (!document.querySelector('meta[name="viewport"]')) {
      document.head.appendChild(viewportMeta);
    }

    // Cleanup function to restore original viewport settings
    return () => {
      if (originalViewport) {
        viewportMeta.setAttribute('content', originalViewport);
      } else {
        viewportMeta.remove();
      }
    };
  }, []);

  const handleWordChange = (index: number, value: string) => {
    const newValue = value.toLowerCase();
    const oldWord = words[index];

    // Only proceed if the word is shorter (backspace) or if the new letter is available
    if (newValue.length > oldWord.length) {
      const addedLetter = newValue[newValue.length - 1];
      // Check if the added letter is available
      if (!availableLetters.includes(addedLetter)) {
        return; // Ignore the change if letter isn't available
      }
    }

    const newWords = [...words];
    newWords[index] = newValue;
    setWords(newWords);

    // Update available letters
    if (newValue.length > oldWord.length) {
      // Letter was added
      const addedLetter = newValue[newValue.length - 1];
      setAvailableLetters((current) =>
        current.filter((l, i) => {
          if (l === addedLetter) {
            const beforeThis = current.slice(0, i);
            if (!beforeThis.includes(addedLetter)) return false;
            return true;
          }
          return true;
        }),
      );
    } else if (newValue.length < oldWord.length) {
      // Letter was removed - Convert string to array of characters before spreading
      const removedLetters = oldWord.slice(newValue.length).split('');
      setAvailableLetters((current) => [...current, ...removedLetters]);
    }
  };

  const handleWordFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    // Prevent automatic scrolling on focus
    event.preventDefault();
    event.stopPropagation();

    // Get current scroll position
    const scrollPosition = window.scrollY;

    // Focus the input
    event.target.focus({ preventScroll: true });

    // Force the window to maintain its scroll position
    window.scrollTo(0, scrollPosition);
  };

  const checkWords = () => {
    // Check if final letter is provided
    if (!finalLetter) {
      setErrorMessages([...Array(6).fill(''), 'Please enter the final unused letter']);
      return;
    }

    // Check if the submitted letter is correct (case insensitive)
    if (finalLetter.toLowerCase() === CORRECT_LETTER) {
      onGameEnd(true);
    } else {
      onGameEnd(false);
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        p: 'clamp(10px, 2vw, 20px)',
        boxSizing: 'border-box',
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontSize: 'clamp(1.3rem, 2.6vw, 1.8rem)',
          fontFamily: "'Chakra Petch', sans-serif",
          mb: 2,
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        Letter Pile
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        {/* Letters and Words container */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 2, sm: 4 },
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {/* Letters section */}
          <Box
            sx={{
              flex: { xs: '0 0 auto', sm: 1 },
              maxWidth: { xs: '100%', sm: '50%' },
              position: { xs: 'sticky', sm: 'static' },
              top: 0,
              backgroundColor: '#fffdfa',
              zIndex: 2,
              pt: { xs: 1, sm: 0 },
              pb: { xs: 2, sm: 0 },
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontSize: 'clamp(1.1rem, 2.2vw, 1.3rem)',
                fontFamily: "'Chakra Petch', sans-serif",
                mb: 1,
                textAlign: 'center',
              }}
            >
              Letters
            </Typography>
            <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
              {availableLetters.map((letter, index) => (
                <Grid item key={index}>
                  <Paper
                    sx={{
                      p: { xs: '0.3rem', sm: 'clamp(0.4rem, 0.8vw, 0.6rem)' },
                      minWidth: { xs: '20px', sm: 'clamp(22px, 3.5vw, 30px)' },
                      height: { xs: '20px', sm: 'clamp(22px, 3.5vw, 30px)' },
                      textAlign: 'center',
                      fontFamily: "'Chakra Petch', sans-serif",
                      textTransform: 'uppercase',
                      fontSize: 'clamp(0.8rem, 1.6vw, 1rem)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {letter}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Words section */}
          <Box
            sx={{
              flex: { xs: '0 0 auto', sm: 1 },
              maxWidth: { xs: '100%', sm: '50%' },
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontSize: 'clamp(1.1rem, 2.2vw, 1.3rem)',
                fontFamily: "'Chakra Petch', sans-serif",
                mb: 1,
                textAlign: 'center',
              }}
            >
              Category: {SELECTED_CATEGORY.name}
            </Typography>
            <Grid container spacing={1} direction="column">
              {words.map((word, index) => (
                <Grid item key={index}>
                  <TextField
                    label={`Word ${index + 1}`}
                    value={word.toUpperCase()}
                    onChange={(e) => handleWordChange(index, e.target.value)}
                    error={!!errorMessages[index]}
                    helperText={errorMessages[index]}
                    onFocus={handleWordFocus}
                    sx={{
                      width: '100%',
                      position: 'relative',
                      '& .MuiInputLabel-root': {
                        fontSize: 'clamp(0.8rem, 1.6vw, 0.9rem)',
                        transform: 'translate(14px, 8px) scale(1)',
                        '&.MuiInputLabel-shrink': {
                          transform: 'translate(14px, -9px) scale(0.75)',
                        },
                        zIndex: 1,
                      },
                      '& .MuiInputBase-root': {
                        fontSize: 'clamp(0.8rem, 1.6vw, 0.9rem)',
                        textTransform: 'uppercase',
                      },
                      '& .MuiInputBase-input': {
                        padding: 'clamp(6px, 1.2vw, 10px)',
                        '-webkit-user-select': 'text',
                        '-moz-user-select': 'text',
                        '-ms-user-select': 'text',
                        'user-select': 'text',
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: "'Chakra Petch', sans-serif",
                      },
                    }}
                    inputProps={{
                      style: {
                        WebkitAppearance: 'none',
                        WebkitTextSizeAdjust: '100%',
                        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                      },
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        {/* Final Letter and Submit section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1.5,
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Chakra Petch', sans-serif",
              fontSize: 'clamp(1.1rem, 2.2vw, 1.3rem)',
              textAlign: 'center',
            }}
          >
            Final Letter
          </Typography>

          <TextField
            value={finalLetter.toUpperCase()}
            onChange={(e) => setFinalLetter(e.target.value)}
            error={!!errorMessages[6]}
            helperText={errorMessages[6]}
            inputProps={{ maxLength: 1 }}
            sx={{
              width: 'clamp(35px, 15%, 45px)',
              '& .MuiInputBase-input': {
                fontSize: 'clamp(0.8rem, 1.6vw, 0.9rem)',
                padding: 'clamp(6px, 1.2vw, 10px)',
                textAlign: 'center',
              },
            }}
          />

          <Button
            variant="contained"
            onClick={checkWords}
            sx={{
              fontFamily: "'Chakra Petch', sans-serif",
              fontSize: 'clamp(0.9rem, 1.8vw, 1.1rem)',
              padding: 'clamp(6px, 1.2vw, 10px)',
              backgroundColor: '#66cc00',
              width: 'clamp(120px, 35%, 200px)',
              '&:hover': {
                backgroundColor: '#5ab300',
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LetterPile;
