import React, { useState, useEffect, useRef } from 'react';
import '../styles/pacman.css';

interface Position {
  x: number;
  y: number;
}

interface Ghost {
  position: Position;
  direction: string;
  color: string;
  lastPosition: Position;
}

// Add new interface for power pellets
type PowerPellet = Position;

const GRID_SIZE = 19;

interface WallShape {
  x: number;
  y: number;
  width: number; // in grid units
  height: number; // in grid units
}

const WALL_SHAPES: WallShape[] = [
  // Left side walls
  {
    x: 1,
    y: 1,
    width: 2,
    height: 2,
  },
  {
    x: 1,
    y: 4,
    width: 2,
    height: 1,
  },
  {
    x: 5,
    y: 1,
    width: 3,
    height: 2,
  },
  {
    x: 5,
    y: 4,
    width: 1,
    height: 5,
  },
  {
    x: 5,
    y: 6,
    width: 3,
    height: 1,
  },
  {
    x: 0,
    y: 6,
    width: 4,
    height: 3,
  },
  // Center wall (moved one column left)
  {
    x: 9,
    y: 0,
    width: 1,
    height: 3,
  },
  // Add new vertical block in middle
  {
    x: 9,
    y: 4,
    width: 1,
    height: 3,
  },
  // Add new horizontal block
  {
    x: 7,
    y: 4,
    width: 5,
    height: 1,
  },
  // Mirrored walls for right side (all shifted one column left)
  {
    x: 16,
    y: 1,
    width: 2,
    height: 2,
  },
  {
    x: 16,
    y: 4,
    width: 2,
    height: 1,
  },
  {
    x: 11,
    y: 1,
    width: 3,
    height: 2,
  },
  {
    x: 13,
    y: 4,
    width: 1,
    height: 5,
  },
  {
    x: 11,
    y: 6,
    width: 3,
    height: 1,
  },
  {
    x: 15,
    y: 6,
    width: 4,
    height: 3,
  },
  // Bottom half mirrors (y positions are adjusted by adding distance from center)
  {
    x: 1,
    y: 16, // Mirrors y:1
    width: 2,
    height: 2,
  },
  {
    x: 1,
    y: 14, // Mirrors y:4
    width: 2,
    height: 1,
  },
  {
    x: 5,
    y: 16, // Mirrors y:1
    width: 3,
    height: 2,
  },
  {
    x: 5,
    y: 10, // Mirrors y:4
    width: 1,
    height: 5,
  },
  {
    x: 5,
    y: 12, // Mirrors y:6
    width: 3,
    height: 1,
  },
  {
    x: 0,
    y: 10, // Mirrors y:6
    width: 4,
    height: 3,
  },
  // Center mirrored walls
  {
    x: 9,
    y: 16, // Mirrors y:0
    width: 1,
    height: 3,
  },
  {
    x: 9,
    y: 12, // Mirrors y:4
    width: 1,
    height: 3,
  },
  {
    x: 7,
    y: 14, // Mirrors y:4
    width: 5,
    height: 1,
  },
  // Right side mirrors
  {
    x: 16,
    y: 16, // Mirrors y:1
    width: 2,
    height: 2,
  },
  {
    x: 16,
    y: 14, // Mirrors y:4
    width: 2,
    height: 1,
  },
  {
    x: 11,
    y: 16, // Mirrors y:1
    width: 3,
    height: 2,
  },
  {
    x: 13,
    y: 10, // Mirrors y:4
    width: 1,
    height: 5,
  },
  {
    x: 11,
    y: 12, // Mirrors y:6
    width: 3,
    height: 1,
  },
  {
    x: 15,
    y: 10, // Mirrors y:6
    width: 4,
    height: 3,
  },
];

const generateWallCoordinates = (shapes: WallShape[]): Position[] => {
  const coordinates: Position[] = [];
  shapes.forEach((shape) => {
    for (let x = shape.x; x < shape.x + shape.width; x++) {
      for (let y = shape.y; y < shape.y + shape.height; y++) {
        if (!coordinates.some((coord) => coord.x === x && coord.y === y)) {
          coordinates.push({ x, y });
        }
      }
    }
  });
  return coordinates;
};

const WALL_COORDINATES = generateWallCoordinates(WALL_SHAPES);

// Move isWall function before findPath
const isWall = (x: number, y: number): boolean => {
  return WALL_COORDINATES.some((wall) => wall.x === x && wall.y === y);
};

// Modify findPath to properly handle grid boundaries
const findPath = (start: Position, target: Position): Position | null => {
  const queue: { pos: Position; path: Position[] }[] = [{ pos: start, path: [] }];
  const visited = new Set<string>();

  while (queue.length > 0) {
    const { pos, path } = queue.shift()!;
    const posKey = `${pos.x},${pos.y}`;

    if (pos.x === target.x && pos.y === target.y) {
      return path[0] || pos;
    }

    if (visited.has(posKey)) continue;
    visited.add(posKey);

    const moves = [
      { x: pos.x + 1, y: pos.y },
      { x: pos.x - 1, y: pos.y },
      { x: pos.x, y: pos.y + 1 },
      { x: pos.x, y: pos.y - 1 },
    ];

    for (const move of moves) {
      // Apply wrap-around and boundary checks
      const wrappedMove = handleWrapAround(move);
      const moveKey = `${wrappedMove.x},${wrappedMove.y}`;

      // Check if the move is valid:
      // 1. Must be within y-axis bounds always
      // 2. Must be within x-axis bounds UNLESS it's in the tunnel row
      const isValidPosition =
        wrappedMove.y >= 0 &&
        wrappedMove.y < GRID_SIZE &&
        (isTunnelPosition(wrappedMove.y) || (wrappedMove.x >= 0 && wrappedMove.x < GRID_SIZE));

      if (!isWall(wrappedMove.x, wrappedMove.y) && !visited.has(moveKey) && isValidPosition) {
        queue.push({
          pos: wrappedMove,
          path: [...path, wrappedMove],
        });
      }
    }
  }

  return null;
};

// Add this helper function near the top of the file, after the interfaces
const isTunnelPosition = (y: number): boolean => {
  // Check if position is in the middle row (adjust based on your grid)
  return y === Math.floor(GRID_SIZE / 2);
};

const handleWrapAround = (position: Position): Position => {
  // Only allow wrap-around in the middle row
  if (!isTunnelPosition(position.y)) return position;

  // Wrap around logic only for x-axis
  if (position.x < 0) {
    return { ...position, x: GRID_SIZE - 1 };
  } else if (position.x >= GRID_SIZE) {
    return { ...position, x: 0 };
  }
  return position;
};

const FRAME_RATE = 16; // ~60fps
const MOVE_DELAY = 150;
const GHOST_MOVE_DELAY = 150;

// Add this helper to determine if a move is a tunnel wrap
const isTunnelWrap = (oldPos: Position, newPos: Position): boolean => {
  return (
    isTunnelPosition(oldPos.y) && isTunnelPosition(newPos.y) && Math.abs(oldPos.x - newPos.x) > 1
  );
};

const Pacman: React.FC<{ onGameEnd: (success: boolean) => void }> = ({ onGameEnd }) => {
  const [pacmanPos, setPacmanPos] = useState<Position>({ x: 0, y: 0 });
  const pacmanPosRef = useRef(pacmanPos);
  useEffect(() => {
    pacmanPosRef.current = pacmanPos;
  }, [pacmanPos]);

  const [direction, setDirection] = useState<string>('right');
  const directionRef = useRef(direction);
  useEffect(() => {
    directionRef.current = direction;
  }, [direction]);

  const [dots, setDots] = useState<Position[]>([]);
  const dotsRef = useRef(dots);
  useEffect(() => {
    dotsRef.current = dots;
  }, [dots]);

  const [ghosts, setGhosts] = useState<Ghost[]>([]);
  const ghostsRef = useRef(ghosts);
  useEffect(() => {
    ghostsRef.current = ghosts;
  }, [ghosts]);

  const [lives, setLives] = useState(3);
  const livesRef = useRef(lives);
  useEffect(() => {
    livesRef.current = lives;
  }, [lives]);

  const [powerMode, setPowerMode] = useState(false);
  const powerModeRef = useRef(powerMode);
  useEffect(() => {
    powerModeRef.current = powerMode;
  }, [powerMode]);

  const [score, setScore] = useState(0);
  const [powerPellets, setPowerPellets] = useState<PowerPellet[]>([
    { x: 0, y: 0 }, // Top left
    { x: 17, y: 0 }, // Top right (changed from 18)
    { x: 0, y: 17 }, // Bottom left (changed from 18)
    { x: 17, y: 17 }, // Bottom right (changed from 18,18)
  ]);
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [lastMoveTime, setLastMoveTime] = useState<number>(0);

  // Add a new ref for ghost movement timing
  const lastGhostMoveTime = useRef<number>(0);

  // Initialize game board
  useEffect(() => {
    initializeGame();
  }, []);

  const initializeGame = () => {
    // Initialize dots (excluding wall positions)
    const initialDots: Position[] = [];
    for (let x = 0; x <= GRID_SIZE - 1; x++) {
      for (let y = 0; y <= GRID_SIZE - 1; y++) {
        if (!isWall(x, y)) {
          initialDots.push({ x, y });
        }
      }
    }
    setDots(initialDots);

    // Initialize ghosts with safe positions
    const safeGhosts: Ghost[] = [
      {
        position: { x: 17, y: 17 },
        lastPosition: { x: 17, y: 17 },
        direction: 'left',
        color: 'red',
      },
      {
        position: { x: 17, y: 0 },
        lastPosition: { x: 17, y: 0 },
        direction: 'right',
        color: 'pink',
      },
      { position: { x: 0, y: 17 }, lastPosition: { x: 0, y: 17 }, direction: 'up', color: 'cyan' },
    ].filter((ghost) => !isWall(ghost.position.x, ghost.position.y));

    setGhosts(safeGhosts);

    // Filter out power pellets that overlap with walls
    setPowerPellets((prev) => prev.filter((pellet) => !isWall(pellet.x, pellet.y)));

    // Reset power mode
    setPowerMode(false);
  };

  // Update the ghost movement effect to match Pacman's continuous movement
  useEffect(() => {
    const moveGhosts = () => {
      const currentTime = Date.now();
      if (currentTime - lastGhostMoveTime.current < GHOST_MOVE_DELAY) return;

      setGhosts((prevGhosts) => {
        return prevGhosts.map((ghost) => {
          const oldPosition = ghost.position;
          let newPosition: Position;

          if (powerModeRef.current) {
            // Power mode movement
            const possibleMoves = [
              { x: ghost.position.x + 1, y: ghost.position.y },
              { x: ghost.position.x - 1, y: ghost.position.y },
              { x: ghost.position.x, y: ghost.position.y + 1 },
              { x: ghost.position.x, y: ghost.position.y - 1 },
            ]
              .map((pos) => {
                // Apply wrap-around for tunnel positions
                if (isTunnelPosition(pos.y)) {
                  return handleWrapAround(pos);
                }
                return pos;
              })
              .filter((pos) => {
                const isValidPosition =
                  pos.y >= 0 &&
                  pos.y < GRID_SIZE &&
                  (isTunnelPosition(pos.y) || (pos.x >= 0 && pos.x < GRID_SIZE));

                return isValidPosition && !isWall(pos.x, pos.y);
              });

            // Sort moves by distance (running away from Pacman)
            newPosition =
              possibleMoves.sort((a, b) => {
                const distA =
                  Math.abs(a.x - pacmanPosRef.current.x) + Math.abs(a.y - pacmanPosRef.current.y);
                const distB =
                  Math.abs(b.x - pacmanPosRef.current.x) + Math.abs(b.y - pacmanPosRef.current.y);
                return distB - distA; // Sort descending to move away from Pacman
              })[0] || ghost.position;

            // Apply wrap-around one more time to ensure proper tunnel behavior
            newPosition = handleWrapAround(newPosition);
          } else {
            // Normal movement
            const nextMove = findPath(ghost.position, pacmanPosRef.current);
            newPosition = nextMove ? handleWrapAround(nextMove) : ghost.position;
          }

          const isValidPosition =
            newPosition.y >= 0 &&
            newPosition.y < GRID_SIZE &&
            (isTunnelPosition(newPosition.y) || (newPosition.x >= 0 && newPosition.x < GRID_SIZE));

          if (isValidPosition && !isWall(newPosition.x, newPosition.y)) {
            lastGhostMoveTime.current = currentTime;
            return {
              ...ghost,
              position: newPosition,
              lastPosition: oldPosition,
            };
          }
          return ghost;
        });
      });
    };

    // Set up interval for continuous ghost movement checks
    const intervalId = setInterval(moveGhosts, FRAME_RATE);
    return () => clearInterval(intervalId);
  }, []); // Remove moveGhosts from dependencies since it's defined inside

  // Modify keyboard handling
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Prevent default for game controls
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'w', 'a', 's', 'd'].includes(e.key)) {
        e.preventDefault();
        setActiveKey(e.key);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (activeKey === e.key) {
        setActiveKey(null);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [activeKey]);

  // Modify the movement effect for Pac-Man
  useEffect(() => {
    if (!activeKey) return;

    const MOVE_DELAY = 150; // Milliseconds between moves

    const movePlayer = () => {
      const currentTime = Date.now();
      if (currentTime - lastMoveTime < MOVE_DELAY) return;

      const newPos = { ...pacmanPosRef.current };
      const oldPos = { ...pacmanPosRef.current }; // Store old position

      switch (activeKey) {
        case 'ArrowUp':
        case 'w':
          newPos.y--;
          setDirection('up');
          break;
        case 'ArrowDown':
        case 's':
          newPos.y++;
          setDirection('down');
          break;
        case 'ArrowLeft':
        case 'a':
          newPos.x--;
          setDirection('left');
          break;
        case 'ArrowRight':
        case 'd':
          newPos.x++;
          setDirection('right');
          break;
      }

      // Apply wrap-around before wall checking
      const wrappedPos = handleWrapAround(newPos);

      // Check if this is a tunnel wrap
      const isTunnel = isTunnelWrap(oldPos, wrappedPos);

      const isValidPosition =
        wrappedPos.y >= 0 &&
        wrappedPos.y < GRID_SIZE &&
        (isTunnelPosition(wrappedPos.y) || (wrappedPos.x >= 0 && wrappedPos.x < GRID_SIZE));

      if (!isWall(wrappedPos.x, wrappedPos.y) && isValidPosition) {
        setPacmanPos(wrappedPos);
        // Set data attribute for tunnel wrapping
        const pacmanElement = document.querySelector('.pacman');
        if (pacmanElement) {
          pacmanElement.setAttribute('data-tunnel-wrap', isTunnel.toString());
        }
        setLastMoveTime(currentTime);

        // Check for dot collection
        setDots((prevDots) =>
          prevDots.filter((dot) => !(dot.x === wrappedPos.x && dot.y === wrappedPos.y)),
        );

        // Power pellet collection
        setPowerPellets((prevPellets) => {
          const newPellets = prevPellets.filter(
            (pellet) => !(pellet.x === wrappedPos.x && pellet.y === wrappedPos.y),
          );
          if (newPellets.length < prevPellets.length) {
            setPowerMode(true);
            setTimeout(() => setPowerMode(false), 10000);
            setScore((prev) => prev + 50);
          }
          return newPellets;
        });
      }
    };

    // Set up interval for continuous movement
    const intervalId = setInterval(movePlayer, 16);

    return () => clearInterval(intervalId);
  }, [activeKey, lastMoveTime]);

  // Remove the ghost movement from the game loop
  useEffect(() => {
    const gameLoop = setInterval(() => {
      // Use refs to access the latest state
      const currentGhosts = ghostsRef.current;
      const currentPacmanPos = pacmanPosRef.current;
      const currentLives = livesRef.current;
      const currentPowerMode = powerModeRef.current;
      const currentDots = dotsRef.current;

      // Check for collisions with ghosts
      currentGhosts.forEach((ghost) => {
        if (ghost.position.x === currentPacmanPos.x && ghost.position.y === currentPacmanPos.y) {
          if (currentPowerMode) {
            // Remove ghost and respawn it after a delay
            setGhosts((prev) =>
              prev.filter(
                (g) => g.position.x !== ghost.position.x || g.position.y !== ghost.position.y,
              ),
            );
            setScore((prev) => prev + 200);

            // Respawn ghost after 5 seconds
            setTimeout(() => {
              setGhosts((prev) => [
                ...prev,
                {
                  ...ghost,
                  position: { x: GRID_SIZE / 2, y: GRID_SIZE / 2 },
                },
              ]);
            }, 5000);
          } else {
            setLives((prev) => prev - 1);
            if (currentLives <= 1) {
              onGameEnd(false);
            }
            // Reset Pac-Man's position
            setPacmanPos({ x: 0, y: 0 });
          }
        }
      });

      // Check win condition
      if (currentDots.length === 0) {
        onGameEnd(true);
      }
    }, MOVE_DELAY);

    return () => clearInterval(gameLoop);
  }, [onGameEnd]);

  return (
    <div className="pacman-game">
      <div className="game-info">
        <span>Score: {score}</span>
        <span>Lives: {lives}</span>
      </div>
      <div className="game-board">
        {/* Update all positioning calculations to account for element size */}
        {WALL_SHAPES.map((wall, index) => (
          <div
            key={`wall-${index}`}
            className="wall"
            style={{
              left: `${(wall.x * 100) / GRID_SIZE}%`, // Changed from wall.x * 5
              top: `${(wall.y * 100) / GRID_SIZE}%`, // Changed from wall.y * 5
              width: `${(wall.width * 100) / GRID_SIZE}%`, // Changed from wall.width * 5
              height: `${(wall.height * 100) / GRID_SIZE}%`, // Changed from wall.height * 5
            }}
          />
        ))}

        {powerPellets.map((pellet, index) => (
          <div
            key={`pellet-${index}`}
            className="dot power-pellet"
            style={{
              left: `${(pellet.x * 100) / GRID_SIZE}%`, // Changed from pellet.x * 5
              top: `${(pellet.y * 100) / GRID_SIZE}%`, // Changed from pellet.y * 5
            }}
          />
        ))}

        {dots.map((dot, index) => (
          <div
            key={`dot-${index}`}
            className="dot"
            style={{
              left: `${(dot.x * 100) / GRID_SIZE}%`, // Changed from dot.x * 5
              top: `${(dot.y * 100) / GRID_SIZE}%`, // Changed from dot.y * 5
            }}
          />
        ))}

        {ghosts.map((ghost, index) => (
          <div
            key={`ghost-${index}`}
            className={`ghost ${ghost.color} ${powerMode ? 'vulnerable' : ''}`}
            data-tunnel-wrap={isTunnelWrap(ghost.lastPosition, ghost.position)}
            style={{
              left: `${(ghost.position.x * 100) / GRID_SIZE}%`,
              top: `${(ghost.position.y * 100) / GRID_SIZE}%`,
            }}
          />
        ))}

        <div
          className={`pacman ${direction}`}
          data-tunnel={isTunnelPosition(pacmanPos.y)}
          style={{
            left: `${(pacmanPos.x * 100) / GRID_SIZE}%`,
            top: `${(pacmanPos.y * 100) / GRID_SIZE}%`,
          }}
        />
      </div>
    </div>
  );
};

export default Pacman;
