import React, { useState, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';

// Types
interface LetterMapping {
  [key: string]: string;
}

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  max-height: inherit;
  overflow: hidden;
`;

const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;

  /* Hide scrollbar but keep functionality */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: clamp(10px, 2vw, 20px);
  gap: clamp(5px, 1vh, 10px);
  flex: 1;
  overflow-y: auto;
`;

const Text = styled.p`
  font-family: 'Courier New', monospace;
  font-size: clamp(0.9rem, 1.8vw, 1.2rem);
  line-height: 1.6;
  white-space: pre-wrap;
  text-align: center;
  width: 100%;
  padding: 0 clamp(15px, 3vw, 30px);
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: clamp(0.85rem, 1.6vw, 1.1rem);
    padding: 0 clamp(12px, 2.5vw, 25px);
  }

  @media (max-width: 480px) {
    font-size: clamp(0.8rem, 1.4vw, 1rem);
    padding: 0 clamp(10px, 2vw, 20px);
  }
`;

const Description = styled.p`
  text-align: center;
  margin: 0 0 clamp(0.2em, 0.4vh, 0.3em) 0;
  font-size: clamp(1.1rem, 2.2vw, 1.4rem);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 clamp(20px, 4vw, 40px);
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: clamp(0.95rem, 2vw, 1.2rem);
    margin-bottom: clamp(0.15rem, 0.3vh, 0.25rem);
    padding: 0 clamp(15px, 3vw, 30px);
  }

  @media (max-width: 480px) {
    font-size: clamp(0.9rem, 1.8vw, 1.1rem);
    margin-bottom: clamp(0.1rem, 0.2vh, 0.2rem);
    padding: 0 clamp(10px, 2vw, 20px);
  }
`;

const HighlightedText = styled.span`
  font-weight: bold;
  color: #66cc00;
  transition: color 0.2s ease;

  @media (max-width: 768px) {
    font-weight: 600;
  }
`;

const Keyboard = styled.div`
  display: none; // Hidden by default (for desktop)

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: min(2px, 0.5vh);
    width: 100%;
    max-width: min(600px, 95%);
    padding: min(3px, 0.8vw);
    box-sizing: border-box;
    margin: 0 auto;
  }
`;

const KeyboardRow = styled.div`
  display: flex;
  gap: min(2px, 0.5vw);
  justify-content: center;
  flex-shrink: 0;
  min-height: min(30px, 5vh);
`;

const Key = styled.button<{ isHeld: boolean }>`
  font-size: 0.9em;
  width: clamp(28px, 5vw, 40px);
  height: clamp(28px, 5vw, 40px);
  padding: 0;
  background-color: ${(props) => (props.isHeld ? '#66cc00' : '#fff')};
  color: ${(props) => (props.isHeld ? '#fff' : '#333')};
  border: min(2px, 0.3vw) solid #333;
  cursor: pointer;
  border-radius: min(4px, 0.8vw);
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    transform: scale(0.95);
  }
`;

const Title = styled.h2`
  text-align: center;
  margin: 0 0 0.5em 0;
  font-size: clamp(1.5rem, 3vw, 2rem);
`;

const Enigma: React.FC<{
  onGameEnd: (success: boolean) => void;
  gameEnded?: boolean;
}> = ({ onGameEnd, gameEnded }) => {
  const originalText =
    'all save one there waiting silent and still in the space before the gate sat gandalf upon shadowfax shadowfax who alone among the free horses of the earth endured the terror unmoving steadfast as a graven image in rath dinen you cannot enter here said gandalf and the huge shadow halted go back to the abyss prepared for you go back fall into the nothingness that awaits you and your master go the black rider flung back his hood and behold he had a kingly crown and yet upon no head visible was it set the red fires shone between it and the mantled shoulders vast and dark from a mouth unseen there came a deadly laughter old fool he said old fool this is my hour do you not know death when you see it die now and curse in vain and with that he lifted high his sword and flames ran down the blade';

  const [encodedText, setEncodedText] = useState('');
  const [heldKey, setHeldKey] = useState<string | null>(null);

  // Create initial encoding
  useEffect(() => {
    // Get unique letters from the original text (excluding spaces)
    const usedLetters = Array.from(new Set(originalText.replace(/\s/g, '').split(''))).sort();

    // Create shuffled version of only the used letters
    const shuffled = [...usedLetters].sort(() => Math.random() - 0.5);

    const mapping: LetterMapping = {};
    usedLetters.forEach((letter, index) => {
      mapping[letter] = shuffled[index];
    });

    const encoded = originalText
      .split('')
      .map((char) => (char === ' ' ? ' ' : mapping[char]))
      .join('');

    setEncodedText(encoded);
  }, []);

  // Handle key presses
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();
      if (key.length === 1 && /[a-z]/.test(key)) {
        if (heldKey && heldKey !== key) {
          setEncodedText((prev) => {
            const newText = prev
              .split('')
              .map((char) => {
                if (char === heldKey) return key;
                if (char === key) return heldKey;
                return char;
              })
              .join('');

            // Check if the puzzle is solved
            if (newText === originalText) {
              onGameEnd(true);
            }

            return newText;
          });
          setHeldKey(null);
        } else {
          setHeldKey(key);
        }
      }
    },
    [heldKey, originalText, onGameEnd],
  );

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();
      if (key === heldKey) {
        setHeldKey(null);
      }
    },
    [heldKey],
  );

  // Set up key listeners
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  // Add effect to handle timer expiration
  useEffect(() => {
    if (gameEnded) {
      // Timer ran out - this is a fail condition
      onGameEnd(false);
    }
  }, [gameEnded, onGameEnd]);

  // Render text with highlighting
  const renderText = () => {
    if (!heldKey) return encodedText;

    return encodedText
      .split('')
      .map((char, index) => {
        if (char === heldKey) {
          return <HighlightedText key={index}>{char}</HighlightedText>;
        }
        return char;
      })
      .map((element, index) =>
        typeof element === 'string' ? element : React.cloneElement(element, { key: index }),
      );
  };

  // Split keyboard into rows for mobile
  const qwertyLayout = ['QWERTYUIOP', 'ASDFGHJKL', 'ZXCVBNM'];

  // Handle virtual keyboard press
  const handleVirtualKeyPress = (key: string) => {
    if (heldKey && heldKey !== key.toLowerCase()) {
      // Simulate the key swap
      setEncodedText((prev) => {
        const newText = prev
          .split('')
          .map((char) => {
            if (char === heldKey) return key.toLowerCase();
            if (char === key.toLowerCase()) return heldKey;
            return char;
          })
          .join('');

        // Check if the puzzle is solved
        if (newText === originalText) {
          onGameEnd(true);
        }

        return newText;
      });
      setHeldKey(null);
    } else {
      setHeldKey(key.toLowerCase());
    }
  };

  return (
    <Container>
      <ScrollableContent>
        <TextContent>
          <Title>Enigma</Title>
          <Description>
            Hold (or tap on mobile) a letter on your keyboard to highlight it, then press another to
            swap.
          </Description>
          <Text>{renderText()}</Text>
        </TextContent>

        <Keyboard>
          {qwertyLayout.map((row, rowIndex) => (
            <KeyboardRow key={rowIndex}>
              {row.split('').map((key) => (
                <Key
                  key={key}
                  isHeld={heldKey === key.toLowerCase()}
                  onClick={() => handleVirtualKeyPress(key)}
                >
                  {key}
                </Key>
              ))}
            </KeyboardRow>
          ))}
        </Keyboard>
      </ScrollableContent>
    </Container>
  );
};

export default Enigma;
