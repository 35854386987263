import React from 'react';
import DescriptionCard from '../DescriptionCard';

const EnigmaDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <DescriptionCard
      title="Enigma"
      description={`**How to play:** Your challenge is to decode the encrypted message. Encoded with random letters is a hidden English phrase. The message can be decoded by swapping a series of letters. To swap two letters in the message, simply press and release the letters on your keyboard. E.g. pressing L and K at the same time will switch the position of these two letters. 

⚠️  This is a timed challenge. Refreshing or leaving and re-entering the page will result in progress and time lost. Lock in, legend.  

**Win/Lose Conditions**: Decode the message to pass the challenge and win immunity. Failure to decode the message, will result in your featuring on the wheel tonight.

**Estimated time required**: 10-15 minutes

**Fun-rating:** 10/10`}
      onStart={onStart}
    />
  );
};

export default EnigmaDescription;
