import React from 'react';
import DescriptionCard from '../DescriptionCard';

const SquaredleDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <DescriptionCard
      title="Squaredle"
      description={`**How to play:** Your challenge is to find as many words as possible using a 5x5 letter grid. Each box contains a letter. Form words by tracing a path through adjacent letters. Letters can be connected in any direction—horizontally, vertically, diagonally, and in zigzag patterns.

Words must be at least 4 letters long. You can submit a word by either tracing your finger or mouse across connected letters or by typing it out (desktop only). Each valid word earns you 1 point. When you're ready to stop searching for words, select **'Abandon All Hope'** to finalise your score. Make sure to submit your score before 9 PM AEDT.

⚠️  This is not a timed challenge. However, if you refresh or leave the game, your score will be submitted and you may not be able to retry.

**Win/Lose Conditions**: To earn immunity, you need to score 70% or more of today's highest score. For example, if the highest number of words found is 10, you need to find 7 words to earn immunity. You won't know if you've earned immunity until the quiz closes at 9PM (AEDT). Failure to score 70% or above of the highest score found will result in your placement on tonight's wheel.

**Estimated time required**: How many minutes are in a day?

**Fun-rating:** 10/10`}
      onStart={onStart}
    />
  );
};

export default SquaredleDescription;
