import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gameComponents } from '../config/GameMapping';
import { GameStatus } from '../api';

interface GameWrapperProps {
  onGameEnd: (success: boolean, isPending?: boolean, bonusTime?: number) => void;
  onStartGame: () => void;
  showDescription: boolean;
  setShowDescription: (show: boolean) => void;
  gameStatus: GameStatus | null;
  gameStarted: boolean;
  gameEnded: boolean;
  setGameEnded: (ended: boolean) => void;
  gameResult: 'PASS' | 'FAIL' | 'PENDING' | null;
}

const GameWrapper: React.FC<GameWrapperProps> = ({
  onGameEnd,
  onStartGame,
  showDescription,
  setShowDescription,
  gameStatus,
  gameStarted,
  gameEnded,
  setGameEnded,
  gameResult,
}) => {
  const { gameName } = useParams<{ gameName: string }>();
  const selectedGameComponent = gameName ? gameComponents[gameName] : null;

  const GameComponent = selectedGameComponent?.game;
  const DescriptionComponent = selectedGameComponent?.description;

  // Add useEffect to handle forced fail state
  useEffect(() => {
    if (gameStatus && !gameStatus.canPlay && gameStatus.previousResult === 'fail' && !gameEnded) {
      onGameEnd(false);
    }
  }, [gameStatus, onGameEnd, gameEnded]);

  // Function to get display text for the result
  const getResultDisplayText = (result: 'PASS' | 'FAIL' | 'PENDING' | null) => {
    if (result === 'PENDING') return 'SUBMITTED';
    return result;
  };

  return (
    <div className="Game-container">
      {showDescription && gameStatus?.canPlay && DescriptionComponent && (
        <DescriptionComponent onStart={onStartGame} />
      )}
      {gameStarted && !gameEnded && GameComponent && <GameComponent onGameEnd={onGameEnd} />}
      {gameName && !selectedGameComponent && <div>Game not found: {gameName}</div>}
      {(gameEnded || (gameStatus && !gameStatus.canPlay)) && (
        <div className="Game-result">
          <div className={`Result-text ${gameResult}`}>{getResultDisplayText(gameResult)}</div>
          {gameStatus?.allowRetries && gameResult === 'FAIL' && (
            <button
              className="Start-button"
              onClick={() => {
                setGameEnded(false);
                setShowDescription(true);
              }}
            >
              Try Again
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default GameWrapper;
