import React, { useState, useEffect, useRef } from 'react';

interface WordleProps {
  onGameEnd: (success: boolean) => void;
}

const Wordle: React.FC<WordleProps> = ({ onGameEnd }) => {
  const WORD_LIST = [
    'ELVES',
    'HYDRA',
    'QUILT',
    'EMBER',
    'BIRCH',
    'LYRIC',
    'GUSTO',
    'JOLLY',
    'FJORD',
    'ZESTY',
    'VEXED',
    'THYME',
    'DWARF',
    'GUAVA',
    'PIXIE',
    'BLUFF',
    'VIVID',
    'FLARE',
    'SHOCK',
    'CHILL',
    'DRIFT',
    'AMBER',
    'CABIN',
    'DAUNT',
    'EBONY',
    'GAUZE',
    'HAVEN',
    'ICILY',
    'JESTS',
    'KNEAD',
    'LODGE',
    'NAVEL',
    'ORBIT',
    'PRONE',
    'QUIRK',
    'ROUGE',
    'SMELT',
    'TONIC',
    'UNTIE',
    'VAULT',
    'WHINE',
    'YEARN',
    'ZONED',
  ];

  const [word, setWord] = useState<string>('');
  const [guesses, setGuesses] = useState<string[]>([]);
  const [currentGuess, setCurrentGuess] = useState<string>('');
  const [gameOver, setGameOver] = useState<boolean>(false);
  const [visibleRows, setVisibleRows] = useState<number[]>([0]);

  const gameRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const randomWord = WORD_LIST[Math.floor(Math.random() * WORD_LIST.length)];
    setWord(randomWord);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (guesses.length > 0) {
      setVisibleRows([guesses.length - 1, guesses.length]);
    }
  }, [guesses]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (gameOver) return;

    const value = event.target.value.toUpperCase();
    if (value.length <= 5) {
      setCurrentGuess(value);
    }
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (gameOver) return;

    if (event.key === 'Enter') {
      if (currentGuess.length !== 5) return;

      const newGuesses = [...guesses, currentGuess];
      setGuesses(newGuesses);
      setCurrentGuess('');
      if (inputRef.current) {
        inputRef.current.value = '';
      }

      if (currentGuess === word) {
        setGameOver(true);
        onGameEnd(true);
      } else if (newGuesses.length === 6) {
        setGameOver(true);
        onGameEnd(false);
      }
    }
  };

  const renderLetterBox = (
    letter: string,
    index: number,
    isCurrentGuess: boolean,
    isFadingOut: boolean,
  ) => {
    if (!letter || isCurrentGuess) {
      return renderBox(letter, 'white', index, isCurrentGuess, isFadingOut);
    }

    const currentGuessWord = guesses[guesses.length - 1];

    // First pass: count target letters and exact matches
    const targetLetterCount = word.split('').filter((l) => l === letter).length;
    const exactMatches = currentGuessWord
      .split('')
      .filter((l, i) => l === letter && l === word[i]).length;

    // If this is an exact match, always show green
    if (letter === word[index]) {
      return renderBox(letter, '#66cc00', index, isCurrentGuess, isFadingOut);
    }

    // Count remaining available highlights after exact matches
    const remainingHighlights = targetLetterCount - exactMatches;

    // If no remaining highlights available, show gray
    if (remainingHighlights <= 0) {
      return renderBox(letter, 'lightgray', index, isCurrentGuess, isFadingOut);
    }

    // Count yellow positions before this index
    const yellowPositionsBeforeThis = currentGuessWord
      .slice(0, index)
      .split('')
      .filter((l, i) => l === letter && word.includes(l) && l !== word[i]).length;

    // Show yellow only if we haven't exceeded remaining highlights
    const shouldBeYellow = word.includes(letter) && yellowPositionsBeforeThis < remainingHighlights;

    return renderBox(
      letter,
      shouldBeYellow ? 'yellow' : 'lightgray',
      index,
      isCurrentGuess,
      isFadingOut,
    );
  };

  const renderBox = (
    letter: string,
    backgroundColor: string,
    index: number,
    isCurrentGuess: boolean,
    isFadingOut: boolean,
  ) => (
    <span
      key={index}
      style={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'clamp(40px, 11vw, 60px)',
        height: 'clamp(40px, 11vw, 60px)',
        border: '2px solid black',
        borderRadius: '8px',
        margin: 'clamp(2px, 0.5vw, 4px)',
        fontSize: 'clamp(20px, 5vw, 32px)',
        fontWeight: 'bold',
        verticalAlign: 'top',
        backgroundColor,
        color: backgroundColor === '#66cc00' ? 'white' : 'black',
        opacity: isFadingOut ? 0 : 1,
        transition: 'opacity 0.5s ease-out',
      }}
    >
      {letter}
    </span>
  );

  // Update containerStyle
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column' as const,
    width: '100%',
    height: '100%',
    margin: '0 auto',
    padding: 'clamp(10px, 2vw, 20px)',
    boxSizing: 'border-box' as const,
    overflow: 'auto', // Add scrolling if needed
  };

  // Update gridStyle
  const gridStyle = {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    gap: 'clamp(2px, 1vh, 5px)',
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    flex: '1',
    minHeight: 'min-content', // Ensure it can shrink if needed
  };

  return (
    <div ref={gameRef} style={{ ...containerStyle }} onClick={() => inputRef.current?.focus()}>
      <input
        ref={inputRef}
        type="text"
        value={currentGuess}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        style={{
          opacity: 0,
          position: 'fixed', // Change from absolute to fixed
          top: 0,
          left: 0,
          height: 0,
          width: 0,
          zIndex: -1,
        }}
        autoComplete="off"
        autoCapitalize="off"
        autoCorrect="off"
        spellCheck="false"
      />
      <h2
        style={{
          fontSize: 'clamp(2rem, 6vw, 3rem)',
          fontFamily: "'Chakra Petch', sans-serif",
          margin: '0 0 clamp(1.5rem, 3vh, 2.2rem) 0', // Updated margin
          color: '#282c34',
          flexShrink: 0, // Prevent title from shrinking
        }}
      >
        Wordle
      </h2>
      <div style={gridStyle}>
        {[...Array(6)].map((_, rowIndex) => (
          <div
            key={rowIndex}
            style={{
              display: 'flex',
              justifyContent: 'center',
              opacity: gameOver
                ? rowIndex > guesses.length
                  ? 0
                  : 1
                : visibleRows.includes(rowIndex)
                ? 1
                : 0.3,
              transition: 'opacity 0.5s ease-out',
              margin: '0 auto',
              width: '100%',
            }}
          >
            {[...Array(5)].map((_, colIndex) => {
              const isCurrentGuess = rowIndex === guesses.length;
              const letter = isCurrentGuess
                ? currentGuess[colIndex] || ''
                : guesses[rowIndex]?.[colIndex] || '';
              const isFadingOut = !visibleRows.includes(rowIndex);
              return renderLetterBox(letter, colIndex, isCurrentGuess, isFadingOut);
            })}
          </div>
        ))}
      </div>
      {gameOver && (
        <div
          style={{
            marginTop: 'clamp(1rem, 2vh, 1.5rem)',
            flexShrink: 0, // Prevent result message from shrinking
            textAlign: 'center',
          }}
        >
          {word === guesses[guesses.length - 1] ? 'You won!' : `Game over! The word was ${word}`}
        </div>
      )}
    </div>
  );
};

export default Wordle;
