import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { completeWildVotesGame } from '../api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: clamp(10px, 2vw, 20px);
  box-sizing: border-box;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  position: relative;

  /* Add scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    display: block;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
    transition: background 0.2s;
  }

  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.5);
  }

  &:hover::-webkit-scrollbar-thumb:hover {
    background: rgba(85, 85, 85, 0.7);
  }
`;

const GameContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: min(900px, 95%);
  margin: 0 auto;
  padding-top: clamp(8px, 1.5vh, 16px);
`;

const AnimalGrid = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: clamp(12px, 2vw, 24px);
  margin-bottom: clamp(16px, 3vh, 24px);
  padding: 0 clamp(10px, 2vw, 20px);
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: clamp(16px, 3vh, 32px);
    width: auto;
    margin-bottom: 0;
  }
`;

const DraggableAnimal = styled.div`
  cursor: grab;
  width: clamp(100px, 15vw, 160px);
  height: clamp(100px, 15vw, 160px);
  flex-shrink: 0;
  touch-action: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;

  @media (max-width: 768px) {
    width: clamp(90px, 22vw, 180px);
    height: clamp(90px, 22vw, 180px);
  }
`;

const AnimalImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  pointer-events: none;
`;

const DropZoneGrid = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: clamp(16px, 3vw, 24px);
  margin-bottom: clamp(16px, 3vh, 24px);
  padding: 0 clamp(10px, 2vw, 20px);
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: clamp(20px, 3vh, 28px);
  }
`;

const DropZone = styled.div`
  width: clamp(100px, 15vw, 160px);
  height: clamp(100px, 15vw, 160px);
  border: 2px dashed #666;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  touch-action: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;

  @media (max-width: 768px) {
    width: clamp(90px, 22vw, 180px);
    height: clamp(90px, 22vw, 180px);
  }
`;

const TokenControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: clamp(4px, 1.2vw, 12px);
  width: clamp(100px, 15vw, 160px);

  @media (max-width: 768px) {
    width: clamp(90px, 22vw, 180px);
  }
`;

const TokenCount = styled.span`
  font-size: clamp(1.4rem, 3vw, 2rem);
  font-weight: bold;
  margin: clamp(4px, 1.5vh, 12px) 0;

  @media (max-width: 480px) {
    font-size: clamp(1.2rem, 2.8vw, 1.8rem);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: clamp(2px, 0.8vw, 8px);
`;

const TokenButton = styled.button<{ disabled?: boolean; forceEnabled?: boolean }>`
  padding: clamp(8px, 1.2vw, 12px) clamp(12px, 1.2vw, 16px);
  border: none;
  border-radius: 4px;
  background: ${(props) => (props.disabled && !props.forceEnabled ? '#666' : '#4a90e2')};
  color: white;
  cursor: ${(props) => (props.disabled && !props.forceEnabled ? 'not-allowed' : 'pointer')};
  width: clamp(44px, 8vw, 60px);
  font-size: max(16px, clamp(1.1rem, 2.2vw, 1.4rem));
  font-weight: bold;
  touch-action: manipulation;
  min-height: 44px;
  min-width: 44px;

  &:hover:not(:disabled) {
    background: #357abd;
  }

  @media (max-width: 480px) {
    width: clamp(44px, 8vw, 60px);
    font-size: max(16px, clamp(1.1rem, 2.2vw, 1.4rem));
  }
`;

const RemainingTokens = styled.div<{ hasSubmitButton?: boolean }>`
  font-size: clamp(1.2rem, 2.5vw, 1.8rem);
  padding: clamp(8px, 1.5vh, 16px);
  text-align: center;
  font-weight: 600;
  color: #333;
  background: transparent;
  position: sticky;
  top: ${(props) => (props.hasSubmitButton ? 'clamp(60px, 8vh, 80px)' : '0')};
  z-index: 10;

  @media (max-width: 480px) {
    font-size: clamp(1.1rem, 2.2vw, 1.6rem);
  }
`;

const SubmitButton = styled(TokenButton)`
  width: auto;
  padding: clamp(12px, 2vw, 24px) clamp(24px, 4vw, 48px);
  font-size: clamp(1.2rem, 2.2vw, 1.8rem);
  margin: clamp(12px, 2.5vh, 32px) auto 0;
  white-space: nowrap;
  min-width: clamp(160px, 25vw, 280px);
  max-width: min(85%, 400px);
  background: #66cc00;

  &:hover:not(:disabled) {
    background: #5ab800;
  }

  @media (max-width: 480px) {
    padding: clamp(8px, 1.5vw, 16px) clamp(16px, 3vw, 32px);
    font-size: clamp(1rem, 2vw, 1.4rem);
    min-width: clamp(140px, 22vw, 240px);
  }
`;

const MobileLayout = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: clamp(20px, 4vw, 40px);
  margin: 0 auto;

  @media (min-width: 769px) {
    flex-direction: column;
  }
`;

const DropZoneRow = styled.div`
  display: flex;
  align-items: center;
  gap: clamp(12px, 2vw, 20px);

  @media (min-width: 769px) {
    flex-direction: column;
  }
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 11;
  padding: clamp(8px, 1.5vh, 16px) 0;
  background: transparent;
`;

// Add new styled component for the description
const GameDescription = styled.div`
  text-align: center;
  padding: clamp(8px, 1.5vh, 16px);
  margin-top: -16px;
  color: #555;
  font-size: clamp(0.9rem, 1.8vw, 1.1rem);
  line-height: 1.5;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

interface AnimalToken {
  name: string;
  image: string;
  tokens: number;
}

interface WildVotesProps {
  onGameEnd: (success: boolean, isPending?: boolean) => void;
  gameId: number;
}

const WildVotes: React.FC<WildVotesProps> = ({ onGameEnd, gameId }) => {
  const initialAnimals = [
    { name: 'Ape', image: '/assets/ape.webp', tokens: 0 },
    { name: 'Lion', image: '/assets/lion.webp', tokens: 0 },
    { name: 'Kangaroo', image: '/assets/kangaroo.webp', tokens: 0 },
    { name: 'Elephant', image: '/assets/elephant.webp', tokens: 0 },
    { name: 'Giraffe', image: '/assets/giraffe.webp', tokens: 0 },
  ];

  // Shuffle the animals array using Fisher-Yates algorithm
  const [animals] = useState(() => [...initialAnimals].sort(() => Math.random() - 0.5));

  const [sequence, setSequence] = useState<(AnimalToken | null)[]>([null, null, null, null, null]);
  const [remainingTokens, setRemainingTokens] = useState(20);
  const [positionTokens, setPositionTokens] = useState([0, 0, 0, 0, 0]);
  const [selectedCell, setSelectedCell] = useState<{
    type: 'animal' | 'sequence';
    index: number;
  } | null>(null);

  const updateTokens = (position: number, change: number) => {
    // Calculate the total tokens used (sum of absolute values)
    const currentTotalTokens = positionTokens.reduce((sum, tokens) => sum + Math.abs(tokens), 0);
    // Calculate what the new total would be after this change
    const newPositionValue = positionTokens[position] + change;
    const newTotalTokens =
      currentTotalTokens - Math.abs(positionTokens[position]) + Math.abs(newPositionValue);

    // Check if we have enough tokens available
    if (newTotalTokens > 20) return;

    const newPositionTokens = [...positionTokens];
    newPositionTokens[position] = newPositionValue;
    setPositionTokens(newPositionTokens);
    setRemainingTokens(20 - newTotalTokens);
  };

  const isAnimalInSequence = (animalName: string) => {
    return sequence.some((item) => item?.name === animalName);
  };

  const handleSubmitVotes = async () => {
    try {
      // Transform the sequence and positionTokens into the required format
      const votes = sequence.map((animal, index) => ({
        position: index,
        animal: animal!.name.toLowerCase(), // Convert to lowercase to match backend expectations
        value: positionTokens[index],
      }));

      await completeWildVotesGame(gameId, votes);
      onGameEnd(true, true);
    } catch (error) {
      console.error('Error submitting votes:', error);
    }
  };

  const handleCellClick = (type: 'animal' | 'sequence', index: number) => {
    if (selectedCell) {
      if (selectedCell.type === type && selectedCell.index === index) {
        // Deselect if clicking the same cell
        setSelectedCell(null);
      } else {
        // Perform swap or placement
        if (type === 'animal') {
          // Swapping between animal list and selected cell
          const animalFromList = animals[index];
          if (selectedCell.type === 'sequence') {
            const newSequence = [...sequence];
            const seqIndex = selectedCell.index;
            if (!isAnimalInSequence(animalFromList.name)) {
              // Replace the animal in sequence with the one from the list
              newSequence[seqIndex] = animalFromList;
              setSequence(newSequence);
            }
          }
        } else {
          // Swapping between sequence positions or placing an animal from list
          const seqIndex = index;
          const newSequence = [...sequence];

          if (selectedCell.type === 'sequence') {
            // Swap animals within the sequence
            const temp = newSequence[seqIndex];
            newSequence[seqIndex] = newSequence[selectedCell.index];
            newSequence[selectedCell.index] = temp;
            setSequence(newSequence);
          } else {
            // Place animal from list into sequence
            const animalFromList = animals[selectedCell.index];
            if (
              !isAnimalInSequence(animalFromList.name) ||
              newSequence[seqIndex]?.name === animalFromList.name
            ) {
              newSequence[seqIndex] = animalFromList;
              setSequence(newSequence);
            }
          }
        }
        // Clear selection after action
        setSelectedCell(null);
      }
    } else {
      // No cell selected yet, select the current cell
      setSelectedCell({ type, index });
    }
  };

  const showSubmitButton = remainingTokens === 0 && !sequence.includes(null);

  // Add this effect to scroll to top when submit button appears
  useEffect(() => {
    if (showSubmitButton) {
      const container = document.querySelector('.game-container');
      container?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [showSubmitButton]);

  return (
    <Container className="game-container">
      {showSubmitButton && (
        <SubmitContainer>
          <SubmitButton onClick={handleSubmitVotes}>Submit Votes</SubmitButton>
        </SubmitContainer>
      )}
      <RemainingTokens hasSubmitButton={showSubmitButton}>
        Remaining Tokens: {remainingTokens}
      </RemainingTokens>
      <GameContent>
        <GameDescription>
          Tap on an image, then select a position to place the image. Once all images are placed,
          allocate your tokens.
        </GameDescription>
        <MobileLayout>
          <AnimalGrid>
            {animals.map(
              (animal, index) =>
                !isAnimalInSequence(animal.name) && (
                  <DraggableAnimal
                    key={animal.name}
                    onClick={() => handleCellClick('animal', index)}
                    style={{
                      border:
                        selectedCell?.type === 'animal' && selectedCell.index === index
                          ? '2px solid #1976d2'
                          : 'none',
                      opacity:
                        selectedCell &&
                        selectedCell.type === 'animal' &&
                        selectedCell.index !== index
                          ? 0.7
                          : 1,
                    }}
                  >
                    <AnimalImage src={animal.image} alt={animal.name} />
                  </DraggableAnimal>
                ),
            )}
          </AnimalGrid>

          <DropZoneGrid>
            {sequence.map((item, index) => (
              <DropZoneRow key={index}>
                <DropZone
                  onClick={() => handleCellClick('sequence', index)}
                  style={{
                    border:
                      selectedCell?.type === 'sequence' && selectedCell.index === index
                        ? '2px solid #1976d2'
                        : '2px dashed #666',
                    backgroundColor:
                      selectedCell &&
                      selectedCell.type === 'sequence' &&
                      selectedCell.index !== index
                        ? 'rgba(0,0,0,0.05)'
                        : 'transparent',
                  }}
                >
                  {item && (
                    <DraggableAnimal
                      onClick={() => handleCellClick('sequence', index)}
                      style={{
                        border:
                          selectedCell?.type === 'sequence' && selectedCell.index === index
                            ? '2px solid #1976d2'
                            : 'none',
                        opacity:
                          selectedCell &&
                          selectedCell.type === 'sequence' &&
                          selectedCell.index !== index
                            ? 0.7
                            : 1,
                      }}
                    >
                      <AnimalImage src={item.image} alt={item.name} />
                    </DraggableAnimal>
                  )}
                </DropZone>
                <TokenControls>
                  <TokenCount>{positionTokens[index]}</TokenCount>
                  <ButtonGroup>
                    <TokenButton
                      onClick={() => updateTokens(index, 1)}
                      disabled={remainingTokens === 0 && positionTokens[index] >= 0}
                      forceEnabled={true}
                    >
                      +
                    </TokenButton>
                    <TokenButton onClick={() => updateTokens(index, -1)}>-</TokenButton>
                  </ButtonGroup>
                </TokenControls>
              </DropZoneRow>
            ))}
          </DropZoneGrid>
        </MobileLayout>
      </GameContent>
    </Container>
  );
};

// Create a wrapper component that matches the expected interface
const WildVotesWrapper: React.FC<{
  onGameEnd: (success: boolean, isPending?: boolean) => void;
}> = ({ onGameEnd }) => {
  // Get the gameId from the URL or context if needed
  const gameId = 7; // Replace with actual gameId for Wild Votes

  return <WildVotes onGameEnd={onGameEnd} gameId={gameId} />;
};

// Export the wrapper instead of the base component
export default WildVotesWrapper;
