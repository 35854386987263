// App.tsx
import React, { useState, useEffect } from 'react';
import './App.css';
import {
  startGame,
  completeGame,
  getGameStatus,
  GameStatus,
  Game,
  getGames,
  verifyAccessCode,
} from './api';
import GameSelector from './components/GameSelector';
import { Routes, Route, useNavigate } from 'react-router-dom';
import GameWrapper from './components/GameWrapper';
import AccessCode from './components/AccessCode';
import { getGamePath } from './config/GameMapping';
import PreLaunchCountdown from './components/PreLaunchCountdown';

const getTodaysGame = (games: Game[]): Game | null => {
  if (!games.length) return null;
  return games[0]; // The backend already filters for today's game for non-admins
};

const getDayOrdinal = (day: number): string => {
  if (day >= 11 && day <= 13) return `${day}th`;

  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

const getChristmasMessage = (): string => {
  // Get current date in Sydney timezone
  const sydneyDate = new Date().toLocaleString('en-US', { timeZone: 'Australia/Sydney' });
  const day = new Date(sydneyDate).getDate();
  const month = new Date(sydneyDate).getMonth() + 1; // getMonth() returns 0-11

  // Only show day message in December and up to the 24th
  if (month === 12 && day <= 24) {
    return `On the ${getDayOrdinal(day)} day of Christmas...`;
  }

  // Default message for other dates
  return 'On the first day of Christmas...';
};

function App() {
  const [timeLeft, setTimeLeft] = useState(30);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [showDescription, setShowDescription] = useState(true);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  const [gameResult, setGameResult] = useState<'PASS' | 'FAIL' | 'PENDING' | null>(null);
  const [gameStatus, setGameStatus] = useState<GameStatus | null>(null);
  const [currentGameId, setCurrentGameId] = useState<number | null>(null);
  const [currentGame, setCurrentGame] = useState<Game | null>(null);
  const [displayText, setDisplayText] = useState('');
  const fullText = getChristmasMessage();
  const [isVerified, setIsVerified] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const [gameStartTime, setGameStartTime] = useState<number | null>(null);

  useEffect(() => {
    // Clear any existing verification
    localStorage.removeItem('isVerified');
    localStorage.removeItem('userId');
    localStorage.removeItem('isAdmin');
    setIsVerified(false);
    setIsAdmin(false);
  }, []); // Empty dependency array means this runs once on component mount

  useEffect(() => {
    if (!isTimerRunning || !currentGame?.has_timer || !gameStartTime) return;

    const updateTimer = () => {
      const now = Date.now();
      const elapsedSeconds = Math.floor((now - gameStartTime) / 1000);
      const remainingTime = currentGame.timer_seconds - elapsedSeconds;

      if (remainingTime <= 0) {
        setTimeLeft(0);
        setIsTimerRunning(false);
        setGameEnded(true);
        handleGameEnd(false);
        localStorage.removeItem('gameStartTime');
        return;
      }

      setTimeLeft(remainingTime);
    };

    // Update immediately and then every second
    updateTimer();
    const timer = setInterval(updateTimer, 1000);

    return () => clearInterval(timer);
  }, [isTimerRunning, currentGame, gameStartTime]);

  useEffect(() => {
    const savedStartTime = localStorage.getItem('gameStartTime');
    const savedGameId = localStorage.getItem('currentGameId');

    if (savedStartTime && savedGameId) {
      const startTime = parseInt(savedStartTime, 10);
      const gameId = parseInt(savedGameId, 10);

      // If there's a saved game in progress, restore it
      setGameStartTime(startTime);
      setCurrentGameId(gameId);
      setIsTimerRunning(true);
      setShowDescription(false);
      setGameStarted(true);
    }
  }, []);

  useEffect(() => {
    const checkGameStatus = async () => {
      if (!currentGameId) return;

      try {
        const status = await getGameStatus(currentGameId);
        setGameStatus(status);

        if (!status.canPlay && status.previousResult) {
          setGameEnded(true);
          setGameResult(status.previousResult.toUpperCase() as 'PASS' | 'FAIL');
          setShowDescription(false);
        }
      } catch (error) {
        console.error('Error checking game status:', error);
      }
    };

    checkGameStatus();
  }, [currentGameId]);

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      setDisplayText(fullText.slice(0, index + 1));
      index++;
      if (index === fullText.length) clearInterval(intervalId);
    }, 40);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const loadInitialGame = async () => {
      if (!isVerified || isAdmin) return;

      // Add check for launch date before attempting to load games
      if (isBeforeLaunchDate()) {
        navigate('/');
        return;
      }

      try {
        const games = await getGames();
        const todaysGame = getTodaysGame(games);

        if (todaysGame) {
          setCurrentGame(todaysGame);
          setCurrentGameId(todaysGame.game_id);
          setTimeLeft(todaysGame.has_timer ? todaysGame.timer_seconds : 0);

          const gamePath = getGamePath(todaysGame.game_name);
          navigate(`/${gamePath}`);
        } else {
          navigate('/no-game-today');
        }
      } catch (error) {
        console.error("Error loading today's game:", error);
      }
    };

    loadInitialGame();
  }, [isVerified, isAdmin, navigate]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const handleGameEnd = async (success: boolean, _isPending?: boolean, bonusTime?: number) => {
    if (!currentGameId) return;

    // If bonusTime is provided, adjust the game start time instead of ending the game
    if (bonusTime && bonusTime > 0 && gameStartTime) {
      const newStartTime = gameStartTime + bonusTime * 1000;
      setGameStartTime(newStartTime);
      localStorage.setItem('gameStartTime', newStartTime.toString());
      return;
    }

    setIsTimerRunning(false);
    setGameEnded(true);

    // Clean up localStorage immediately to prevent reload cheating
    localStorage.removeItem('gameStartTime');
    localStorage.removeItem('currentGameId');

    // Always submit a definitive pass/fail result
    const result = success ? 'PASS' : 'FAIL';
    setGameResult(result);
    await completeGame(currentGameId, success ? 'pass' : 'fail');

    // Refresh game status after completion
    const newStatus = await getGameStatus(currentGameId);
    setGameStatus(newStatus);
  };

  const handleStartGame = async () => {
    if (!currentGameId) return;

    try {
      // Check game status before starting
      const status = await getGameStatus(currentGameId);
      if (!status.canPlay) {
        setGameResult(status.previousResult?.toUpperCase() as 'PASS' | 'FAIL');
        setGameEnded(true);
        return;
      }

      const startTime = Date.now();
      setGameStartTime(startTime);
      setIsTimerRunning(true);
      setShowDescription(false);
      setGameStarted(true);
      setGameEnded(false);
      setGameResult(null);

      localStorage.setItem('gameStartTime', startTime.toString());
      localStorage.setItem('currentGameId', currentGameId.toString());

      await startGame(currentGameId);
    } catch (error) {
      console.error('Error starting game:', error);
      // Handle error appropriately
    }
  };

  const handleGameSelect = async (gameId: number) => {
    try {
      const games = await getGames();
      const selectedGame = games.find((game) => game.game_id === gameId);
      if (selectedGame) {
        setCurrentGame(selectedGame);
        setCurrentGameId(gameId);
        setTimeLeft(selectedGame.has_timer ? selectedGame.timer_seconds : 0);
        setIsTimerRunning(false);
        setShowDescription(true);
        setGameStarted(false);
        setGameEnded(false);
        setGameResult(null);
        setGameStatus(null);

        const gamePath = getGamePath(selectedGame.game_name);
        navigate(`/${gamePath}`);
      }
    } catch (error) {
      console.error('Error fetching game details:', error);
    }
  };

  const handleVerifyAccessCode = async (accessCode: string) => {
    const response = await verifyAccessCode(accessCode);
    if (response.valid && response.user_id) {
      setIsVerified(true);
      setIsAdmin(response.is_staff || false);
      localStorage.setItem('isVerified', 'true');
      localStorage.setItem('userId', response.user_id.toString());
      localStorage.setItem('isAdmin', (response.is_staff || false).toString());

      // Remove the automatic redirect to game 1
      // Non-admin users will be directed to today's game by the useEffect above
    }
    return response.valid;
  };

  const isBeforeLaunchDate = () => {
    // Sydney timezone (AEDT - UTC+11)
    const launchDate = new Date('2024-12-01T00:00:00+11:00');
    const now = new Date();
    return now < launchDate;
  };

  // Add cleanup function to useEffect for timer
  useEffect(() => {
    return () => {
      // Cleanup timer state when component unmounts
      setIsTimerRunning(false);
      setGameStartTime(null);
      localStorage.removeItem('gameStartTime');
      localStorage.removeItem('currentGameId');
    };
  }, []);

  return (
    <div className="App">
      {!isVerified && <AccessCode onVerify={handleVerifyAccessCode} />}
      <header className="App-header">
        <div className="header-content">
          <h1>
            <div className="title-word">
              <span className="title-letter-a">A</span>
              <span>D</span>
              <span>V</span>
              <span className="title-letter-e">E</span>
              <span>N</span>
              <span>T</span>
              <span className="word-break"></span>
              <span>C</span>
              <span>H</span>
              <span>A</span>
              <span>L</span>
              <span>L</span>
              <span>E</span>
              <span>N</span>
              <span>G</span>
              <span>E</span>
            </div>
          </h1>
          {isAdmin && <GameSelector onGameSelect={handleGameSelect} />}
        </div>
      </header>
      <main className="Game-frame">
        {(!isBeforeLaunchDate() || isAdmin) &&
          (gameStarted && currentGame?.has_timer ? (
            <div className="Countdown-timer">{formatTime(timeLeft)}</div>
          ) : (
            <div className="Xmas-text typing">{displayText}</div>
          ))}
        <Routes>
          <Route
            path="/"
            element={
              !isAdmin && isBeforeLaunchDate() ? (
                <PreLaunchCountdown />
              ) : (
                <GameWrapper
                  onGameEnd={handleGameEnd}
                  onStartGame={handleStartGame}
                  showDescription={showDescription}
                  setShowDescription={setShowDescription}
                  gameStatus={gameStatus}
                  gameStarted={gameStarted}
                  gameEnded={gameEnded}
                  setGameEnded={setGameEnded}
                  gameResult={gameResult}
                />
              )
            }
          />
          <Route
            path="/:gameName"
            element={
              !isAdmin && isBeforeLaunchDate() ? (
                <PreLaunchCountdown />
              ) : (
                <GameWrapper
                  onGameEnd={handleGameEnd}
                  onStartGame={handleStartGame}
                  showDescription={showDescription}
                  setShowDescription={setShowDescription}
                  gameStatus={gameStatus}
                  gameStarted={gameStarted}
                  gameEnded={gameEnded}
                  setGameEnded={setGameEnded}
                  gameResult={gameResult}
                />
              )
            }
          />
        </Routes>
      </main>
    </div>
  );
}

export default App;
