import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ChatOpenAI } from '@langchain/openai';
import { StringOutputParser } from '@langchain/core/output_parsers';
import { ChatPromptTemplate } from '@langchain/core/prompts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: clamp(10px, 2vw, 20px);
  box-sizing: border-box;
`;

const Title = styled.h2`
  text-align: center;
  margin: 0 0 clamp(1em, 2vh, 1.5em) 0;
  font-size: clamp(1.5rem, 3vw, 2rem);
`;

const Description = styled.p`
  text-align: center;
  margin: 0 0 clamp(1.5em, 3vh, 2em) 0;
  font-size: clamp(0.9rem, 1.8vw, 1.1rem);
`;

const GameHeader = styled.div`
  display: flex;
  justify-content: center;
  font-size: clamp(0.9rem, 1.8vw, 1.1rem);
  color: #333;
  margin-bottom: clamp(15px, 3vh, 25px);
`;

const InputContainer = styled.div`
  position: relative;
  width: min(100%, 400px);
  margin: 0 auto clamp(15px, 3vh, 25px) auto;
`;

const WordInput = styled.input`
  width: 100%;
  padding: clamp(10px, 2vw, 15px);
  font-size: clamp(0.9rem, 1.8vw, 1.1rem);
  border: 2px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #1976d2;
  }
`;

const GuessHistory = styled.div`
  display: flex;
  flex-direction: column;
  gap: clamp(8px, 1.5vh, 12px);
  overflow-y: auto;
  max-height: calc(100% - 220px);
  width: min(100%, 400px);
  margin: 0 auto;
`;

const GuessEntry = styled.div<{ isClosest: boolean }>`
  display: flex;
  align-items: center;
  padding: clamp(10px, 2vw, 15px);
  background-color: ${(props) => (props.isClosest ? '#fff9e6' : '#f5f5f5')};
  border-radius: 8px;
  border: ${(props) => (props.isClosest ? '2px solid #e6d5a7' : '1px solid transparent')};
  font-size: clamp(0.9rem, 1.8vw, 1.1rem);
  gap: 10px;
`;

const Word = styled.span`
  font-weight: 500;
  min-width: 100px;
`;

const Score = styled.span`
  font-weight: 500;
  min-width: 40px;
  text-align: right;
`;

const BarContainer = styled.div`
  flex-grow: 1;
  height: 20px;
  background-color: #eee;
  border-radius: 4px;
  overflow: hidden;
`;

const Bar = styled.div<{ score: number }>`
  height: 100%;
  width: ${(props) => `${100 - props.score}%`};
  background-color: ${(props) => {
    if (props.score < 33) return '#4caf50';
    if (props.score < 66) return '#ff9800';
    return '#f44336';
  }};
  transition: width 0.3s ease-out;
`;

const TARGET_WORD = 'gravy';

const ErrorMessage = styled.div`
  color: #d32f2f;
  font-size: clamp(0.8rem, 1.6vw, 0.9rem);
  margin-top: 8px;
  min-height: 20px;
`;

const MAX_GUESSES = 30;

const GuessTheWord: React.FC<{ onGameEnd: (success: boolean) => void }> = ({ onGameEnd }) => {
  const [currentGuess, setCurrentGuess] = useState('');
  const [guesses, setGuesses] = useState<{ word: string; score: number; timestamp: number }[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [latestGuessTimestamp, setLatestGuessTimestamp] = useState<number | null>(null);
  const [mostRecentGuess, setMostRecentGuess] = useState<number | null>(null);

  useEffect(() => {
    if (latestGuessTimestamp) {
      const element = document.querySelector(`[data-timestamp="${latestGuessTimestamp}"]`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
      setLatestGuessTimestamp(null);
      setMostRecentGuess(latestGuessTimestamp);
    }
  }, [latestGuessTimestamp]);

  const handleGuessSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (currentGuess && !isProcessing) {
      if (currentGuess.includes(' ')) {
        setError('Please enter only one word');
        return;
      }

      if (currentGuess.length > 30) {
        setError('Word is too long (maximum 30 characters)');
        return;
      }

      const existingGuess = guesses.find(
        (guess) =>
          guess.word.toLowerCase() === currentGuess.toLowerCase() &&
          guess.score >= 0 &&
          guess.score <= 100,
      );

      if (existingGuess) {
        setError("You've already tried this word!");
        return;
      }

      setIsProcessing(true);
      setError('');

      if (currentGuess.toLowerCase() === TARGET_WORD.toLowerCase()) {
        onGameEnd(true);
        return;
      }

      try {
        const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
        if (!apiKey) {
          throw new Error('OpenAI API key not found');
        }

        const chatModel = new ChatOpenAI({
          openAIApiKey: apiKey,
          modelName: 'gpt-4o-mini',
          temperature: 0,
        });

        const prompt = ChatPromptTemplate.fromMessages([
          [
            'system',
            `You are a semantic similarity calculator. Given two words, you must return a number between 0 and 100 representing their semantic similarity, where:
- 0-5: Words are perfect synonyms (e.g. 'sauce' and 'gravy')
- 5-10: Words are close synonyms with nuance differences (e.g., 'jus' and 'gravy', 'dressing' and 'gravy')
- 10-20: Words are similar in meaning but with slight differences (e.g., 'broth' and 'gravy', 'stock' and 'gravy')
- 20-40: Words are related or share some common context (e.g., 'meat' and 'gravy', 'dinner' and 'gravy')
- 40-60: Words have a loose or indirect connection (e.g., 'kitchen' and 'gravy', 'turkey' and 'gravy')
- 60-80: Words have very little semantic connection (e.g., 'plate' and 'gravy', 'food' and 'gravy')
- 80-100: Words have no meaningful connection (e.g., 'computer' and 'gravy', 'mountain' and 'gravy')

Respond with ONLY the number. It can be ANY number between 0-100, you are not limited to responding in groups of fives or tens.`,
          ],
          [
            'user',
            `The target word is always: ${TARGET_WORD}.
The word to score is: ${currentGuess}.
The semantic similarity score is:`,
          ],
        ]);

        const outputParser = new StringOutputParser();
        const chain = prompt.pipe(chatModel).pipe(outputParser);

        let score: number | null = null;
        let attempts = 0;
        const maxAttempts = 3;

        while (attempts < maxAttempts && score === null) {
          try {
            const response = await chain.invoke({
              input: currentGuess,
            });

            const parsedScore = parseInt(response.trim());
            if (!isNaN(parsedScore) && parsedScore >= 0 && parsedScore <= 100) {
              score = parsedScore;
            } else {
              attempts++;
              console.warn(`Invalid AI response (attempt ${attempts}):`, response);
            }
          } catch (error) {
            attempts++;
            console.error(`API error (attempt ${attempts}):`, error);
          }
        }

        if (score === null) {
          throw new Error(`Failed to get valid score after ${maxAttempts} attempts`);
        }

        const timestamp = Date.now();
        let newGuesses = [...guesses, { word: currentGuess, score, timestamp }].sort(
          (a, b) => a.score - b.score,
        );

        if (newGuesses.length > MAX_GUESSES) {
          newGuesses = newGuesses.slice(0, MAX_GUESSES);
        }

        setGuesses(newGuesses);
        setLatestGuessTimestamp(timestamp);
        setCurrentGuess('');
      } catch (error) {
        console.error('Error processing guess:', error);
        setError('Sorry, there was an error processing your guess. Please try again.');
      } finally {
        setIsProcessing(false);
        inputRef.current?.focus();
      }
    }
  };

  return (
    <Container>
      <Title>Guess The Word</Title>
      <Description>
        Guess the secret word. Each guess will receive a score. The lower the score, the closer you
        are!
      </Description>

      <GameHeader>
        <span>GUESSES: {guesses.length}</span>
      </GameHeader>

      <form onSubmit={handleGuessSubmit}>
        <InputContainer>
          <WordInput
            ref={inputRef}
            value={currentGuess}
            onChange={(e) => setCurrentGuess(e.target.value)}
            placeholder="type a word"
            disabled={isProcessing}
            autoFocus
            enterKeyHint="send"
          />
          <ErrorMessage>{error}</ErrorMessage>
        </InputContainer>
      </form>

      <GuessHistory>
        {guesses.map((guess) => (
          <GuessEntry
            key={guess.timestamp}
            isClosest={guess.timestamp === mostRecentGuess}
            data-timestamp={guess.timestamp}
          >
            <Word>{guess.word}</Word>
            <BarContainer>
              <Bar score={guess.score} />
            </BarContainer>
            <Score>{guess.score}</Score>
          </GuessEntry>
        ))}
      </GuessHistory>
    </Container>
  );
};

export default GuessTheWord;
