import React from 'react';
import DescriptionCard from '../DescriptionCard';

const RedPillDescription: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  return (
    <DescriptionCard
      title="Red Pill, Blue Pill"
      description={`**How to play:** This game revolves around choices and their consequences. Your challenge is to navigate the delicate balance of comfort and risk, all while shaping your future in the game. You will face **3 questions.** Before each one, you must decide: red pill or blue pill. 

The blue pill represents comfort and blissful ignorance, the avoidance of difficult truths to maintain the status quo and remain in the contented experience of ordinary reality. The red pill represents the pursuit of deeper understanding, embracing uncomfortable realities to unlock hidden truths—no matter how harsh or difficult.

**Scoring:** Selecting a red pill question awards you **2 points**. Selecting a blue pill question awards you **1 point**. If your name is answered to another player's blue pill question, you will be awarded **1 point**. If your name is answered to another player's red pill question, you will be deducted **2 points**.

You cannot vote for yourself, Jared.

⚠️  This is not a timed challenge. Leaving, refreshing, or re-entering the page may result in progress lost. 

**Win/Lose Conditions**: Players in the top 50 percentile of points will be awarded immunity, while those in the bottom 50 percentile of points will be placed on the elimination wheel.

**Estimated time required**: 2 minutes. 

**Fun-rating:** 10/10`}
      onStart={onStart}
    />
  );
};

export default RedPillDescription;
